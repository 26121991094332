import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { AlertPage } from 'client/ui/alert/AlertPage';
import React from 'react';

export interface INetworkAlertPage {
  message: string;
  description?: string;
  error?: any;
}

/**
 * Gestisce gli eventi in caso di errore dell'API.
 * @param message il messaggio mostrato nella pagina d'errore
 * @param description il sottotitolo mostrato nella pagina d'error.
 *                    Se presente sostituisce la descrizione dell'error.
 * @param error l'errore ricevuto dall'API da cui verrà estratta la descrizione.
 * @returns
 */
export function NetworkAlertPage(props: INetworkAlertPage) {
  const { message, error, description } = props;
  return (
    <AlertPage
      showIcon
      type="error"
      message={message}
      description={description ?? getNetworkErrorMessage(error ?? '')}
    />
  );
}

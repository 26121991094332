import React from 'react';
import Decimal from 'decimal.js-light';
import { CurrencyFormatter } from './CurrencyFormatter';

export interface CurrencyRenderProps {
  value: any;

  /**
   * Permette di passare la valuta. EUR (€) di default.
   */
  currency?: keyof typeof CurrencyFormatter;

  /**
   * Permette di rimuovere il simbolo della valuta
   */
  // removeCurrencySymbol?: boolean;
}

const renderValue = (
  value: any,
  currency: keyof typeof CurrencyFormatter = 'EUR'
) => {
  return value == null
    ? '-'
    : value instanceof Decimal
    ? CurrencyFormatter[currency].format(value.toNumber())
    : value;
};

/**
 * Render di una valuta
 * Ritorna una stringa con il valore e il prefisso della valuta
 */
export function CurrencyRender(props: CurrencyRenderProps) {
  return renderValue(props.value, props.currency);
}

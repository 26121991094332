import React from 'react';
import { Alert, Row, Col } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import './AlertPage.scss';
import { PageLayout } from '../layout/PageLayout';

export interface AlertPageProps extends AlertProps {}

/**
 * Pagina che contiene un solo messaggio di avviso.
 */
export function AlertPage(props: AlertPageProps) {
  const { ...otherProps } = props;

  return (
    <PageLayout hasMainMenu>
      <div style={{ padding: '40px 80px' }}>
        <Alert {...otherProps} />
      </div>
    </PageLayout>
  );
}

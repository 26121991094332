import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { RoleName } from "common/permissions/Roles";

export interface IUserRole {
  role: RoleName;
  id: number;
  code: string;
  description: string;
}

export interface IUserOffice {
  id: number;
  code: string;
  description: string;
}

@GenerateDto()
class UserAuthorities {
  roles!: IUserRole[];
  offices!: IUserOffice[];
}

/**
 * Rappresentazione DTO della classe UserAuthorities 
 * Hash: 398e28315466e640637837b2f8fb60ff
 */
@ValidationSchema(() => UserAuthoritiesSchema)
export class UserAuthoritiesDto {
  @ApiProperty({ type: [Object] })
  roles!: IUserRole[];
  @ApiProperty({ type: [Object] })
  offices!: IUserOffice[];

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<UserAuthoritiesDto>) {
    if (values != null) {
      Object.assign(this, values instanceof UserAuthoritiesDto ? values : plainToClass(UserAuthoritiesDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await UserAuthoritiesSchema.validate(classToPlain(this), options);
    return new UserAuthoritiesDto(validated);
  }
}

export const UserAuthoritiesSchema = yup
  .object({
    roles: yup.array(yup.mixed().required()).required(),
    offices: yup.array(yup.mixed().required()).required()
  })
  .noUnknown()
  .meta({ schemaName: "UserAuthoritiesSchema" })
  .required();

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { DocumentKind } from 'common/schema/contract/contract-document/ContractDocumentTypes';
import { ContractState, ContractTypologyType } from 'common/schema/contract/ContractTypes';
import { ConsumerDto } from './ConsumerDto';
import { ContractDocumentDto, IContractDocumentType } from './ContractDocumentDto';
import { ContractSupplierDto, ContractSupplierSchema } from './ContractSupplierDto';
import { ExternalDocumentDto, ExternalDocumentSchema } from './ExternalDocumentDto';
import { UserDto } from './UserDto';
import { OfficeDto } from './OfficeDto';
import { TaxRecordDto, TaxRecordSchema } from './TaxRecordDto';
import { ResourceAccessElementDto, ResourceAccessElementSchema } from './ResourceAccessElementDto';
import { AccessType } from 'common/permissions/ResourceAccessElementsTypes';
import { GenderTypes } from 'common/schema/supplier/GenderTypes';
import schema from 'yup/lib/schema';

/**
 * Rappresentazione DTO della classe Contract definita in: src/server/schema/contract/contract/Contract.entity.ts
 * Hash: 1cf54d2748229d3f194f939fe8ad8932
 */
@ValidationSchema(() => ContractSchema)
export class ContractDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number, description: 'Contratto da Estendere' })
  parentId?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Numero di registrazione contratto da estendere' })
  parentDocumentNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Presenza contratto da estendere' })
  parentPresence?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Utente' })
  authorId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  author?: UserDto | null;
  @ApiProperty({ required: false, type: Number, description: 'Id Ufficio Proponente' })
  officeId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => OfficeDto })
  @Type(() => OfficeDto)
  office?: OfficeDto | null;
  @ApiProperty({ required: false, type: Number, description: 'Id Ufficio Ufficiale Rogante' })
  rogatoryOfficeId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => [ContractSupplierDto] })
  @Type(() => ContractSupplierDto)
  suppliers!: ContractSupplierDto[];
  @ApiProperty({ required: false, type: () => [TaxRecordDto] })
  @Type(() => TaxRecordDto)
  taxRecords!: TaxRecordDto[];
  @ApiProperty({ required: false, type: Boolean, description: 'Registrato con schede anagrafiche' })
  hasTaxRecords?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Organizzazione' })
  consumerId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => ConsumerDto })
  @Type(() => ConsumerDto)
  consumer?: ConsumerDto | null;
  @ApiProperty({ required: false, enum: ['InProgress', 'ToVerify', 'ToReview', 'InRegistration', 'Registered', 'Paid', 'Closed', 'Cancelled'], description: 'Stato' })
  state?: ContractState | null | undefined;
  @ApiProperty({ type: String, description: 'Oggetto' })
  subject!: string;
  @ApiProperty({ required: false, type: String, description: 'Nota Conlusiva Contratto' })
  terminationNotes?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Qualifica' })
  consumerLegalRole?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione Qualifica' })
  consumerLegalOther?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome' })
  consumerLegalName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Cognome' })
  consumerLegalSurname?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Paese' })
  consumerLegalFiscalIdCountry?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Partita IVA' })
  consumerLegalFiscalIdCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Fiscale' })
  consumerLegalFiscalNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Telefono' })
  consumerLegalPhoneNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Fax' })
  consumerLegalFaxNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Email' })
  consumerLegalEmail?: string | null | undefined;
  @ApiProperty({ required: false, enum: ['M', 'F'], description: 'Sesso' })
  consumerLegalGender?: GenderTypes | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Comune Nascita' })
  consumerLegalBornCity?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Provincia Nascita' })
  consumerLegalBornProvince?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Nascita' })
  consumerLegalBornDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Numero di registrazione fascicolo' })
  dossierNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Id fascicolo' })
  dossierUid?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Registro Documento' })
  documentRegister?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Id Documento' })
  documentUid?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Numero di registrazione Documento' })
  documentNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Anno di registrazione Documento' })
  documentYear?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Forma' })
  shapeType?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Sottoforma' })
  subShapeType?: string | null | undefined;
  @ApiProperty({ type: String, description: 'Tipo di Contratto' })
  typologyType!: string;
  @ApiProperty({ required: false, type: String, description: 'Tipo di Rapporto' })
  relationshipType?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Categoria' })
  categoryType?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Specie' })
  classificationType?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Motivo' })
  reasonType?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione Altro Motivo' })
  reasonOther?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Tipo di Gara' })
  tenderType?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione Altro Tipo di Gara' })
  tenderOther?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Criterio' })
  assignmentType?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Stipula' })
  stipulatedAt?: Date | null | undefined;
  @ApiProperty({ type: Number, description: 'Anno Stipula' })
  stipulatedYear!: number;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Registrazione' })
  registeredAt?: Date | null | undefined;
  @ApiProperty({ type: String, format: 'date-time', description: 'Data Inizio Validità' })
  validityStartAt!: Date;
  @ApiProperty({ type: String, format: 'date-time', description: 'Data Fine Validità' })
  validityEndAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Inizio Lavori' })
  worksStartAt?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Fine Lavori' })
  worksEndAt?: Date | null | undefined;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Importo Totale' })
  @TypeTransformDecimal()
  grossAmount!: Decimal;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Imponibile' })
  @TypeTransformDecimal()
  netAmount?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Importo Cauzione' })
  @TypeTransformDecimal()
  depositAmount?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Aliquota Iva' })
  @TypeTransformDecimal()
  vatRate?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Aliquota Cassa' })
  @TypeTransformDecimal()
  cashRate?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'IVA Inclusa' })
  hasVatIncluded?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Esente IVA' })
  hasVatExemption?: boolean | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Motivo esenzione IVA' })
  vatExemptionReason?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Impegno di spesa' })
  hasCommitment?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Esente Marca da Bollo' })
  hasStampExemption?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Marca da Bollo' })
  hasStampDuty?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Oneri Accessori' })
  hasAccessoryCharges?: boolean | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione Oneri Accessori' })
  accessoryChargesName?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'ID Originale migrazione' })
  migrationId?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Numero di repertorio migrazione' })
  migrationRepertoryNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Codice Agenzia delle Entrate migrazione' })
  migrationAaeeCode?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Motivo Revisione da migrazione' })
  revisionNote?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Contratto pregresso da migrazione' })
  migrationPregress?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Contratto preregistrato' })
  preregistered?: boolean | null | undefined;
  @ApiProperty({ required: false, type: () => [ExternalDocumentDto] })
  @Type(() => ExternalDocumentDto)
  externalDocuments!: ExternalDocumentDto[];
  @ApiProperty({ required: false, type: () => [ContractDocumentDto], description: 'Documenti' })
  @Type(() => ContractDocumentDto)
  documents!: ContractDocumentDto[];
  @ApiProperty({ required: false, type: Boolean, description: 'Flag documenti caricati' })
  documentsUploaded?: boolean | undefined;
  @ApiProperty({ required: false, enum: ['read', 'write'] })
  userAccessTypes?: AccessType[] | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Creazione' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Aggiornamento' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Eliminazione' })
  deletedAt?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractDto ? values : plainToClass(ContractDto, values));
    }
  }

  getMainDocument() {
    if (!this.documents) return null;
    return (this.documents as IContractDocumentType[]).find(
      doc => doc.kind === DocumentKind.Principal
    );
  }

  getAttachments() {
    if (!this.documents) return [] as IContractDocumentType[];
    return (this.documents as IContractDocumentType[]).filter(
      doc => doc.kind === DocumentKind.Attachment
    );
  }

  getActDocuments() {
    if (!this.documents) return [] as IContractDocumentType[];
    return (this.documents as IContractDocumentType[]).filter(
      doc => doc.kind === DocumentKind.Act
    );
  }

  getOtherDocuments() {
    if (!this.documents) return [] as IContractDocumentType[];
    return (this.documents as IContractDocumentType[]).filter(
      doc => doc.kind === DocumentKind.Other
    );
  }

  canUser(access: AccessType) {
    return this.userAccessTypes?.includes(access) ?? false;
  }

  async validate(options?: any) {
    const validated = await ContractSchema.validate(classToPlain(this), options);
    return new ContractDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ContractDto */
export type IContractType = IDto<ContractDto>;

/**
 * DTO Paginato della classe Contract
 */
export class PaginatedContractDto {
  @ApiProperty({ type: [ContractDto] })
  @Type(() => ContractDto)
  items!: ContractDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ContractSchema = yup
  .object({
    id: yup.number(),
    parentId: yup.number().nullable().label('Contratto da Estendere'),
    parentDocumentNumber: yup.number().nullable().label('Numero di registrazione contratto da estendere'),
    officeId: yup.number().nullable().label('Id Ufficio Proponente'),
    rogatoryOfficeId: yup.number().nullable().label('Id Ufficio Ufficiale Rogante'),
    suppliers: yup.array(ContractSupplierSchema),
    taxRecords: yup.array(TaxRecordSchema),
    consumerId: yup.number().nullable().label('Organizzazione'),
    subject: yup.string().required().label('Oggetto'),
    terminationNotes: yup.string().nullable().label('Nota Conlusiva Contratto'),
    consumerLegalRole: yup.string().nullable().label('Qualifica'),
    consumerLegalOther: yup.string().nullable().when('legalRole', {
          is: (role: string) => ['90', '95'].includes(role),
          then: schema =>
            schema.required('Inserire la descrizione per il Rappresentante Legale'),
          otherwise: schema =>
            schema
              .notRequired()
              .nullable()
              .transform(() => null)
        }).label('Descrizione Qualifica'),
    consumerLegalName: yup.string().nullable().required().label('Nome'),
    consumerLegalSurname: yup.string().nullable().required().label('Cognome'),
    consumerLegalFiscalIdCountry: yup.string().nullable().label('Codice Paese'),
    consumerLegalFiscalIdCode: yup.string().nullable().label('Partita IVA'),
    consumerLegalFiscalNumber: yup.string().nullable().label('Codice Fiscale'),
    consumerLegalPhoneNumber: yup.string().nullable().label('Telefono'),
    consumerLegalFaxNumber: yup.string().nullable().label('Fax'),
    consumerLegalEmail: yup.string().nullable().label('Email'),
    consumerLegalGender: yup.string().oneOfEnum(GenderTypes).nullable().label('Sesso'),
    consumerLegalBornCity: yup.string().nullable().label('Comune Nascita'),
    consumerLegalBornProvince: yup.string().nullable().label('Provincia Nascita'),
    consumerLegalBornDate: yup.date().nullable().label('Data Nascita'),
    dossierNumber: yup.number().nullable().label('Numero di registrazione fascicolo'),
    dossierUid: yup.number().nullable().label('Id fascicolo'),
    documentRegister: yup.string().nullable().label('Registro Documento'),
    documentUid: yup.string().nullable().label('Id Documento'),
    documentNumber: yup.number().nullable().label('Numero di registrazione Documento'),
    documentYear: yup.number().nullable().label('Anno di registrazione Documento'),
    shapeType: yup.string().nullable().required().label('Forma'),
    subShapeType: yup.string().nullable().label('Sottoforma'),
    typologyType: yup.string().required().label('Tipo di Contratto'),
    relationshipType: yup.string().nullable().label('Tipo di Rapporto'),
    categoryType: yup.string().nullable().label('Categoria'),
    classificationType: yup.string().nullable().label('Specie'),
    reasonType: yup.string().nullable().label('Motivo'),
    reasonOther: yup.string().nullable().when('reasonType', {
          is: '90',
          then: s => s.required(),
          otherwise: s =>
            s
              .notRequired()
              .nullable()
              .transform(() => null)
        }).label('Descrizione Altro Motivo'),
    tenderType: yup.string().nullable().label('Tipo di Gara'),
    tenderOther: yup.string().nullable().when('tenderType', {
          is: '90',
          then: s => s.required(),
          otherwise: s =>
            s
              .notRequired()
              .nullable()
              .transform(() => null)
        }).label('Descrizione Altro Tipo di Gara'),
    assignmentType: yup.string().nullable().label('Criterio'),
    stipulatedAt: yup.date().nullable().required().min(new Date('1900-01-01')).max(new Date('2100-01-01')).label('Data Stipula'),
    stipulatedYear: yup.number().required()
          .min(1900, "L'anno stipula deve essere successivo al 1900")
          .max(2100, "L'anno stipula deve essere precedente al 2100").label('Anno Stipula'),
    validityStartAt: yup.date().required().label('Data Inizio Validità'),
    validityEndAt: yup.date().required().when('typologyType', {
          is: ContractTypologyType.NoTaxRecords,
          then: s => s.notRequired(),
          otherwise: s => s.required()
        }).label('Data Fine Validità'),
    worksStartAt: yup.date().nullable().label('Data Inizio Lavori'),
    worksEndAt: yup.date().nullable().label('Data Fine Lavori'),
    grossAmount: yup.mixed().required().required('È obbligatorio inserire l’importo lordo del Contratto').label('Importo Totale'),
    netAmount: yup.mixed().nullable().label('Imponibile'),
    depositAmount: yup.mixed().nullable().label('Importo Cauzione'),
    vatRate: yup.mixed().nullable().label('Aliquota Iva'),
    cashRate: yup.mixed().nullable().label('Aliquota Cassa'),
    hasVatIncluded: yup.boolean().nullable().label('IVA Inclusa'),
    hasVatExemption: yup.boolean().nullable().label('Esente IVA'),
    vatExemptionReason: yup.string().nullable().when('hasVatExemption', {
          is: true,
          then: s => s.required(),
          otherwise: s =>
            s
              .notRequired()
              .nullable()
              .transform(() => null)
        }).label('Motivo esenzione IVA'),
    hasCommitment: yup.boolean().nullable().label('Impegno di spesa'),
    hasStampExemption: yup.boolean().nullable().label('Esente Marca da Bollo'),
    hasStampDuty: yup.boolean().nullable().label('Marca da Bollo'),
    hasAccessoryCharges: yup.boolean().nullable().label('Oneri Accessori'),
    accessoryChargesName: yup.string().nullable().when('hasAccessoryCharges', {
          is: true,
          then: s => s.required('Inserire la descrizione degli Oneri Accessori'),
          otherwise: s =>
            s
              .notRequired()
              .nullable()
              .transform(() => null)
        }).label('Descrizione Oneri Accessori'),
    externalDocuments: yup.array(ExternalDocumentSchema)
  })
  .noUnknown()
  .meta({ schemaName: "ContractSchema" })
  .required();

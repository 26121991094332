import React, { useCallback } from 'react';
import { InputNumber } from 'antd';
import { FormikValues, useField } from 'formik';
import { InputNumberProps } from 'antd/lib/input-number';
import { useFormikFormContext } from '../FormikFormContext';

export interface NumberInputProps<T extends FormikValues>
  extends Omit<InputNumberProps, 'name'> {
  name: keyof T & string;
}

/**
 * Campo per input numerico.
 */
export function NumberInput<T extends FormikValues>(
  props: NumberInputProps<T>
) {
  const { name } = props;
  const [field, meta, helpers] = useField(name);
  const { disabled } = useFormikFormContext();
  const handleChange = useCallback(
    (value: string | number | undefined | null) => {
      helpers.setValue(value === '' || value == null ? null : value);
    },
    [helpers.setValue]
  );

  return (
    <InputNumber
      {...field}
      onChange={handleChange}
      {...props}
      disabled={props.disabled || disabled}
    />
  );
}

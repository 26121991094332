import moment from 'moment-timezone';

export const dateInputUtils = {
  toMoment(
    type: 'from' | 'to' | 'date-only',
    raw: string | undefined
  ): moment.Moment | undefined {
    if (!raw) return undefined;

    if (type === 'date-only') {
      return moment.tz(raw, 'YYYY-MM-DD', 'Europe/Rome');
    }

    const date = moment.tz(raw, moment.ISO_8601, 'Europe/Rome');
    return date;
  },
  toString(
    type: 'from' | 'to' | 'date-only',
    raw: moment.Moment | undefined | null
  ): string | undefined {
    if (!raw) return undefined;

    // Convertiamo la data nella TimeZone di interesse per l'applicativo
    const date = moment.tz(
      raw.format('DD/MM/YYYY'),
      'DD/MM/YYYY',
      'Europe/Rome'
    );

    // console.log('tz', date.tz());
    // console.log(
    //   'date',
    //   raw.format('DD/MM/YYYY'),
    //   raw.toISOString(),
    //   type === 'start'
    //     ? date.startOf('day').toISOString()
    //     : date.endOf('day').toISOString()
    // );

    switch (type) {
      case 'from':
        return date.startOf('day').toISOString();
      case 'to':
        return date.endOf('day').toISOString();
      case 'date-only':
        return date.format('YYYY-MM-DD');
    }
  }
};

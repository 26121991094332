import { CheckOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Divider, message, Space, Typography } from 'antd';
const { Text, Paragraph } = Typography;
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { ContractState } from 'common/schema/contract/ContractTypes';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import {
  ContractSendRegisteredDto,
  ContractSendRegisteredSchema
} from 'common/dto/schema/ContractSendRegisteredDto';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { UploadAsBase64Input } from 'client/ui/form/input/UploadAsBase64Input';
import {
  SendFileNotificationDto,
  SendFileNotificationSchema
} from 'common/dto/schema/SendFileNotificationDto';
import { ContractMachine } from 'common/schema/contract/ContractMachine';

export interface ContractRegistrationDoneModalProps {
  contract?: ContractDto;
}

/**
 * Modal per la verifica del contratto  da parte di UR (Ufficiale Rogante)
 * permette di inviare rifiutare (rimandare a UD) o accettare il contratto
 * per poi procedere alla Registrazione su Prosa (fase successiva)
 *
 */
export function ContractRegistrationDoneModal(
  props: ContractRegistrationDoneModalProps
) {
  const { contract } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [register] = useApiMutation(ContractApi.actions.states.registered, {
    invalidates: [ContractApi.find]
  });

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const currentUser = useCurrentUser();

  const transition = ContractMachine.to(
    contract,
    ContractState.Registered,
    currentUser
  );

  if (!transition.allowed && transition.skipped) return null;
  if (!contract) return null;

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Comunica Registrazione <SendOutlined />
      </Button>

      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={new SendFileNotificationDto()}
        validate={schemaToValidator(SendFileNotificationSchema)}
        onSubmit={async values => {
          try {
            const data = await values.validate();

            await register({ data: { id: contract.id, data } });
            message.success('Operazione riuscita');
            handleCancel();
          } catch (e) {
            message.error('Si è verificato un errore. Riprovare.'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        <>
          <FormErrorsNotification />
          <FormikForm nested inline editable={true} helpInTooltips>
            <ModalDialog
              title="Comunica avvenuta Registrazione Contratto"
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={
                <Space>
                  <Button onClick={handleCancel}>Annulla</Button>
                  <FormikSendButton>
                    Comunica Registrazione Contratto <SendOutlined />
                  </FormikSendButton>
                </Space>
              }
            >
              <FormFieldsContainer>
                <FormFieldItem
                  component={TextAreaInput}
                  label="Descrizione"
                  name="description"
                  placeholder="Descrizione"
                  rows={5}
                />
                <FormFieldItem
                  component={UploadAsBase64Input}
                  label="Documento"
                  name="content"
                  maxCount={1}
                  filenameFieldName="filename"
                />
              </FormFieldsContainer>
            </ModalDialog>
          </FormikForm>
        </>
      </Formik>
    </>
  );
}

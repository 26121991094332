import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";

@GenerateDto()
class UserAuthoritiesSelect {
  roleId!: number;
  officeId!: number;
}

/**
 * Rappresentazione DTO della classe UserAuthoritiesSelect 
 * Hash: e0374a8364e7cf90a6762cfd7bd5136d
 */
@ValidationSchema(() => UserAuthoritiesSelectSchema)
export class UserAuthoritiesSelectDto {
  @ApiProperty({ type: Number })
  roleId!: number;
  @ApiProperty({ type: Number })
  officeId!: number;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<UserAuthoritiesSelectDto>) {
    if (values != null) {
      Object.assign(this, values instanceof UserAuthoritiesSelectDto ? values : plainToClass(UserAuthoritiesSelectDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await UserAuthoritiesSelectSchema.validate(classToPlain(this), options);
    return new UserAuthoritiesSelectDto(validated);
  }
}

export const UserAuthoritiesSelectSchema = yup
  .object({
    roleId: yup.number().required(),
    officeId: yup.number().required()
  })
  .noUnknown()
  .meta({ schemaName: "UserAuthoritiesSelectSchema" })
  .required();

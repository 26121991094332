import { DownloadOutlined } from '@ant-design/icons';
import { Button, Result, ResultProps } from 'antd';
import React from 'react';

export interface FileGenerationResultProps {
  error?: string | null;
  buttons: React.ReactNode;
}

/**
 * Visualizzazione del risultato della generazione del file
 */
export function FileGenerationResult(props: FileGenerationResultProps) {
  const { error, buttons } = props;

  const resultProps: ResultProps = {
    status: error ? 'error' : 'success',
    title: error
      ? 'Errore nella generazione del dile!'
      : 'File generato con successo!',
    subTitle: error
      ? error
      : 'Scarica subito il file oppure successivamente dalla pagina di elenco esportazioni.'
  };

  return (
    <Result
      style={{
        padding: '10px'
      }}
      extra={buttons}
      {...resultProps}
    />
  );
}

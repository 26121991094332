import { OfficeFieldItem } from 'client/components/schema/office/input/OfficeFieldItem';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import React from 'react';

export interface IContractTransferFormFieldsProps {
  isSingleTransfer?: boolean;
}

/**
 * Campi della modale per il trasferimento di contratti.
 */
export function ContractTransferFormFields(
  props: IContractTransferFormFieldsProps
) {
  const { isSingleTransfer } = props;
  const labelWidth: ILabelWidth = 'width-140';

  return (
    <FormFieldsContainer>
      {!isSingleTransfer && (
        <OfficeFieldItem
          labelWidth={labelWidth}
          name="senderOfficeId"
          isRogatory={null}
          label="Ufficio di Partenza:"
          allowClear
        />
      )}
      <OfficeFieldItem
        labelWidth={labelWidth}
        name="receiverOfficeId"
        isRogatory={null}
        label="Ufficio di Destinazione:"
        allowClear
      />
    </FormFieldsContainer>
  );
}

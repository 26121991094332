import { apiClient } from 'client/core/network/apiClient';
import {
  ContractDto,
  PaginatedContractDto
} from 'common/dto/generated/ContractDto';
import { ContractChangeStateDto } from 'common/dto/query/ContractChangeStateDto';
import { ContractQueryDto } from 'common/dto/query/ContractQueryDto';
import { ContractSendRegisteredDto } from 'common/dto/schema/ContractSendRegisteredDto';
import { ContractSendToReviewDto } from 'common/dto/schema/ContractSendToReviewDto';
import { ContractSendToVerifyDto } from 'common/dto/schema/ContractSendToVerifyDto';
import { ContractTerminationDto } from 'common/dto/schema/ContractTerminationDto';
import { ContractTransferDto } from 'common/dto/schema/ContractTransferDto';
import { IPreRegisteredContractDto } from 'common/dto/schema/PreRegisteredContractDto';
import { SendFileNotificationDto } from 'common/dto/schema/SendFileNotificationDto';
import { ContractState } from 'common/schema/contract/ContractTypes';

export const ContractApi = {
  create: (opts: { input: ContractDto }) =>
    apiClient
      .requestWithResponseType(ContractDto)
      .post(`/contracts`, opts.input),

  createPreRegistered: (opts: { input: IPreRegisteredContractDto }) =>
    apiClient
      .requestWithResponseType(ContractDto)
      .post('/contracts/preregistered', opts.input),

  // TODO: Verificare che non dia problemi, di solito è utilizzato { id: number, input: ContractDto }
  update: (opts: { input: ContractDto }) =>
    apiClient
      .requestWithResponseType(ContractDto)
      .put(`/contracts/${opts.input.id}`, opts.input),

  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(ContractDto).get(`/contracts/${opts.id}`),

  list: (opts: { query: ContractQueryDto }) =>
    apiClient.requestWithResponseType(PaginatedContractDto).get(`/contracts`, {
      params: opts.query
    }),

  listTransferContracts: (opts: ContractTransferDto) =>
    apiClient.request().get(`/contracts/actions/transfer`, { params: opts }),

  transferContracts: (opts: { input: ContractTransferDto }) =>
    apiClient
      .requestWithResponseType(ContractTransferDto)
      .post(`/contracts/actions/transfer`, opts.input),

  actions: {
    states: {
      toVerify: (opts: { id: number; data: ContractSendToVerifyDto }) =>
        apiClient
          .request()
          .post(`/contracts/${opts.id}/actions/to-verify`, opts.data),
      toReview: (opts: { id: number; data: ContractSendToReviewDto }) =>
        apiClient
          .request()
          .post(`/contracts/${opts.id}/actions/to-review`, opts.data),
      inRegistration: (opts: { id: number; data: ContractSendRegisteredDto }) =>
        apiClient
          .requestWithResponseType(ContractDto)
          .post(`/contracts/${opts.id}/actions/in-registration`, opts.data),
      registered: (opts: { id: number; data: SendFileNotificationDto }) =>
        apiClient
          .requestWithResponseType(ContractDto)
          .post(`/contracts/${opts.id}/actions/registered`, opts.data),
      paid: (opts: { id: number; data: ContractTerminationDto }) =>
        apiClient
          .request()
          .post(`/contracts/${opts.id}/actions/paid`, opts.data),
      cancelled: (opts: { id: number }) =>
        apiClient
          .requestWithResponseType(ContractDto)
          .post(`/contracts/${opts.id}/actions/cancelled`),
      restored: (opts: { id: number; restoredState?: ContractState | null }) =>
        apiClient
          .requestWithResponseType(ContractDto)
          .post(`/contracts/${opts.id}/actions/restored/${opts.restoredState}`)
    }
  }
};

import { UndoOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { ContractMachine } from 'common/schema/contract/ContractMachine';
import { ContractState } from 'common/schema/contract/ContractTypes';
import React, { useCallback, useState } from 'react';
const { Title } = Typography;

export interface IContractRestoreModalProps {
  contract: ContractDto;
  /** Un contratto annullato può derivare solo da Registrato o In Registrazione */
  restoredState: ContractState.Registered | ContractState.InRegistration;
}

/**
 * Modal per il ripristino del contratto
 */
export function ContractRestoreModal(props: IContractRestoreModalProps) {
  const { contract, restoredState } = props;
  const [isVisible, setIsVisible] = useState(false);
  const currentUser = useCurrentUser();

  const handleCancel = useCallback(() => {
    setIsVisible(false);
  }, []);

  const showModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const [restoreContract, restoreRequestMeta] = useApiMutation(
    ContractApi.actions.states.restored,
    { invalidates: [ContractApi.find] }
  );

  const wasPreviouslyRegistered = restoredState === ContractState.Registered;

  const handleRestoreContract = useCallback(async () => {
    await restoreContract({
      data: { id: contract.id, restoredState: restoredState }
    });
    setIsVisible(false);
  }, [contract]);

  // Se il contratto non può essere ripristinato, non mostro il pulsante
  if (
    !ContractMachine.to(contract, restoredState, currentUser).allowed ||
    contract.state !== ContractState.Cancelled
  ) {
    return null;
  }

  return (
    <>
      <Button type="ghost" onClick={showModal}>
        {`Ripristina Contratto ${
          wasPreviouslyRegistered ? 'Registrato' : 'In Registrazione'
        }`}{' '}
        <UndoOutlined />
      </Button>
      <ModalDialog
        title={`Ripristino Contratto ${
          wasPreviouslyRegistered ? 'Registrato' : 'In Registrazione'
        }`}
        visible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
        footer={
          <Space>
            <Button onClick={handleCancel}>Indietro</Button>
            <Button
              type="primary"
              loading={restoreRequestMeta.loading}
              onClick={handleRestoreContract}
            >
              Ripristina il Contratto
            </Button>
          </Space>
        }
      >
        <Row>
          <Col>
            <Title level={4}>
              {` Sei sicuro di voler ripristinare il contratto ${
                wasPreviouslyRegistered ? 'registrato' : 'in registrazion'
              }?`}
            </Title>
          </Col>
        </Row>
      </ModalDialog>
    </>
  );
}

import * as React from 'react';
import { ProfileOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { Section } from 'client/ui/section/Section';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { useField, useFormikContext } from 'formik';
import { Divider, Space, Tooltip } from 'antd';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { SectionExtraFieldContainer } from 'client/ui/section/SectionExtraFieldContainer';
import { SectionButton } from 'client/ui/section/SectionButton';
import { ContractParentLinkButton } from 'client/components/schema/contract/general/ContractParentLinkButton';
import { ContractParentInput } from 'client/components/schema/contract/general/ContractParentInput';
import { DataItem } from 'client/ui/form/field/DataItem';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';

export interface ContractGeneralSectionProps {
  editable?: boolean;
}

export function ContractGeneralSection(props: ContractGeneralSectionProps) {
  const formik = useFormikContext<ContractDto>();
  const [stipulatedYearField, , StipulatedYearHelpers] =
    useField('stipulatedYear');

  const parentId = formik.values.parentId ?? null;
  const parentPresence = formik.values.parentPresence ?? null;
  const parentDocumentNumeber = formik.values.parentDocumentNumber ?? null;

  useFormikFieldChange(
    formik => {
      const year = formik.values.stipulatedAt
        ? new Date(formik.values.stipulatedAt).getFullYear()
        : null;
      StipulatedYearHelpers.setValue(year);
    },
    ['stipulatedAt']
  );

  return (
    <Section
      icon={<ProfileOutlined />}
      title="Dati Principali"
      hasPadding
      extra={
        <SectionExtraFieldContainer width={'335px'}>
          {props.editable ? (
            <ContractParentInput />
          ) : (
            <ContractParentLinkButton
              parentId={parentId}
              parentDocumentNumeber={parentDocumentNumeber}
              parentPresence={parentPresence}
            />
          )}
        </SectionExtraFieldContainer>
      }
    >
      {/** Forma Contratto */}
      <FormFieldsContainer columns={2}>
        <FormFieldItem
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="shapeType"
          label="Forma"
          name="shapeType"
          placeholder="Forma"
        />
        <FormFieldItem
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="typologyType"
          label="Tipo Contratto"
          name="typologyType"
          placeholder="Tipo Contratto"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={2} removeTopTitleSpacing>
        <FormFieldItem
          component={DatePickerInput}
          type="date-only"
          label="Data Stipula"
          name="stipulatedAt"
          placeholder="Data Stipula"
        />
        <DataItem label="Anno Stipula" value={formik.values.stipulatedYear} />
      </FormFieldsContainer>

      <FormFieldsContainer>
        <FormFieldItem
          component={TextAreaInput}
          label="Oggetto"
          name="subject"
          placeholder="Oggetto"
          rows={2}
        />
      </FormFieldsContainer>

      {/* DATE DI RIFERIMENTO */}
      <FormFieldsContainer title="Date di Riferimento" columns={2}>
        <FormFieldItem
          component={DatePickerInput}
          type="from"
          label="Decorrenza"
          name="validityStartAt"
          placeholder="Decorrenza"
        />
        <FormFieldItem
          component={DatePickerInput}
          type="to"
          label="Scadenza"
          name="validityEndAt"
          placeholder="Scadenza"
        />
        <FormFieldItem
          component={DatePickerInput}
          type="from"
          label="Inizio Lavori"
          name="worksStartAt"
          placeholder="Inizio Lavori"
        />
        <FormFieldItem
          component={DatePickerInput}
          type="to"
          label="Fine Lavori"
          name="worksEndAt"
          placeholder="Fine Lavori"
        />
      </FormFieldsContainer>
    </Section>
  );
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";

@GenerateDto()
class EvaluateTaxRecord {
    @SchemaLabel('Nota e Assegnazione Ufficiale Rogante')
    description!: string;
}

/**
 * Rappresentazione DTO della classe EvaluateTaxRecord 
 * Hash: fe1b16a35fcb81d2861c67adbbf0e0d2
 */
@ValidationSchema(() => EvaluateTaxRecordSchema)
export class EvaluateTaxRecordDto {
  @ApiProperty({ type: String, description: 'Nota e Assegnazione Ufficiale Rogante' })
  description!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EvaluateTaxRecordDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EvaluateTaxRecordDto ? values : plainToClass(EvaluateTaxRecordDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EvaluateTaxRecordSchema.validate(classToPlain(this), options);
    return new EvaluateTaxRecordDto(validated);
  }
}

export const EvaluateTaxRecordSchema = yup
  .object({
    description: yup.string().required().label('Nota e Assegnazione Ufficiale Rogante')
  })
  .noUnknown()
  .meta({ schemaName: "EvaluateTaxRecordSchema" })
  .required();

import * as React from 'react';
import { EuroOutlined } from '@ant-design/icons';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Section } from 'client/ui/section/Section';
import { CurrencyInput } from 'client/ui/form/input/CurrencyInput';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { FormikContextType, useFormikContext } from 'formik';
import { PercentageInput } from 'client/ui/form/input/PercentageInput';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { ContractLogic } from 'common/schema/contract/ContractLogic';

export interface ContractEconomicSectionProps {}

export function ContractEconomicSection(props: ContractEconomicSectionProps) {
  const formik = useFormikContext<ContractDto>();

  useFormikFieldChange(
    (formik: FormikContextType<ContractDto>) => {
      const { netAmount, vatRate, cashRate } = formik.values;

      if (netAmount == null || (vatRate == null && cashRate == null)) return;

      const grossAmount = ContractLogic.computeGrossAmount(formik.values);

      formik.setFieldValue('grossAmount', grossAmount);
    },
    ['netAmount', 'vatRate', 'cashRate']
  );

  return (
    <Section icon={<EuroOutlined />} title="Dati Economici" hasPadding>
      {/* Dati Economico Finanziari */}
      <FormFieldsContainer removeTopTitleSpacing columns={2}>
        <FormFieldItem
          component={CurrencyInput}
          label="Imponibile"
          name="netAmount"
          placeholder="Importo Imponibile"
        />
        <FormFieldItem
          component={CurrencyInput}
          label="Importo Totale"
          name="grossAmount"
          placeholder="Importo Lordo"
        />
        <FormFieldItem
          component={PercentageInput}
          label="Aliquota Cassa"
          name="cashRate"
          placeholder="Aliquota Cassa"
        />

        <FormFieldItem
          component={CurrencyInput}
          label="Deposito"
          name="depositAmount"
          placeholder="Deposito"
        />
        <FormFieldItem
          component={PercentageInput}
          label="Aliquota IVA"
          name="vatRate"
          placeholder="Aliquota IVA"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={2}>
        <FormFieldItem
          component={CheckboxInput}
          label={<span />}
          children="Esente IVA (se esente indicare motivo di esenzione)"
          name="hasVatExemption"
          defaultChecked={false}
        />
        <FormFieldItem
          component={CheckboxInput}
          label={null}
          children="IVA Inclusa"
          name="hasVatIncluded"
          defaultChecked={false}
        />
        <FormFieldItem
          colSpan={2}
          component={TextInput}
          label="Motivo"
          placeholder="Indicare il Motivo esenzione IVA"
          name="vatExemptionReason"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={4}>
        <FormFieldItem
          component={CheckboxInput}
          label={<span />}
          children="Marca da Bollo"
          name="hasStampDuty"
          defaultChecked={false}
        />
        <FormFieldItem
          component={CheckboxInput}
          label={null}
          children="Impegno di spesa"
          name="hasCommitment"
          defaultChecked={false}
        />

        <FormFieldItem
          colSpan={2}
          component={CheckboxInput}
          label={null}
          children="Oneri Accessori"
          name="hasAccessoryCharges"
          defaultChecked={false}
        />
        <FormFieldItem
          colSpan={2}
          component={CheckboxInput}
          label={<span />}
          children="Esente Marca da Bollo"
          name="hasStampExemption"
          defaultChecked={false}
        />
        <FormFieldItem
          colSpan={2}
          component={TextAreaInput}
          label="Descrizione"
          name="accessoryChargesName"
          placeholder="Descrizione Oneri Accessori"
          rows={2}
        />
      </FormFieldsContainer>
    </Section>
  );
}

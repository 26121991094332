import { EntityMachine } from 'common/fsm/EntityMachine';
import { yup } from 'common/validation/initYup';

export function SchemaValidate<
  T extends yup.BaseSchema,
  TOut extends yup.BaseSchema
>(fn: (schema: T) => TOut): PropertyDecorator {
  return (target: any, propertyKey: any) => {};
}

export function SchemaNotRequired(): PropertyDecorator {
  return () => {};
}

export function SchemaLabel(label: string): PropertyDecorator {
  return (target: any, propertyKey: any) => {
    Reflect.defineMetadata(
      'yup:schema:label',
      label,
      target.constructor,
      propertyKey
    );
  };
}

export function SchemaPageNumber(): PropertyDecorator {
  return () => {};
}

export function SchemaPageSize(): PropertyDecorator {
  return () => {};
}

/** Proprietà in sola lettura (assente negli input) */
export function SchemaReadonly(): PropertyDecorator {
  return () => {};
}

/** Richiede che sia disponibile l'azione indicata per la EntityMachine relativa all'entità */
export function SchemaAction<E extends object, A extends string>(
  machine: EntityMachine<E, any, A>,
  action: A
): PropertyDecorator {
  return () => {};
}

import { Tag } from 'antd';
import { ContractDto } from 'common/dto/generated/ContractDto';
import React from 'react';

export interface IPreRegisteredTagProps {
  contract: ContractDto;
}

export function PreRegisteredTag(props: IPreRegisteredTagProps) {
  const { contract } = props;

  if (!contract.preregistered) {
    return null;
  }
  return <Tag color="cyan">Preregistrato</Tag>;
}

import { Button, ButtonProps } from 'antd';
import { useFormikContext } from 'formik';
import * as React from 'react';

export interface IFormikResetButtonProps extends ButtonProps {
  onReset?: () => void;
}

export function FormikResetButton(props: IFormikResetButtonProps) {
  const { onReset, ...otherProps } = props;
  const formik = useFormikContext();

  return (
    <Button
      loading={formik.isSubmitting}
      {...otherProps}
      onClick={() => {
        formik.resetForm();
        onReset && onReset();
      }}
    />
  );
}

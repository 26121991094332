import { AxiosInstance, AxiosError } from 'axios';
import { inRange } from 'lodash';

const MAX_RETRY_COUNT = 0;

/**
 * Permette di gestire i tentativi con backoff esponenziale per le richieste
 * fallite.
 */
export function retryInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(undefined, (error: AxiosError) => {
    const { config: originalConfig, response } = error;

    // Controlliamo se deve essere ritentata
    if (!shouldRetryRequest(error)) {
      return Promise.reject(error);
    }

    // Preparo la configurazione per la richiesta successiva
    const config = {
      ...originalConfig,
      headers: { ...originalConfig.headers }
    };

    // Impostiamo lo stato di retry.
    config.headers['x-retry-count'] = config.headers['x-retry-count'] || 0;
    config.headers['x-retry-max'] = MAX_RETRY_COUNT;

    // Creiamo una Promise per gestire il backoff prima di ritentare.
    // Formula: (2^count - 1 / 2) * 1000
    const backoff = new Promise<any>(resolve => {
      const delay =
        ((Math.pow(2, config.headers['x-retry-count']!) - 1) / 2) * 1000;

      config.headers['x-retry-count'] += 1;
      setTimeout(resolve, delay);
    });

    return (
      Promise.resolve()
        .then(() => backoff)
        .then(() => {
          return axios({ ...config });
        })
        // Rimappiamo l'errore dell'ultima promise all'errore della promise
        // corrente.
        .catch(err => {
          // Reimpostiamo solamente il retry count per evitare di mostrare
          // l'errore n volte
          err.config.headers['x-retry-count'] =
            originalConfig.headers['x-retry-count'];

          return Promise.reject(err);
        })
    );
  });
}

const allowedRetryHttpMethods = ['GET', 'HEAD', 'PUT', 'OPTIONS', 'DELETE'];

/**
 * Funzione per controllare se la richiesta deve essere ritentata oppure no.
 * Potrebbe essere il caso di risposte client-error (BadRequest), 404, oppure
 * success (200, ecc.)
 */
export function shouldRetryRequest(err: AxiosError) {
  const { config, response } = err;

  // Errore non di axios
  if (!config) return false;

  // Riprovare solo con i metodi HTTP supportati
  if (
    !allowedRetryHttpMethods.includes(
      config.method ? config.method.toUpperCase() : ''
    )
  )
    return false;

  // In caso di risposta, riproviamo soltanto se lo status-code lo supporta.
  // Ad esempio, evitiamo i success o i client-errors.
  if (response && response.status) {
    if (
      !inRange(response.status, 100, 200) &&
      !inRange(response.status, 429, 430) &&
      !inRange(response.status, 500, 600)
    ) {
      return false;
    }
  }

  // Supportiamo un numero limitato di retry.
  config.headers['x-retry-count'] = config.headers['x-retry-count'] || 0;
  if (config.headers['x-retry-count'] >= MAX_RETRY_COUNT) {
    return false;
  }

  return true;
}

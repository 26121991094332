import { OfficeAssignVisibility } from 'server/schema/documental/drivers/folium/dto/FoliumOfficeDto';

export class OfficeLogic {
  /** Verifica se l'ufficio ha visibilità di assegnazione */
  static isNotAssignVisible(assignVisibility?: string | null) {
    return (
      assignVisibility != null &&
      assignVisibility !== OfficeAssignVisibility.VISIBLE
    );
  }
}

import { Transform } from 'class-transformer';
import Decimal from 'decimal.js-light';

export function TypeTransformDecimal(): PropertyDecorator {
  return (target: Object, propertyKey: any) => {
    Transform(({ value }) => (value == null ? value : value.toString()), {
      toPlainOnly: true
    })(target, propertyKey);
    Transform(({ value }) => (value == null ? value : new Decimal(value)), {
      toClassOnly: true
    })(target, propertyKey);
  };
}

import { apiClient } from 'client/core/network/apiClient';
import {
  SupplierDto,
  PaginatedSupplierDto
} from 'common/dto/generated/SupplierDto';
import { SupplierQueryDto } from 'common/dto/query/SupplierQueryDto';

export const SupplierApi = {
  create: (opts: { input: SupplierDto }) =>
    apiClient
      .requestWithResponseType(SupplierDto)
      .post(`/suppliers`, opts.input),

  // TODO: Verificare che non dia problemi, di solito è utilizzato { id: number, input: SupplierDto }
  update: (opts: { input: SupplierDto }) =>
    apiClient
      .requestWithResponseType(SupplierDto)
      .put(`/suppliers/${opts.input.id}`, opts.input),

  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(SupplierDto).get(`/suppliers/${opts.id}`),

  list: (opts: { query: SupplierQueryDto }) =>
    apiClient.requestWithResponseType(PaginatedSupplierDto).get(`/suppliers`, {
      params: opts.query
    })
};

import * as React from 'react';
import { FormikErrors, useFormikContext } from 'formik';
import { useState, useEffect, useLayoutEffect } from 'react';
import { Modal } from 'antd';
import './FormErrorsNotification.scss';
import { CaretRightFilled } from '@ant-design/icons';
import { allErrors } from './allErrors';

type ModalType = ReturnType<typeof Modal['error']>;

export interface IFormErrorsNotificationProps {}

export function FormErrorsNotification(props: IFormErrorsNotificationProps) {
  const { submitCount, errors } = useFormikContext();
  const submitCountRef = React.useRef(0);
  const modal = React.useRef<ModalType | undefined>();

  useLayoutEffect(() => {
    if (submitCount > 0 && submitCount !== submitCountRef.current) {
      submitCountRef.current = submitCount;
      if (Object.keys(errors).length > 0) {
        if (modal.current) {
          modal.current.destroy();
        }

        // console.trace('[PROC/ERR] Validation error', errors);
        modal.current = Modal.error({
          title: 'Errore di Validazione',
          width: 700,
          onOk() {
            // Fix per gestire la proprietà di `overflow: hidden` che viene
            // impostata erroneamente
            try {
              document.body.style.removeProperty('overflow');
            } catch (e: any) {}
          },
          content: (
            <div className="form-errors-list">
              {allErrors(errors).map((error, i) => (
                <p key={i}>
                  <CaretRightFilled /> {error}
                </p>
              ))}
            </div>
          )
        });
      }
    }

    return () => {
      if (modal.current) modal.current.destroy();
    };
  }, [submitCount, errors]);

  return null;
}

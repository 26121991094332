import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";



@GenerateDto()
class ContractTermination {
    @SchemaLabel('Nota di terminazione')
    note!: string;
}

/**
 * Rappresentazione DTO della classe ContractTermination 
 * Hash: b54adf74d0b2f59b6c49794f951b6936
 */
@ValidationSchema(() => ContractTerminationSchema)
export class ContractTerminationDto {
  @ApiProperty({ type: String, description: 'Nota di terminazione' })
  note!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractTerminationDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractTerminationDto ? values : plainToClass(ContractTerminationDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ContractTerminationSchema.validate(classToPlain(this), options);
    return new ContractTerminationDto(validated);
  }
}

export const ContractTerminationSchema = yup
  .object({
    note: yup.string().required().label('Nota di terminazione')
  })
  .noUnknown()
  .meta({ schemaName: "ContractTerminationSchema" })
  .required();

import { AxiosInstance } from 'axios';

/**
 * Aggiunge l'href corrente alle richieste.
 */
export function locationHrefInterceptor(axios: AxiosInstance) {
  axios.interceptors.request.use(config => {
    config.headers['x-location-href'] = window.location.href;
    return config;
  });
}

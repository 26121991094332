import * as React from 'react';
import { TrophyOutlined } from '@ant-design/icons';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { Section } from 'client/ui/section/Section';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { useFormikContext } from 'formik';
import { Divider } from 'antd';

export interface ContractOthersSectionProps {
  editable?: boolean;
}

export function ContractOthersSection(props: ContractOthersSectionProps) {
  const formik = useFormikContext<ContractDto>();
  return (
    <Section
      icon={<TrophyOutlined />}
      title="Classificazione e Gara"
      hasPadding
    >
      <FormFieldsContainer columns={2}>
        <FormFieldItem
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="subShapeType"
          // dropdownMatchSelectWidth={600}
          label="Sotto Forma"
          name="subShapeType"
          placeholder="Sotto Forma"
        />
        <FormFieldItem
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="relationshipType"
          // dropdownMatchSelectWidth={600}
          label="Tipo Rapporto"
          name="relationshipType"
          placeholder="Tipo Rapporto"
        />
        <FormFieldItem
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="classificationType"
          label="Specie"
          name="classificationType"
          placeholder="Specie"
        />
        <FormFieldItem
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="categoryType"
          label="Categoria"
          name="categoryType"
          placeholder="Categoria"
        />
      </FormFieldsContainer>

      {props.editable && <Divider style={{ margin: '6px 0' }} />}

      <FormFieldsContainer columns={2}>
        <FormFieldItem
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="reasonType"
          label="Motivo"
          name="reasonType"
          placeholder="Motivo"
        />
        <FormFieldItem
          component={TextAreaInput}
          label="Descrizione"
          name="reasonOther"
          placeholder="Descrizione Altro Motivo"
          rows={2}
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={2}>
        <FormFieldItem
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="tenderType"
          label="Tipo di Gara"
          name="tenderType"
          placeholder="Tipo di Gara"
        />
        <FormFieldItem
          component={TextAreaInput}
          label="Descrizione"
          name="tenderOther"
          placeholder="Descrizione Altro Tipo di Gara"
          rows={2}
        />
        <FormFieldItem
          colSpan={2}
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="assignmentType"
          label="Criterio"
          name="assignmentType"
          placeholder="Criterio"
        />
      </FormFieldsContainer>
    </Section>
  );
}

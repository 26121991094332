import { apiClient } from 'client/core/network/apiClient';
import {
  ConsumerDto,
  PaginatedConsumerDto
} from 'common/dto/generated/ConsumerDto';

export const ConsumerApi = {
  create: (opts: { input: ConsumerDto }) =>
    apiClient
      .requestWithResponseType(ConsumerDto)
      .post(`/consumers`, opts.input),

  update: (opts: { input: ConsumerDto }) =>
    apiClient
      .requestWithResponseType(ConsumerDto)
      .put(`/consumers/${opts.input.id}`, opts.input),

  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(ConsumerDto).get(`/consumers/${opts.id}`),

  list: (opts: {}) =>
    apiClient.requestWithResponseType(PaginatedConsumerDto).get(`/consumers`, {
      params: undefined
    })
};

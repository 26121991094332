import React from 'react';
import { FormikValues, useField, useFormikContext } from 'formik';
import { TextAreaProps } from 'antd/lib/input';
import TextArea from 'antd/lib/input/TextArea';
import { useFormikFormContext } from '../FormikFormContext';

// interface Props<T extends FormikValues> extends Omit<TextAreaProps, 'name'> {
//   name: keyof T & string;
// }
export interface TextAreaInputProps<T extends FormikValues>
  extends Omit<TextAreaProps, 'name'> {
  name: keyof T & string;
}

/**
 * Campo per input area di testo.
 */
export function TextAreaInput<T extends FormikValues>(
  props: TextAreaInputProps<T>
) {
  const { name } = props;
  const [field, meta] = useField(name);
  const formik = useFormikContext<T>();
  const { disabled } = useFormikFormContext();

  return (
    <TextArea {...field} {...props} disabled={props.disabled || disabled} />
  );
}

TextAreaInput.renderFieldValue = (value: string) => {
  // Gestiamo gli accapo nel render del testo
  if (!value) return null;
  const splittedText = value.replace('\r', '\n').split('\n');
  return splittedText.map((paragraph, i) => (
    <p key={i} style={{ minHeight: '1.5em', margin: 0 }}>
      {paragraph}
    </p>
  ));
};

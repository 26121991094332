import * as yup from 'yup';
import Reference from 'yup/lib/Reference';

declare module 'yup' {
  interface StringSchema {
    /**
     * Permette di fornire un ref a un array esterno (i.e. context) per validare
     * se il valore è contenuto in quell'array.
     */
    oneOfRefArray(arrayRef: Reference<string[]>): StringSchema;
  }
}

yup.addMethod(
  yup.mixed,
  'oneOfRefArray',
  function (this: yup.BaseSchema<any>, arrayRef) {
    return this.test({
      message: 'Il campo ${path} non rientra fra le opzioni disponibili.',
      name: 'oneOfRefArray',
      exclusive: true,
      params: { arrayRef },
      test(value) {
        return (this.resolve(arrayRef) || []).includes(value);
      }
    });
  }
);

import { ClockCircleOutlined } from '@ant-design/icons';
import { ActivityLogTable } from 'client/components/schema/activity-log/table/ActivityLogTable';
import { Section } from 'client/ui/section/Section';
import React from 'react';

export interface IContractActivityLogSectionProps {
  contractId: number;
}

/**
 * Sezione della cronologia attività del contratto.
 */
export function ContractActivityLogSection(
  props: IContractActivityLogSectionProps
) {
  const { contractId } = props;

  return (
    <Section icon={<ClockCircleOutlined />} title="Cronologia Attività">
      <ActivityLogTable entity="contract" entityId={contractId} />
    </Section>
  );
}

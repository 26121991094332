import * as React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import './MainMenu.scss';
import {
  AuditOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  IdcardOutlined,
  ProfileOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useCurrentMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';
import {
  useCurrentUser,
  usePermissions
} from 'client/components/auth/AuthModule';
import { LogoutButton } from 'client/components/auth/logout/LogoutButton';
import SubMenu from 'antd/lib/menu/SubMenu';
import { SyncOfficeMenuItem } from 'client/components/schema/office/SyncOfficeMenuItem';
import { resolvePublicPath } from 'client/core/appConfig';

export interface MainMenuProps {}

/**
 * Menu principale dell'applicazione
 */
export function MainMenu(props: MainMenuProps) {
  const currentPathKey = useCurrentMenuKey();
  const permissions = usePermissions();
  const currentUser = useCurrentUser();
  const isRogatory = currentUser?.isRogatory();

  return (
    <Menu
      selectedKeys={currentPathKey}
      theme="dark"
      mode="horizontal"
      className="main-menu"
    >
      <Menu.Item key="contracts" icon={<AuditOutlined />}>
        <Link to="/contracts">Contratti</Link>
      </Menu.Item>
      <Menu.Item key="suppliers" icon={<IdcardOutlined />}>
        <Link to="/suppliers">Contraenti</Link>
      </Menu.Item>
      <Menu.Item key="tax" icon={<ProfileOutlined />}>
        <Link to="/tax/records">Schede Anagrafe Tributaria</Link>
      </Menu.Item>
      {permissions.has(['tax-exports.read']) && (
        <Menu.Item key="exports" icon={<DownloadOutlined />}>
          <Link to="/tax/exports">Export</Link>
        </Menu.Item>
      )}
      {permissions.has(['consumers.write', 'users.write', 'offices.sync']) && (
        <SubMenu key="admin" icon={<SettingOutlined />} title="Amministrazione">
          {/* Gestione Sincronizzazione Uffici */}
          <SyncOfficeMenuItem key="syncOffice" />

          {/* Gestione Committenti */}
          {permissions.has('consumers.write') && (
            <Menu.Item key="consumers" icon={<UserOutlined />}>
              <Link to="/consumers">Committenti</Link>
            </Menu.Item>
          )}

          {/* Gestione Utenti */}
          {permissions.has('users.write') && (
            <Menu.Item key="users" icon={<UserOutlined />}>
              <Link to="/users">Utenti</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      <LogoutButton key="logout-btn" />
      <Menu.Item
        key="guide"
        style={{ float: 'right' }}
        icon={<FilePdfOutlined />}
      >
        <a
          href={resolvePublicPath(
            `manuali/${encodeURIComponent(
              `Manuale Utente ${
                isRogatory ? 'UR' : 'UD'
              } - Ufficiale Rogante.pdf`
            )}`
          )}
          target="_blank"
        >
          Manuale
        </a>
      </Menu.Item>
    </Menu>
  );
}

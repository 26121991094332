import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';

/**
 * Rappresentazione DTO della classe ExternalDocument definita in: src/server/schema/contract/external-document/ExternalDocument.entity.ts
 * Hash: 830a1f5b7beb88474f8b76b1f3b33993
 */
@ValidationSchema(() => ExternalDocumentSchema)
export class ExternalDocumentDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: String, description: 'Note' })
  description?: string | null | undefined;
  @ApiProperty({ type: Number, description: 'ID Documento su Documentale' })
  documentalUid!: number;
  @ApiProperty({ required: false, type: Number, description: 'ID Contratto' })
  contractId!: number;
  @ApiProperty({ type: Boolean, description: 'Stato fascicolazione' })
  inDossier!: boolean;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Creazione' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Aggiornamento' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Eliminazione' })
  deletedAt?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ExternalDocumentDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ExternalDocumentDto ? values : plainToClass(ExternalDocumentDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ExternalDocumentSchema.validate(classToPlain(this), options);
    return new ExternalDocumentDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ExternalDocumentDto */
export type IExternalDocumentType = IDto<ExternalDocumentDto>;

/**
 * DTO Paginato della classe ExternalDocument
 */
export class PaginatedExternalDocumentDto {
  @ApiProperty({ type: [ExternalDocumentDto] })
  @Type(() => ExternalDocumentDto)
  items!: ExternalDocumentDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ExternalDocumentSchema = yup
  .object({
    id: yup.number(),
    description: yup.string().nullable().label('Note'),
    contractId: yup.number().label('ID Contratto'),
    inDossier: yup.boolean().required().label('Stato fascicolazione')
  })
  .noUnknown()
  .meta({ schemaName: "ExternalDocumentSchema" })
  .required();

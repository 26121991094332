import React from 'react';
import { Collapse } from 'antd';
import clx from 'classnames';

import './GhostCollapse.scss';

export interface GhostCollapseProps {
  children: React.ReactNode;
  visible?: boolean;
}

export function GhostCollapse(props: GhostCollapseProps) {
  const { children, visible } = props;

  return (
    <Collapse
      bordered={false}
      className={clx('ghost-collapse')}
      activeKey={visible ? ['visible'] : []}
    >
      <Collapse.Panel header={null} key="visible">
        {children}
      </Collapse.Panel>
    </Collapse>
  );
}

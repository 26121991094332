import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import Text from 'antd/lib/typography/Text';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { ContractMachine } from 'common/schema/contract/ContractMachine';
import { ContractState } from 'common/schema/contract/ContractTypes';
import React, { useCallback, useState } from 'react';

const { Title } = Typography;

export interface IContractCancelModalProps {
  contract: ContractDto;
}

export function ContractCancelModal(props: IContractCancelModalProps) {
  const { contract } = props;

  const [isVisible, setIsVisible] = useState(false);
  const currentUser = useCurrentUser();

  const [cancelContract, cancelRequestMeta] = useApiMutation(
    ContractApi.actions.states.cancelled,
    { invalidates: [ContractApi.find] }
  );

  const handleCancel = useCallback(() => {
    setIsVisible(false);
  }, []);

  const showModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const handleCancelContract = useCallback(async () => {
    await cancelContract({ data: { id: contract.id } });
    setIsVisible(false);
  }, [contract]);

  // Se il contratto non può essere annullato, non mostro il pulsante
  if (
    !ContractMachine.to(contract, ContractState.Cancelled, currentUser).allowed
  ) {
    return null;
  }

  return (
    <>
      <Button type="ghost" danger onClick={showModal}>
        Annulla Contratto <DeleteOutlined />
      </Button>
      <ModalDialog
        title="Annullamento Contratto"
        visible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
        footer={
          <Space>
            <Button onClick={handleCancel}>Indietro</Button>
            <Button
              danger
              loading={cancelRequestMeta.loading}
              onClick={handleCancelContract}
            >
              Annulla il Contratto
            </Button>
          </Space>
        }
      >
        <Row>
          <Col>
            <Title level={4}>Sei sicuro di vole annullare il contratto?</Title>
          </Col>
          <Col>
            <Text type="secondary">
              Una volta annullato non sarà più possibile modificarne i dati o lo
              stato. L'azione è irreversibile.
            </Text>
          </Col>
        </Row>
      </ModalDialog>
    </>
  );
}

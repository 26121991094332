import * as React from 'react';
import { Result } from 'antd';
import './ForbiddenPage.scss';
import { PageLayout } from 'client/ui/layout/PageLayout';

export interface IForbiddenPageProps {}

export function ForbiddenPage(props: IForbiddenPageProps) {
  return (
    <PageLayout>
      <Result
        status="error"
        title="Non Autorizzato"
        subTitle="Non si dispone dei permessi per visualizzare la pagina richiesta."
      />
    </PageLayout>
  );
}

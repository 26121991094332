import * as React from 'react';
import { Typography, message, Tag } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Section } from 'client/ui/section/Section';
import { ArrowRightOutlined, InboxOutlined } from '@ant-design/icons';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { RouteComponentProps } from 'react-router';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useState } from 'react';
import { EditAndSaveButton } from 'client/ui/form/button/EditAndSaveButton';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import {
  TaxRecordDto,
  TaxRecordSchema
} from 'common/dto/generated/TaxRecordDto';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { TaxRecordApi } from 'client/components/schema/tax-record/TaxRecordApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { CurrencyInput } from 'client/ui/form/input/CurrencyInput';
import { TaxRecordSendToVerifyModal } from '../modal/TaxRecordSendToVerifyModal';
import { TaxRecordRefuseModal } from '../modal/TaxRecordRefuseModal';
import { TaxRecordAcceptModal } from '../modal/TaxRecordAcceptModal';
import { TaxRecordNotSendableModal } from '../modal/TaxRecordNotSendableModal';
import { TaxRecordStateTag } from '../TaxRecordStateTag';
import { ISODate } from 'client/ui/display/date/ISODate';
import { TaxRecordMachine } from 'server/schema/tax/tax-record/TaxRecordMachine';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ConfigEnumRenderValue } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { Link } from 'react-router-dom';
import { SectionButton } from 'client/ui/section/SectionButton';

interface Params {
  id: string;
}

export interface TaxRecordPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di dettaglio dell'anagrafica tributaria
 */
export function TaxRecordPage(props: TaxRecordPageProps) {
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;
  const [editable, setEditable] = useState(id == null);

  const currentUser = useCurrentUser();

  const { response, error, loading, refetch } = useApiQuery(TaxRecordApi.find, {
    skip: id == null,
    data: {
      id: id!
    }
  });

  // Eliminato possibilità di creazione scheda
  // const [create] = useApiMutation(TaxRecordApi.create, {
  //   invalidates: [TaxRecordApi.find]
  // });
  const [update] = useApiMutation(TaxRecordApi.update, {
    invalidates: [TaxRecordApi.find]
  });

  const taxRecord = response?.data;

  const consumer = taxRecord?.consumer;
  const contract = taxRecord?.contract;
  const supplier = taxRecord?.supplier;
  const contractSupplier = contract?.suppliers.find(
    s => s.supplierId === supplier?.id
  );

  const pageName = `${taxRecord?.paymentYear} | Contratto ID ${
    taxRecord?.contractId
  } | ${taxRecord?.supplier?.getName()}`;

  const canEdit = TaxRecordMachine.can(taxRecord, 'edit', currentUser);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/tax/records',
    title: `Schede Anagrafe Tributaria`
  });
  useBreadcrumbItem({
    priority: 20,
    path: props.location.pathname,
    title: `Anno ${pageName}`,
    menuKey: 'tax'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere alla scheda di Anagrafe Tributaria"
        error={error}
      />
    );
  }

  if (!taxRecord || loading) return <LoadingPage />;

  return (
    <CorePageLayout>
      <FormikAugmented<TaxRecordDto>
        enableReinitialize
        initialValues={taxRecord}
        validationSchema={TaxRecordSchema}
        validateOnChange={false}
        validationContext={{}}
        onReset={() => {
          setEditable(false);
        }}
        onSubmit={async values => {
          if (!editable) return;
          try {
            const input = await values.validate();
            if (id) {
              await update({ data: { input } });
              message.success('Scheda Anagrafica aggiornata con successo.');
              setEditable(false);
            } else {
              // const result = await create({
              //   data: { input }
              // });
              // message.success('Scheda Anagrafica aggiunto con successo.');
              // props.history.push(`/contracts/supplier/${result.data!.id}`);
            }
          } catch (e) {
            message.error(
              'Si è verificato un errore nella gestione del scheda anagrafica'
            );
            console.error(e);
          }
        }}
      >
        <FormikForm editable={editable} helpInTooltips>
          <PageHeadingRogante
            fixedWidth
            title={<PageHeadingTitle children={`${pageName}`} />}
            breadcrumbRender={() => <BreadcrumbsContainer />}
            tags={
              id == null ? (
                <Tag>Non ancora salvata</Tag>
              ) : (
                <TaxRecordStateTag state={taxRecord.state} />
              )
            }
            extra={
              <>
                {canEdit.allowed && (
                  <EditAndSaveButton
                    isNew={id == null}
                    name="Scheda"
                    state={[editable, setEditable]}
                  />
                )}
                {!editable && (
                  <>
                    <TaxRecordSendToVerifyModal taxRecord={taxRecord} />
                    <TaxRecordRefuseModal taxRecord={taxRecord} />
                    <TaxRecordAcceptModal taxRecord={taxRecord} />
                    <TaxRecordNotSendableModal taxRecord={taxRecord} />
                  </>
                )}
              </>
            }
          />
          <PageContent fixedWidth>
            <Section
              icon={<InboxOutlined />}
              title="Soggetto obbligato alla comunicazione"
              hasPadding
            >
              <FormFieldsContainer columns={2}>
                <DataItem inline label="Denominazione" value={consumer?.name} />
                <DataItem
                  inline
                  label="Codice Fiscale"
                  value={consumer?.fiscalNumber}
                />
              </FormFieldsContainer>
              <FormFieldsContainer columns={4}>
                <DataItem
                  inline
                  colSpan={2}
                  label="Indirizzo"
                  value={consumer?.address}
                />
                <DataItem
                  inline
                  label="Comune"
                  value={
                    (consumer?.city ?? '-') +
                    ' (' +
                    (consumer?.province ?? '-') +
                    ')'
                  }
                />
                <DataItem inline label="CAP" value={consumer?.zip} />
              </FormFieldsContainer>
            </Section>

            <Section
              icon={<InboxOutlined />}
              title="Dati del Contratto"
              hasPadding
              extra={
                <SectionButton
                  type="default"
                  to={`/contracts/${taxRecord?.contractId}`}
                >
                  Vai al Contratto <ArrowRightOutlined />
                </SectionButton>
              }
            >
              <FormFieldsContainer columns={3}>
                <DataItem
                  inline
                  colSpan={3}
                  label="Oggetto:"
                  value={contract?.subject}
                />
                <DataItem
                  inline
                  label="Anno Stipula"
                  value={contract?.stipulatedYear}
                />
                <DataItem
                  inline
                  label="Tipo di Contratto"
                  value={
                    <ConfigEnumRenderValue
                      name="typologyType"
                      feature="contract.values"
                      enumKey="typologyType"
                      value={contract?.typologyType}
                    />
                  }
                />
                <DataItem
                  inline
                  label="Numero Registro Cronologico"
                  value={contract?.documentNumber}
                />

                <DataItem
                  inline
                  label="Data Stipula"
                  value={<ISODate date={contract?.stipulatedAt} />}
                />
                <DataItem
                  inline
                  label="Data decorrenza"
                  value={<ISODate date={contract?.validityStartAt} />}
                />
                <DataItem
                  inline
                  label="Data scadenza"
                  value={<ISODate date={contract?.validityEndAt} />}
                />
              </FormFieldsContainer>
            </Section>
            <Section
              icon={<InboxOutlined />}
              title="Dati del Contraente"
              hasPadding
            >
              <FormFieldsContainer columns={3}>
                <DataItem
                  inline
                  colSpan={2}
                  label="Denominazione"
                  value={supplier?.getName()}
                />
                <DataItem
                  inline
                  label="Codice Fiscale / Partita IVA"
                  value={supplier?.getFiscalNumber()}
                />
                <DataItem
                  inline
                  colSpan={3}
                  label="Sede Legale"
                  value={supplier?.hqAddress}
                />
              </FormFieldsContainer>
            </Section>
            <Section
              icon={<InboxOutlined />}
              title="Dati Comunicazione"
              hasPadding
            >
              <FormFieldsContainer columns={3}>
                <DataItem
                  inline
                  label="Importo Totale"
                  value={
                    <CurrencyRender
                      currency="EUR"
                      value={contractSupplier?.amount}
                    />
                  }
                />
                <DataItem
                  inline
                  label="Anno Pagamento"
                  value={taxRecord.paymentYear}
                />
                <FormFieldItem
                  inline
                  readOnly={!canEdit.allowed}
                  component={CurrencyInput}
                  label="Importo Corrisposto"
                  name="paymentAmount"
                />
              </FormFieldsContainer>
            </Section>
          </PageContent>
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}

import { resolveApiPath } from 'client/core/appConfig';
import { apiClient } from 'client/core/network/apiClient';
import {
  ContractDocumentDto,
  PaginatedContractDocumentDto
} from 'common/dto/generated/ContractDocumentDto';
import { ContractDocumentQueryDto } from 'common/dto/query/ContractDocumentQueryDto';

export const ContractDocumentApi = {
  list: (opts: { params: ContractDocumentQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedContractDocumentDto)
      .get(`contracts/${opts.params.contractId}/documents`, {
        params: opts.params
      }),

  create: (opts: { contractId: number; data: FormData }) =>
    apiClient
      .requestWithResponseType(ContractDocumentDto)
      .post(`/contracts/${opts.contractId}/documents`, opts.data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }),

  update: (opts: { contractId: number; id: number; data: FormData }) =>
    apiClient
      .requestWithResponseType(ContractDocumentDto)
      .put(`/contracts/${opts.contractId}/documents/${opts.id}`, opts.data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }),

  downloadLink: (opts: { contractId: number; id: number }) =>
    resolveApiPath(
      `/contracts/${opts.contractId}/documents/${opts.id}/actions/download`
    ),
  remove: (opts: { contractId: number; id: number }) =>
    apiClient
      .request()
      .delete(`/contracts/${opts.contractId}/documents/${opts.id}`)
};

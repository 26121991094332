import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  QuestionCircleFilled
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { IContractType } from 'common/dto/generated/ContractDto';
import { useField, useFormikContext } from 'formik';
import React from 'react';

export interface IContractParentInputProps {}

export function ContractParentInput(props: IContractParentInputProps) {
  const {} = props;
  const formik = useFormikContext<IContractType>();

  const [field, meta, helper] = useField('parentDocumentNumber');
  const needParent = formik.values.parentId != null;
  const parentNotFound = formik.values.parentPresence === false;

  const isDirty = field.value != meta.initialValue;

  return (
    <>
      <FormFieldItem
        labelWidth="width-180"
        label={
          <span>
            <Tooltip
              placement="topLeft"
              title="Indicare, se estensione di altro Contratto, il numero cronologico di registrazione del Contratto da estendere"
            >
              <a>
                <QuestionCircleFilled />
              </a>{' '}
            </Tooltip>{' '}
            {!isDirty && parentNotFound && (
              <Tooltip
                placement="topLeft"
                title="Il contratto di riferimento non è presente sul sistema"
              >
                <a>
                  <ExclamationCircleFilled style={{ color: '#cf1322' }} />
                </a>
              </Tooltip>
            )}{' '}
            {/* {!isDirty && parentPresence && (
              <>
                <a>
                  <CheckCircleFilled style={{ color: '#389e0d' }} />
                </a>{' '}
              </>
            )} */}
            Estensione Contratto:
          </span>
        }
        component={NumberInput}
        name="parentDocumentNumber"
        placeholder=" Numero di registrazione"
      />
    </>
  );
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { DocumentKind } from 'common/schema/contract/contract-document/ContractDocumentTypes';
import schema from 'yup/lib/schema';

/**
 * Rappresentazione DTO della classe ContractDocument definita in: src/server/schema/contract/contract-document/ContractDocument.entity.ts
 * Hash: 7448a73e321becaff11f8291fbb70825
 */
@ValidationSchema(() => ContractDocumentSchema)
export class ContractDocumentDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number, description: 'ID Originale migrazione' })
  migrationId?: number | null | undefined;
  @ApiProperty({ type: String, description: 'Percorso documento' })
  filePath!: string;
  @ApiProperty({ type: String, description: 'Nome file' })
  filename!: string;
  @ApiProperty({ type: String, description: 'Descrizione documento' })
  description!: string;
  @ApiProperty({ type: Boolean, description: 'Caricato sul documentale' })
  uploaded!: boolean;
  @ApiProperty({ required: false, type: Number, description: 'UID Documentale' })
  documentalUid?: number | null | undefined;
  @ApiProperty({ enum: ['Principal', 'Attachment', 'Act', 'Other'], description: 'Tipo documento' })
  kind!: DocumentKind;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deleteAt?: Date | null;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: Number, description: 'Id Contratto' })
  contractId!: number;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractDocumentDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractDocumentDto ? values : plainToClass(ContractDocumentDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ContractDocumentSchema.validate(classToPlain(this), options);
    return new ContractDocumentDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ContractDocumentDto */
export type IContractDocumentType = IDto<ContractDocumentDto>;

/**
 * DTO Paginato della classe ContractDocument
 */
export class PaginatedContractDocumentDto {
  @ApiProperty({ type: [ContractDocumentDto] })
  @Type(() => ContractDocumentDto)
  items!: ContractDocumentDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ContractDocumentSchema = yup
  .object({
    id: yup.number(),
    description: yup.string().required().label('Descrizione documento'),
    kind: yup.string().oneOfEnum(DocumentKind).required().test(
          'checkPrincipalDocument',
          'È possibile inserire un unico atto principale.',
          (value, context) => {
            if (
              [
                DocumentKind.Attachment,
                DocumentKind.Act,
                DocumentKind.Other
              ].includes(value)
            ) {
              return true;
            }

            const documents: any[] = context.options.context?.documents;
            if (!documents) {
              return true;
            }

            const principals = documents.filter(
              (d: any) => d.kind === DocumentKind.Principal
            );
            if (principals.length === 0) {
              return true;
            }

            if (principals.length === 1) {
              return context.parent.id === principals[0].id;
            }

            return false;
          }
        ).label('Tipo documento'),
    contractId: yup.number().label('Id Contratto')
  })
  .noUnknown()
  .meta({ schemaName: "ContractDocumentSchema" })
  .required();

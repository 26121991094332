import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaNotRequired, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";
import { ContractSupplierDto } from "../generated/ContractSupplierDto";
import { ArraySchema } from 'yup';
import { values } from 'lodash';
import { ContractTypologyType } from 'common/schema/contract/ContractTypes';


/**
 * Sovrascrive i campi del contratto per la validazione
 * dei contratti preregistrati.
 */
@GenerateDto()
class PreRegisteredContractBase {
    @SchemaLabel('Numero Registro Cronologico')
    documentNumber!: number;

    @SchemaLabel('Organizzazione')
    consumerId!: number;

    @SchemaLabel('Ufficio Ufficiale Rogante')
    rogatoryOfficeId!: number;

    @SchemaLabel('Ufficio Proponente')
    officeId!: number;

    @SchemaLabel('Utente dell’ufficio Proponente (UD)')
    authorId!: number;

    @SchemaLabel('Anno Stipula Contratto')
    stipulatedYear!: number;

    @SchemaLabel('Importo Totale')
    grossAmount!: Decimal;

    @SchemaLabel('Tipo di Contratto')
    typologyType!: string;

    @SchemaLabel('con Scheda Anagrafica')
    @SchemaValidate(schema => schema.when('typologyType', 
    {
      is: ContractTypologyType.NoTaxRecords, 
      then: (schema) => schema.oneOf([false],'Non è possibile impostare la scheda anagrafica con il tipo di contratto selezionato'), otherwise: schema => schema
    }).notRequired().default(false))
    hasTaxRecords!: boolean | undefined;

    @SchemaLabel('Dati Contraente')
    @Type(() => ContractSupplierDto)
    @SchemaValidate((schema: ArraySchema<any>) => schema
    .min(1, 'È necessario inserire almeno un contraente')
    .test('check-amount', "È necessario indicare l'Importo Contratto di tutti i Contraenti", 
    (suppliers: ContractSupplierDto[] | undefined) => {
      if (!suppliers) return true;
      return suppliers.some(supplier => !!supplier.amount)
    }))
    suppliers!: ContractSupplierDto[];
}

/**
 * Rappresentazione DTO della classe PreRegisteredContractBase 
 * Hash: 0dd1868a38001f998422c0dd7aaab6af
 */
@ValidationSchema(() => PreRegisteredContractBaseSchema)
export class PreRegisteredContractBaseDto {
  @ApiProperty({ type: Number, description: 'Numero Registro Cronologico' })
  documentNumber!: number;
  @ApiProperty({ type: Number, description: 'Organizzazione' })
  consumerId!: number;
  @ApiProperty({ type: Number, description: 'Ufficio Ufficiale Rogante' })
  rogatoryOfficeId!: number;
  @ApiProperty({ type: Number, description: 'Ufficio Proponente' })
  officeId!: number;
  @ApiProperty({ type: Number, description: 'Utente dell’ufficio Proponente (UD)' })
  authorId!: number;
  @ApiProperty({ type: Number, description: 'Anno Stipula Contratto' })
  stipulatedYear!: number;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Importo Totale' })
  @TypeTransformDecimal()
  grossAmount!: Decimal;
  @ApiProperty({ type: String, description: 'Tipo di Contratto' })
  typologyType!: string;
  @ApiProperty({ required: false, type: Boolean, description: 'con Scheda Anagrafica' })
  hasTaxRecords?: boolean | undefined;
  @ApiProperty({ type: [ContractSupplierDto], description: 'Dati Contraente' })
  suppliers!: ContractSupplierDto[];

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<PreRegisteredContractBaseDto>) {
    if (values != null) {
      Object.assign(this, values instanceof PreRegisteredContractBaseDto ? values : plainToClass(PreRegisteredContractBaseDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await PreRegisteredContractBaseSchema.validate(classToPlain(this), options);
    return new PreRegisteredContractBaseDto(validated);
  }
}

export const PreRegisteredContractBaseSchema = yup
  .object({
    documentNumber: yup.number().required().label('Numero Registro Cronologico'),
    consumerId: yup.number().required().label('Organizzazione'),
    rogatoryOfficeId: yup.number().required().label('Ufficio Ufficiale Rogante'),
    officeId: yup.number().required().label('Ufficio Proponente'),
    authorId: yup
      .number()
      .required()
      .label('Utente dell’ufficio Proponente (UD)'),
    stipulatedYear: yup.number().required().label('Anno Stipula Contratto'),
    grossAmount: yup.mixed().required().label('Importo Totale'),
    typologyType: yup.string().required().label('Tipo di Contratto'),
    hasTaxRecords: yup
      .boolean()
      .when('typologyType', {
        is: ContractTypologyType.NoTaxRecords,
        then: schema =>
          schema.oneOf(
            [false],
            'Non è possibile impostare la scheda anagrafica con il tipo di contratto selezionato'
          ),
        otherwise: schema => schema
      })
      .notRequired()
      .default(false)
      .label('con Scheda Anagrafica'),
    suppliers: yup
      .array(yup.mixed().required())
      .required()
      .min(1, 'È necessario inserire almeno un contraente')
      .test(
        'check-amount',
        "È necessario indicare l'Importo Contratto di tutti i Contraenti",
        (suppliers: ContractSupplierDto[] | undefined) => {
          if (!suppliers) return true;
          return suppliers.some(supplier => !!supplier.amount);
        }
      )
      .label('Dati Contraente')
  })
  .noUnknown()
  .meta({ schemaName: "PreRegisteredContractBaseSchema" })
  .required();

import { yup } from './initYup';

export const ValidationSchemaMetadataKey = 'yup:schema';

export function ValidationSchema(
  schema: () => yup.BaseSchema<any>
): ClassDecorator {
  return (target: Function) => {
    Reflect.defineMetadata(ValidationSchemaMetadataKey, schema, target);
  };
}

export function getValidationSchema(
  target: Function
): () => yup.BaseSchema<any> {
  return Reflect.getMetadata(ValidationSchemaMetadataKey, target);
}

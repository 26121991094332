import * as React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { CountryCodeOptions } from 'common/schema/country-codes/CountryCodeOptions';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { RadioInput } from 'client/ui/form/input/RadioInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { genderOptions } from './genderOptions';

export interface SupplierFormFieldItemsProps {}

/**
 * Campi impaginati dell'anagrafica Fornitore
 */
export function SupplierFormFieldItems(props: SupplierFormFieldItemsProps) {
  const labelWidth: ILabelWidth = 'width-140';
  return (
    <>
      <FormFieldsContainer
        title="Denominazione Fornitore"
        removeTopTitleSpacing
      >
        <FormFieldItem
          component={TextInput}
          label="Denominazione"
          name="companyName"
          placeholder="Denominazione"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={8}>
        <FormFieldItem
          colSpan={3}
          label="Nome"
          placeholder="Nome"
          component={TextInput}
          name="name"
        />
        <FormFieldItem
          inline
          colSpan={3}
          label="Cognome"
          placeholder="Cognome"
          component={TextInput}
          name="surname"
        />
        <FormFieldItem
          inline
          colSpan={2}
          label="Sesso"
          component={RadioInput}
          name="gender"
          options={genderOptions}
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={8}>
        <FormFieldItem
          colSpan={3}
          component={TextInput}
          label="Codice Fiscale"
          placeholder="Codice Fiscale"
          name="fiscalNumber"
        />
        <FormFieldItem
          inline
          colSpan={2}
          labelWidth={labelWidth}
          component={SelectInput}
          dropdownMatchSelectWidth={true}
          options={CountryCodeOptions}
          showSearch
          optionFilterProp="value"
          label="Sigla Nazione IVA"
          placeholder="Sigla"
          name="fiscalIdCountry"
        />
        <FormFieldItem
          inline
          colSpan={3}
          component={TextInput}
          label="Codice IVA"
          placeholder="Codice IVA"
          name="fiscalIdCode"
        />
      </FormFieldsContainer>

      {/* Se Contraente è Pesona Fisica */}

      <FormFieldsContainer columns={8}>
        <FormFieldItem
          colSpan={3}
          label="Comune Nascita"
          placeholder="Comune Nascita"
          component={TextInput}
          name="bornCity"
        />
        <FormFieldItem
          inline
          colSpan={2}
          label="Provincia Nascita"
          placeholder="Provincia Nascita"
          component={TextInput}
          name="bornProvince"
        />
        <FormFieldItem
          inline
          colSpan={3}
          label="Data Nascita"
          placeholder="Data Nascita"
          type="date-only"
          component={DatePickerInput}
          name="bornDate"
        />
      </FormFieldsContainer>

      <FormFieldsContainer title="Indirizzo Sede Legale" columns={6}>
        <FormFieldItem
          colSpan={4}
          component={TextInput}
          label="Indirizzo"
          placeholder="Indirizzo"
          name="address"
        />
        <FormFieldItem
          inline
          label="Numero"
          placeholder="Numero"
          component={TextInput}
          name="civic"
        />
        <FormFieldItem
          inline
          component={TextInput}
          label="CAP"
          placeholder="CAP"
          name="zip"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={3}>
        <FormFieldItem
          label="Comune"
          placeholder="Comune"
          component={TextInput}
          name="city"
        />
        <FormFieldItem
          inline
          label="Provincia"
          placeholder="Provincia"
          component={TextInput}
          name="province"
        />
        <FormFieldItem
          inline
          label="Nazione"
          placeholder="Nazione"
          component={TextInput}
          name="country"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={3}>
        <FormFieldItem
          label="Telefono"
          placeholder="Telefono"
          component={TextInput}
          name="phoneNumber"
        />
        <FormFieldItem
          inline
          label="Fax"
          placeholder="Fax"
          component={TextInput}
          name="faxNumber"
        />
        <FormFieldItem
          inline
          label="Email"
          placeholder="Email"
          component={TextInput}
          name="email"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={1}>
        <FormFieldItem
          label="Sede Operativa"
          placeholder="Sede Operativa"
          name="operationsAddress"
          component={TextAreaInput}
          rows={2}
        />
      </FormFieldsContainer>

      {/* Dati legale Rappresentante */}
    </>
  );
}

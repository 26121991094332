import { apiClient } from 'client/core/network/apiClient';
import { PaginatedActivityLogDto } from 'common/dto/generated/ActivityLogDto';
import { ActivityLogQueryDto } from 'common/dto/query/ActivityLogQueryDto';

export const ActivityLogApi = {
  list: (opts: { query: ActivityLogQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedActivityLogDto)
      .get(`/activity-log`, { params: opts.query })
};

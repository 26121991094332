import { EuroOutlined } from '@ant-design/icons';
import { Alert, Button, message, Space, Tooltip, Typography } from 'antd';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import {
  SendFileNotificationDto,
  SendFileNotificationSchema
} from 'common/dto/schema/SendFileNotificationDto';
import { ContractState } from 'common/schema/contract/ContractTypes';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import {
  ContractTerminationDto,
  ContractTerminationSchema
} from 'common/dto/schema/ContractTerminationDto';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { ContractMachine } from 'common/schema/contract/ContractMachine';

export interface ContractTerminationModalProps {
  contract?: ContractDto;
}

/**
 * Modal per la Chiusura del contratto da parte di UR (Ufficio Proponente)
 * permette di modificare lo stato del contratto in Pagato
 * e inserire la nota conclusiva
 *
 */
export function ContractTerminationModal(props: ContractTerminationModalProps) {
  const { contract } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const currentUser = useCurrentUser();
  const [setAsPaid] = useApiMutation(ContractApi.actions.states.paid, {
    invalidates: [ContractApi.find]
  });

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const transition = ContractMachine.to(
    contract,
    ContractState.Paid,
    currentUser
  );

  if (!transition.allowed && transition.skipped) return null;
  if (!contract) return null;

  return (
    <>
      <Tooltip title={!transition.allowed && transition.message}>
        <Button
          type="primary"
          onClick={showModal}
          disabled={!transition.allowed}
        >
          Salda Contratto <EuroOutlined />
        </Button>
      </Tooltip>

      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={new ContractTerminationDto()}
        validate={schemaToValidator(ContractTerminationSchema)}
        onSubmit={async values => {
          try {
            const input = await values.validate();
            console.log(input);
            await setAsPaid({ data: { id: contract.id, data: input } });
            message.success('Operazione riuscita');
            handleCancel();
          } catch (e) {
            message.error('Si è verificato un errore. Riprovare.'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        <>
          <FormErrorsNotification />
          <FormikForm nested inline editable={true} helpInTooltips>
            <ModalDialog
              title="Saldo e Conclusione del Contratto"
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={
                <Space>
                  <Button onClick={handleCancel}>Annulla</Button>
                  <FormikSendButton>
                    Salda Contratto <EuroOutlined />
                  </FormikSendButton>
                </Space>
              }
            >
              {props.contract?.hasTaxRecords && (
                <Alert
                  message="Comunicazioni all'Anagrafe Tributaria"
                  description="Una volta Saldato il Contratto non saranno più generate le schede annuali di Anagrafe Tributaria collegate al Contratto."
                  type="info"
                  showIcon
                  style={{ marginBottom: '24px' }}
                />
              )}
              <FormFieldsContainer>
                <FormFieldItem
                  component={TextAreaInput}
                  label="Nota Finale"
                  name="note"
                  placeholder="Nota di conclusione Contratto"
                  rows={4}
                />
              </FormFieldsContainer>
            </ModalDialog>
          </FormikForm>
        </>
      </Formik>
    </>
  );
}

import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { AuthCurrentLoader } from 'client/components/auth/current-loader/AuthCurrentLoader';
import { useAuth } from 'client/components/auth/AuthModule';
import { NotFoundPage } from 'client/components/errors/not-found/NotFoundPage';
import { useModalHistoryCleanup } from 'client/ui/modal/useModalHistoryCleanup';
import { ClientConfigLoader } from 'client/components/schema/config/ClientConfigLoader';
import { LoginPage } from 'client/components/auth/login/LoginPage';
import { ContractPage } from './contract/contract/single/ContractPage';
import { ContractListPage } from './contract/contract/list/ContractsListPage';
import { SupplierPage } from './supplier/single/SupplierPage';
import { PrivateRoute } from 'client/components/auth/routes/PrivateRoute';
import { ConsumerListPage } from './contract/consumer/list/ConsumerListPage';
import { ConsumerPage } from './contract/consumer/single/ConsumerPage';
import { TaxRecordPage } from './tax-record/single/TaxRecordPage';
import { SupplierListPage } from './supplier/list/SupplierListPage';
import { TaxRecordListPage } from './tax-record/list/TaxRecordListPage';
import { TaxExportListPage } from './tax-export/list/TaxExportListPage';
import { PreregisteredContractPage } from './contract/contract/single/PreregisteredContractPage';
import { UserListPage } from './contract/user/list/UserListPage';

export const Routes = () => {
  const { logged } = useAuth();

  // const permissions = usePermissions();

  // Chiudiamo tutte le modal alla navigazione
  useModalHistoryCleanup();

  return (
    <ClientConfigLoader>
      <AuthCurrentLoader>
        {!logged ? (
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Redirect to={{ pathname: '/login' }} />
          </Switch>
        ) : (
          <Switch>
            <Redirect exact from="/login" to="/" />
            <Redirect exact from="/" to="/contracts" />
            {/** Contratti  */}
            <Route
              path="/contracts/preregistered"
              component={PreregisteredContractPage}
            />
            <Route path="/contracts/:id" component={ContractPage} />
            <Route path="/contracts" component={ContractListPage} />
            {/** Contraenti  */}
            <Route path="/suppliers/:id" component={SupplierPage} />
            <Route path="/suppliers" component={SupplierListPage} />
            {/** Committenti */}
            <PrivateRoute
              path="/consumers/:id"
              permission="consumers.write"
              component={ConsumerPage}
            />
            <PrivateRoute
              path="/consumers/"
              permission="consumers.write"
              component={ConsumerListPage}
            />
            <PrivateRoute
              path="/users/"
              permission="users.write"
              component={UserListPage}
            />
            {/* <Route path="/suppliers" component={ContractListPage} /> */}
            {/** Schede Anagrafica Tributaria  */}
            <Route path="/tax/records/:id" component={TaxRecordPage} />
            <Route path="/tax/records" component={TaxRecordListPage} />
            <Route path="/tax/exports" component={TaxExportListPage} />

            {/** Pagina non trovata */}
            <Route component={NotFoundPage} />
          </Switch>
        )}
      </AuthCurrentLoader>
    </ClientConfigLoader>
  );
};

import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { getProvinceStructure } from 'common/data/provinces/getProvinceStructure';
import { useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

interface IProvinceInputOptions {
  provinceField: string;
  cityField: string;
}

/**
 * Permette di gestire in modo standard la selezione a cascata di Provincia
 * e Comune; prevede il reset del Comune al cambiamento della Provincia.
 */
export function useProvinceInput(options: IProvinceInputOptions) {
  const provinces = getProvinceStructure();
  const [provinceFieldInput] = useField(options.provinceField);
  const provinceOptions = useMemo(
    () =>
      provinces.map(p => ({ label: `${p.nome} (${p.sigla})`, value: p.nome })),
    []
  );

  const province = provinceFieldInput.value;
  const cityOptions = provinces
    .find(p => p.nome === province)
    ?.comuni.map(c => ({ label: c.nome, value: c.nome }));

  useFormikFieldChange(
    formik => {
      formik.setFieldValue(options.cityField, null);
    },
    [options.provinceField]
  );

  return {
    provinceOptions,
    cityOptions
  };
}

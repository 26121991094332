import { Button, ButtonProps } from 'antd';
import { useFormikContext } from 'formik';
import * as React from 'react';

export interface IFormikSendButtonProps extends ButtonProps {
  disabledIfPristine?: boolean;
}

export function FormikSendButton(props: IFormikSendButtonProps) {
  const { disabledIfPristine, ...otherProps } = props;
  const formik = useFormikContext();
  const disabled = disabledIfPristine
    ? formik.isSubmitting || !formik.dirty
    : formik.isSubmitting;

  return (
    <Button
      type="primary"
      disabled={disabled}
      loading={formik.isSubmitting}
      {...otherProps}
      onClick={formik.submitForm}
    />
  );
}

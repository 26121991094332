import * as React from 'react';

export interface PageHeadingTitleProps {
  icon?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

/**
 * Titolo della singola pagina
 * Da passare nella proprietà "title" di <PageHeading />
 *
 * Esempio utilizzo
 * <PageHeading title={<PageHeadingTitle>...} ... />
 */
export function PageHeadingTitle(props: PageHeadingTitleProps) {
  const { children, icon, ...othersProps } = props;
  return (
    <span {...othersProps}>
      {children}
      {icon}
    </span>
  );
}

import { AxiosInstance } from 'axios';
import { ApiClient } from './apiClient';

export class ApiClientRequest<T extends Constructor<any> = any> {
  constructor(readonly client: ApiClient, readonly dtoType?: T) {}

  /**
   * Richiesta GET
   */
  get(...[url, config]: Parameters<AxiosInstance['get']>) {
    return this.client.instance.get<InstanceType<T>>(url, {
      ...config,
      dto: this.dtoType
    });
  }

  /**
   * Richiesta POST
   */
  post(...[url, data, config]: Parameters<AxiosInstance['post']>) {
    return this.client.instance.post<InstanceType<T>>(url, data, {
      ...config,
      dto: this.dtoType
    });
  }

  /**
   * Richiesta PUT
   */
  put(...[url, data, config]: Parameters<AxiosInstance['put']>) {
    return this.client.instance.put<InstanceType<T>>(url, data, {
      ...config,
      dto: this.dtoType
    });
  }

  /**
   * Richiesta DELETE
   */
  delete(...[url, config]: Parameters<AxiosInstance['delete']>) {
    return this.client.instance.delete<InstanceType<T>>(url, {
      ...config,
      dto: this.dtoType
    });
  }
}

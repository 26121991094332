import { FileOutlined } from '@ant-design/icons';
import { AuthLink } from 'client/core/router/links/AuthLink';
import { createTypedColumns } from 'client/ui/table/createTypedColumns';
import { ContractDocumentDto } from 'common/dto/generated/ContractDocumentDto';
import React from 'react';
import { ContractDocumentApi } from '../ContractDocumentApi';
import { ContractDocumentKindTag } from '../ContractDocumentKindTag';

export const ContractDocumentColumns = createTypedColumns(ContractDocumentDto, {
  filename: {
    title: 'Nome File',
    dataIndex: 'filename',
    ellipsis: true,
    render: (filename: string, document: ContractDocumentDto) => {
      return (
        <AuthLink
          inline
          href={ContractDocumentApi.downloadLink({
            contractId: document.contractId,
            id: document.id
          })}
        >
          <a>
            <FileOutlined /> {filename}
          </a>
        </AuthLink>
      );
    }
  },
  description: {
    title: 'Descrizione',
    dataIndex: 'description',
    ellipsis: true
  },
  kind: {
    title: 'Tipo',
    dataIndex: 'kind',
    sorter: true,
    width: 130,
    render: kind => <ContractDocumentKindTag kind={kind} />
  }
});

import React, { useState } from 'react';
import {
  DownloadOutlined,
  FileOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, message, Result, Space } from 'antd';
import { ConsumerApi } from 'client/components/schema/consumer/ConsumerApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { FileGenerationResult } from './FileGenerationResult';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { TaxExportApi } from 'client/components/schema/tax-export/TaxExportApi';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { AuthLink } from 'client/core/router/links/AuthLink';
import { resolveApiPath } from 'client/core/appConfig';
import {
  TaxExportCreateInputDto,
  TaxExportCreateInputSchema
} from 'common/dto/query/TaxExportCreateInputDto';
import { logger } from 'client/core/logger/logger';

export interface ITaxExportModalButtonProps {}

export function TaxExportModalButton(props: ITaxExportModalButtonProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [editable, setEditable] = useState(false);

  // Generazione export
  const [create, createState] = useApiMutation(TaxExportApi.create, {
    invalidates: [TaxExportApi.list]
  });
  // const isCreated = createState.response?.data != null;
  const [isCreated, setIsCreated] = useState(false);

  // Elenco dei committenti
  const { response, error, loading } = useApiQuery(ConsumerApi.list, {
    data: {}
  });
  const consumers = response?.data;
  const options = consumers?.items.map(consumer => {
    return {
      label: `${consumer.name} (C.F. ${consumer.fiscalNumber})`,
      value: consumer.id
    };
  });

  const handleOpen = () => {
    setModalVisible(true);
    setEditable(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setEditable(false);
    setIsCreated(false);
  };

  return (
    <>
      <Button
        type="primary"
        disabled={error || loading}
        icon={<PlusOutlined />}
        onClick={handleOpen}
      >
        Nuova Esportazione
      </Button>

      <FormikAugmented<TaxExportCreateInputDto>
        enableReinitialize
        validateOnChange={false}
        initialValues={new TaxExportCreateInputDto()}
        validationSchema={TaxExportCreateInputSchema}
        validationContext={{}}
        onSubmit={async values => {
          try {
            const input = await values.validate();
            const result = await create({ data: { input } });
            if (result) {
              setIsCreated(true);
            }
          } catch (e) {
            message.error(`Si è verificato un errore durante la generazione dell'esportazione. Si prega di riprovare`); // prettier-ignore
            logger.error('[TaxExportModal] Error', e);
          }
        }}
      >
        <>
          <FormErrorsNotification />
          <FormikForm editable={editable} helpInTooltips>
            <ModalDialog
              afterClose={() => {}}
              size="small"
              title="Nuova Esportazione Comunicazione Anagrafe Tributaria"
              visible={modalVisible}
              onCancel={handleCloseModal}
              footer={
                !isCreated ? (
                  <Space>
                    <Button onClick={handleCloseModal}>Annulla</Button>
                    <FormikSendButton>
                      <DownloadOutlined /> Genera Esportazione
                    </FormikSendButton>
                  </Space>
                ) : null
              }
              bodyStyle={{
                minHeight: `${editable ? 120 : 120 + 53}px`,
                position: 'relative'
              }}
            >
              {!isCreated && (
                <FormFieldsContainer>
                  <FormFieldItem
                    labelWidth="width-160"
                    component={SelectInput}
                    name="consumerId"
                    label="Seleziona Committente"
                    size="small"
                    dropdownMatchSelectWidth={false}
                    options={options}
                  />
                  {/* <FormFieldItem
                      labelWidth="width-160"
                      component={AsyncSelectInput}
                      name="consumerId"
                      label="Seleziona Committente"
                      size="small"
                      placeholder="Seleziona Committente"
                      query={{
                        apiFn: ConsumerApi.list,
                        options: (formik, search) => ({
                          data: {}
                        })
                      }}
                      dropdownMatchSelectWidth={false}
                      // showSearch // Non funziona "in-memory", o si implementa sull'AsyncSelectInput o si modifica la query
                      responseTransform={data => data.items}
                      responseMeta={data => ({
                        shown: data.items.length,
                        total: data.meta.total
                      })}
                      optionTransform={(consumer: ConsumerDto) => ({
                        label: `${consumer.name} (C.F. ${consumer.fiscalNumber})`,
                        value: consumer.id
                      })}
                    /> */}
                  <FormFieldItem
                    labelWidth="width-160"
                    component={NumberInput}
                    name="year"
                    label="Anno Pagamenti"
                    size="small"
                  />
                </FormFieldsContainer>
              )}
              {createState.loading && <LoaderSpin full transparent />}
              {isCreated && (
                <FileGenerationResult
                  error={createState.error}
                  buttons={[
                    <Button onClick={handleCloseModal} key="cancel">
                      Chiudi
                    </Button>,

                    <AuthLink
                      inline
                      href={resolveApiPath(
                        `/tax-exports/${createState.response.data.id}/download`
                      )}
                    >
                      <Button
                        type="primary"
                        key="download"
                        icon={<DownloadOutlined />}
                      >
                        Download File
                      </Button>
                    </AuthLink>
                  ]}
                />
              )}
            </ModalDialog>
          </FormikForm>
        </>
      </FormikAugmented>
    </>
  );
}

/** Stato del Contratto */
export enum ContractState {
  /** In lavorazione */
  InProgress = 'InProgress',
  /** In Verifica */
  ToVerify = 'ToVerify',
  /** In Revisione */
  ToReview = 'ToReview',
  /** In Registrazione */
  InRegistration = 'InRegistration',
  /** Registrato */
  Registered = 'Registered',
  /** Saldato */
  Paid = 'Paid',
  /** Chiuso (da migrazione) */
  Closed = 'Closed',
  /** Annullato */
  Cancelled = 'Cancelled'
}

export enum ContractOrderBy {
  id = 'id',
  stipulatedAt = 'stipulatedAt',
  createdAt = 'createdAt',
  validityStartAt = 'validityStartAt',
  stipulatedYear = 'stipulatedYear',
  documentNumber = 'documentNumber'
}

export enum ContractTypologyType {
  Tender = 'A',
  Administration = 'B',
  Transport = 'C',
  NoTaxRecords = 'noTaxRecords'
}

import { message, Table } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { getDateRange } from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { ActivityLogDto } from 'common/dto/generated/ActivityLogDto';
import { ActivityLogQueryDto } from 'common/dto/query/ActivityLogQueryDto';
import React, { useState } from 'react';
import { ActivityLogEntityType } from 'server/schema/activity-log/ActivityLogEntityType';
import { ActivityLogApi } from '../ActivityLogApi';
import { ActivityLogTableColumn } from './ActivityLogColumns';

interface IActivityLogTableProps {
  entity?: ActivityLogEntityType | null;
  entityId?: number | null;
}

export function ActivityLogTable(props: IActivityLogTableProps) {
  const defaultQuery = new ActivityLogQueryDto({
    pageNumber: 1,
    pageSize: 10,
    entity: props.entity,
    entityId: props.entityId
  });

  const [query, setQuery] = useState<ActivityLogQueryDto>(defaultQuery);

  const { response, loading, error } = useApiQuery(ActivityLogApi.list, {
    data: { query }
  });

  const logs = response?.data.items ?? [];

  if (error) {
    message.error('Si è verificato un errore durante il recupero dei log.');
  }

  return (
    <Table
      rowKey="id"
      loading={loading}
      columns={ActivityLogTableColumn}
      dataSource={logs}
      size="small"
      onChange={(pagination, filters, sorter) => {
        const { field, order } = sorter as SorterResult<ActivityLogDto>;

        // Date
        const createdDateRange = getDateRange(filters.createdAt);

        setQuery(
          new ActivityLogQueryDto({
            ...defaultQuery,
            pageNumber: pagination.current ?? query.pageNumber,
            pageSize: pagination.pageSize ?? query.pageSize,
            orderBy: field?.toString(),
            orderByDirection: order ? ColumnSortDirections[order] : undefined,
            dateFrom: createdDateRange.from,
            dateTo: createdDateRange.to
          })
        );
      }}
      pagination={{
        size: 'small',
        position: ['bottomRight'],
        showSizeChanger: true,
        total: response?.data.meta.total,
        pageSize: query.pageSize,
        current: query.pageNumber,
        pageSizeOptions: ['5', '10', '20', '30', '40']
      }}
    />
  );
}

import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { useFormikFieldValidation } from 'client/ui/form/hooks/validation/useFormikFieldValidation';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { OfficeDto } from 'common/dto/generated/OfficeDto';
import { useField } from 'formik';
import React, { useCallback, useState } from 'react';
import { OfficeApi } from '../OfficeApi';
import clx from 'classnames';
import { OfficeQueryDto } from 'common/dto/query/OfficeQueryDto';
import { Checkbox, Typography } from 'antd';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { useAuthoritiesOfficeCodes } from '../../user/hooks/useUserAuthorities';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
const { Text } = Typography;

export interface IOfficeFieldItemProps {
  name: string;
  isRogatory?: boolean | null;
  label?: string;
  labelWidth?: ILabelWidth;
  allowClear?: boolean;
  /**
   * Se impostato, mostra la checkbox per il filtro dei soli uffici a cui si è abilitati
   */
  showOnlyUserOfficesFilter?: boolean;
}

/**
 * Permette di selezionare un ufficio.
 */
export function OfficeFieldItem(props: IOfficeFieldItemProps) {
  const { name, isRogatory, label, labelWidth, showOnlyUserOfficesFilter } =
    props;
  const [field, _, helpers] = useField<number | undefined>(name);
  const validation = useFormikFieldValidation(name);
  const currentUser = useCurrentUser();
  const { officeCodes } = useAuthoritiesOfficeCodes(currentUser, {
    skip: !showOnlyUserOfficesFilter
  });
  const [onlyAuthorityOffices, setOnlyAuthorityOffices] = useState(true);

  const onSubsetOfficeCodeCheck = useCallback(
    (event: CheckboxChangeEvent) => {
      setOnlyAuthorityOffices(event.target.checked);
    },
    [setOnlyAuthorityOffices]
  );

  const { response } = useApiQuery(OfficeApi.find, {
    skip: field.value == null,
    data: { id: field.value! }
  });
  const office = response?.data;

  return (
    <FormFieldsContainer columns={2} removeTopTitleSpacing>
      <DataItem
        colSpan={2}
        className={clx({ editable: validation.editable })}
        ghost
        label={label ?? 'Ufficio'}
        labelWidth={labelWidth}
        itemProps={{ required: validation.editable && validation.required }}
        value={
          validation.editable ? (
            <AsyncSelectInput
              name={name}
              placeholder="Seleziona Ufficio"
              size="small"
              showSearch
              refreshOnSearch
              allowClear={props.allowClear}
              extraMeta={
                showOnlyUserOfficesFilter ? (
                  <Checkbox
                    onChange={onSubsetOfficeCodeCheck}
                    defaultChecked={onlyAuthorityOffices}
                  >
                    Mostra solo i miei uffici
                  </Checkbox>
                ) : undefined
              }
              query={{
                apiFn: OfficeApi.list,
                options: (_, search) => {
                  return {
                    data: {
                      query: new OfficeQueryDto({
                        pageNumber: 1,
                        pageSize: 20,
                        search,
                        isRogatory: isRogatory,
                        // Se il filtro è attivo e selezionato, filtriamo solo per i codici degli uffici dell'utente
                        subsetOfficeCodes:
                          showOnlyUserOfficesFilter && onlyAuthorityOffices
                            ? officeCodes
                            : undefined
                      })
                    }
                  };
                }
              }}
              // dropdownMatchSelectWidth={false}
              responseTransform={data => data.items}
              responseMeta={data => ({
                shown: data.items.length,
                total: data.meta.total
              })}
              optionTransform={(office: OfficeDto) => ({
                label: `${office.code} - ${office.description}`,
                value: office.id
              })}
              loadInitialValue={{
                apiFn: OfficeApi.find,
                options: formik => ({
                  skip: formik.values[name] == null,
                  data: {
                    id: formik.values[name]
                  }
                })
              }}
            />
          ) : (
            <Text ellipsis>
              {office?.code ?? '--'}
              {office?.description ? ' - ' + office?.description : ''}
            </Text>
          )
        }
      />
    </FormFieldsContainer>
  );
}

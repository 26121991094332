import { ITaxRecordType } from 'common/dto/generated/TaxRecordDto';
import { EntityMachine } from 'common/fsm/EntityMachine';
import { AccessType } from 'common/permissions/ResourceAccessElementsTypes';
import { TaxRecordState } from 'common/schema/tax-record/TaxRecordTypes';
import {
  taxRecordEvaluatedSchema,
  taxRecordToVerifySchema
} from './validation/TaxRecordStateChangeValidation';

export const TaxRecordMachine = EntityMachine.for<ITaxRecordType>()
  .withState(taxRecord => taxRecord.state ?? TaxRecordState.ToComplete)
  // Azioni
  .withAction('change.state.toVerify', {
    skip: taxRecord =>
      taxRecord.id == null || taxRecord.state === TaxRecordState.ToVerify,
    schema: taxRecordToVerifySchema
  })
  .withAction('edit', {
    allowed: (record, user) =>
      !!user?.getRole().hasPermission('tax-records.edit') &&
      !!record.contract?.userAccessTypes?.includes(AccessType.Write) &&
      !!record.state &&
      [TaxRecordState.ToComplete, TaxRecordState.Refused].includes(record.state)
  })
  // Transizioni di stato
  .withTransition(TaxRecordState.ToComplete, {
    to: TaxRecordState.ToVerify,
    skip: (taxRecord, user) =>
      !taxRecord.contract?.canUser(AccessType.Write) ||
      !user?.getRole().hasPermission('tax-records.state:toVerify'),
    schema: taxRecordToVerifySchema
  })
  .withTransition(TaxRecordState.ToVerify, {
    to: TaxRecordState.Accepted,
    skip: (taxRecord, user) =>
      !taxRecord.contract?.canUser(AccessType.Write) ||
      !user?.getRole().hasPermission('tax-records.state:evaluated'),
    schema: taxRecordEvaluatedSchema
  })
  .withTransition(TaxRecordState.ToVerify, {
    to: TaxRecordState.Refused,
    skip: (taxRecord, user) =>
      !taxRecord.contract?.canUser(AccessType.Write) ||
      !user?.getRole().hasPermission('tax-records.state:evaluated'),
    schema: taxRecordEvaluatedSchema
  })
  .withTransition(TaxRecordState.ToVerify, {
    to: TaxRecordState.NotSendable,
    skip: (taxRecord, user) =>
      !taxRecord.contract?.canUser(AccessType.Write) ||
      !user?.getRole().hasPermission('tax-records.state:evaluated'),
    schema: taxRecordEvaluatedSchema
  })
  .withTransition(TaxRecordState.Refused, {
    to: TaxRecordState.ToVerify,
    skip: (taxRecord, user) =>
      !taxRecord.contract?.canUser(AccessType.Write) ||
      !user?.getRole().hasPermission('tax-records.state:toVerify'),
    schema: taxRecordToVerifySchema
  });

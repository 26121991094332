import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from 'cli/generate/decorators/SchemaDecorators';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { UserOrderBy } from 'common/schema/user/UserTypes';

/**
 * Query per lista di utenti non paginata
 */
@GenerateDto()
class UserListQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
  @SchemaLabel("Ordinamento")
  orderBy?: UserOrderBy = UserOrderBy.id;
  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;

  @SchemaLabel('Nome Utente')
  name?: string | null;
  
  @SchemaLabel('Codice utente documentale')
  documentalUserCode?: string | null;

  @SchemaLabel('Flag per indicare se includere gli utenti disabilitati in elenco')
  includeDeleted?: boolean | null;



}

/**
 * Rappresentazione DTO della classe UserListQuery 
 * Hash: 53f859a0836f714eded5bf342782cec4
 */
@ValidationSchema(() => UserListQuerySchema)
export class UserListQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, enum: ['id', 'name'], description: 'Ordinamento' })
  orderBy?: UserOrderBy | undefined = UserOrderBy.id;
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;
  @ApiProperty({ required: false, type: String, description: 'Nome Utente' })
  name?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice utente documentale' })
  documentalUserCode?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Flag per indicare se includere gli utenti disabilitati in elenco' })
  includeDeleted?: boolean | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<UserListQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof UserListQueryDto ? values : plainToClass(UserListQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await UserListQuerySchema.validate(classToPlain(this), options);
    return new UserListQueryDto(validated);
  }
}

export const UserListQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    orderBy: yup.string().oneOfEnum(UserOrderBy).default(UserOrderBy.id).label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento'),
    name: yup.string().nullable().label('Nome Utente'),
    documentalUserCode: yup.string().nullable().label('Codice utente documentale'),
    includeDeleted: yup.boolean().nullable().label('Flag per indicare se includere gli utenti disabilitati in elenco')
  })
  .noUnknown()
  .meta({ schemaName: "UserListQuerySchema" })
  .required();

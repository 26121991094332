import { Tag } from 'antd';
import { ContractDto } from 'common/dto/generated/ContractDto';
import React from 'react';

export interface IMigratedTagProps {
  contract: ContractDto;
}

export function MigratedTag(props: IMigratedTagProps) {
  const { contract } = props;
  if (contract.migrationId == null) {
    return <></>;
  }

  return (
    <Tag color="volcano">
      {contract.migrationPregress ? 'ID Migrato (Pregresso)' : 'ID Migrato'}:{' '}
      {contract.migrationId}
    </Tag>
  );
}

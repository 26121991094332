import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { yup } from 'common/validation/initYup';
import { Formik, FormikConfig, FormikValues } from 'formik';
import * as React from 'react';
import { AnyObject } from 'yup/lib/types';
import { FormikAugmentedContext } from './FormikAugmentedContext';

export interface IFormikAdvancedProps<
  Values extends FormikValues = FormikValues
> extends Omit<FormikConfig<Values>, 'validationSchema' | 'validate'> {
  /** Schema di validazione */
  validationSchema: yup.ObjectSchema<any>;
  /** Contesto di validazione di Yup */
  validationContext?: AnyObject;
  /** Se true passa al context del validatore i values del formik (accessibili come $values) */
  valuesToContext?: boolean;
}

/**
 * Versione di `Formik` aumentata per gestire lo schema di Yup allineato
 * con i DTO e l'introspezione (vedi `useFormikValidation`)
 */
export function FormikAugmented<
  Values extends FormikValues = FormikValues,
  ExtraProps = {}
>(props: IFormikAdvancedProps<Values> & ExtraProps) {
  const { validationSchema, valuesToContext, ...otherProps } = props;

  return (
    <FormikAugmentedContext.Provider
      value={{
        context: props.validationContext ?? {},
        schema: props.validationSchema
      }}
    >
      <Formik<Values>
        {...otherProps}
        innerRef={props.innerRef}
        validate={schemaToValidator(
          props.validationSchema,
          { context: props.validationContext },
          valuesToContext
        )}
      />
    </FormikAugmentedContext.Provider>
  );
}

import React from 'react';
import { ArrowRightOutlined, ProfileOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { Section } from 'client/ui/section/Section';
import { TaxRecordDto } from 'common/dto/generated/TaxRecordDto';
import { TaxRecordStateTag } from 'client/routes/tax-record/TaxRecordStateTag';
import { ContractDto } from 'common/dto/generated/ContractDto';

export interface ContractTaxRecordsSectionProps {
  contract?: ContractDto;
}

export function ContractTaxRecordsSection(
  props: ContractTaxRecordsSectionProps
) {
  const taxRecords = props.contract?.taxRecords;

  // non mostriamo la section se non ci sono schede associate
  if (!taxRecords) return null;
  if (taxRecords && taxRecords.length < 1) return null;
  const taxRecordsColumns: ColumnsType<TaxRecordDto> = [
    {
      title: 'Anno',
      dataIndex: 'paymentYear',
      width: 90,
      sorter: true,
      // In questo modo previene di impostare la colonna a undefined.
      // Avendo un default nel dto, si crea un problema quando viene
      // impostato a undefined
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Stato',
      dataIndex: 'state',
      width: 120,
      render: (_, record) => <TaxRecordStateTag state={record.state} />
    },
    {
      title: 'Contraente',
      dataIndex: 'supplierId',
      render: (_, record) => record.supplier?.getName()
    },
    {
      title: 'Importo Corrisposto',
      dataIndex: 'paymentAmount',
      width: 160,
      align: 'right',
      render: (_, record) => (
        <CurrencyRender currency="EUR" value={record.paymentAmount} />
      )
    },

    {
      align: 'right',
      width: 60,
      render: (_, record) => (
        <Link to={`/tax/records/${record.id}`}>
          Vai <ArrowRightOutlined />
        </Link>
      )
    }
  ];

  return (
    <Section icon={<ProfileOutlined />} title="Schede di Anagrafe Tributaria">
      <Table
        columns={taxRecordsColumns}
        rowKey="id"
        dataSource={taxRecords}
        size="middle"
        pagination={{
          hideOnSinglePage: true,
          size: 'small',
          position: ['bottomRight'],
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </Section>
  );
}

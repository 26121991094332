import * as React from 'react';
import clx from 'classnames';
import { Typography } from 'antd';
const { Title, Text } = Typography;
import { FormFieldLabel, FormFieldLabelProps } from './FormFieldLabel';

export interface DataItemProps extends Omit<FormFieldLabelProps, 'children'> {
  value: React.ReactNode;
  /** Nasconde se è vuoto */
  hideIfEmpty?: boolean;
  /** Valore evidenziato in bold */
  strong?: boolean;
  /**
   * bigger: Mostra la Label e il campo di Input con un corpo maggiorato per metterli in evidenza
   */
  bigger?: boolean;
}

/**
 * Elememento statico per mostrare un dato con la sua etichetta
 * gli stili sono quelli del FormFieldLabel
 * */
export function DataItem(props: DataItemProps) {
  const { value, strong, bigger, hideIfEmpty, className, ...othersProp } =
    props;
  // Nascondo il campo se vuoto
  if (hideIfEmpty && value == null) return null;

  return (
    <FormFieldLabel {...othersProp} className={clx(className, { bigger })}>
      {typeof value === 'string' ? (
        <Text strong={props.strong}>{props.value}</Text>
      ) : (
        props.value
      )}
    </FormFieldLabel>
  );
}

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, message, Space } from 'antd';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { ContractState } from 'common/schema/contract/ContractTypes';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ContractMachine } from 'common/schema/contract/ContractMachine';
import {
  ContractSendToReviewDto,
  ContractSendToReviewSchema
} from 'common/dto/schema/ContractSendToReviewDto';

export interface ContractSendToReviewModalProps {
  contract?: ContractDto;
}

/**
 * Modal per la verifica del contratto  da parte di UR (Ufficiale Rogante)
 * permette di inviare rifiutare (rimandare a UD) o accettare il contratto
 * per poi procedere alla Registrazione su Prosa (fase successiva)
 *
 */
export function ContractSendToReviewModal(
  props: ContractSendToReviewModalProps
) {
  const { contract } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const currentUser = useCurrentUser();

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [toReview] = useApiMutation(ContractApi.actions.states.toReview, {
    invalidates: [ContractApi.find]
  });

  const transition = ContractMachine.to(
    contract,
    ContractState.ToReview,
    currentUser
  );

  if (!transition.allowed && transition.skipped) return null;
  if (!contract) return null;

  return (
    <>
      <Button type="primary" ghost onClick={showModal}>
        Richiedi Revisione <ArrowRightOutlined />
      </Button>

      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={new ContractSendToReviewDto()}
        validate={schemaToValidator(ContractSendToReviewSchema)}
        onSubmit={async values => {
          try {
            const input = await values.validate();

            await toReview({ data: { id: contract.id, data: input } });
            message.success('Operazione riuscita');
            handleCancel();
          } catch (e) {
            message.error('Si è verificato un errore. Riprovare.'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        <FormikForm nested inline editable={true} helpInTooltips>
          <ModalDialog
            title="Invia Contratto a proponente per revisione"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={
              <Space>
                <Button onClick={handleCancel}>Annulla</Button>
                <FormikSendButton ghost>
                  Invia a Proponente per Revisione <ArrowRightOutlined />
                </FormikSendButton>
              </Space>
            }
          >
            <FormFieldsContainer>
              <FormFieldItem
                component={TextAreaInput}
                label="Nota di Richiesta"
                labelWidth="width-140"
                name="content"
                placeholder="Testo della nota di richiesta inviata all'ufficiale rogante"
                rows={6}
              />
            </FormFieldsContainer>
          </ModalDialog>
        </FormikForm>
      </Formik>
    </>
  );
}

import { ArrowRightOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { FormikCancelModal } from 'client/ui/button/FormikCancelModal';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { useFormikContext } from 'formik';
import React from 'react';
import { ContractTransferFormFields } from './components/ContractTransferFormFields';
import {
  IPreviewTransfer,
  PreviewContractListToTransfer
} from './components/PreviewContractListToTransfer';

export interface IContractTransferModalProps {
  isModalVisible: boolean;
  handleCancel: () => void;
  hasContracts?: boolean | null;
  isSingleTransfer?: boolean;
  previewTransfer: IPreviewTransfer | null;
}

/**
 * Modale per il trasferimento di contratti.
 */
export function ContractTransferModal(props: IContractTransferModalProps) {
  const {
    isModalVisible,
    handleCancel,
    hasContracts,
    previewTransfer,
    isSingleTransfer
  } = props;
  const formik = useFormikContext();

  return (
    <ModalDialog
      title="Trasferimento Contratti"
      visible={isModalVisible}
      onCancel={handleCancel}
      afterClose={() => {
        formik.resetForm();
      }}
      destroyOnClose
      footer={
        <Space>
          <FormikCancelModal onClick={handleCancel}>Annulla</FormikCancelModal>
          <FormikSendButton>
            {!hasContracts ? `Trasferisci contratti` : `Conferma trasferimento`}
            <ArrowRightOutlined />
          </FormikSendButton>
        </Space>
      }
    >
      <ContractTransferFormFields isSingleTransfer={isSingleTransfer} />
      <PreviewContractListToTransfer previewTransfer={previewTransfer} />
    </ModalDialog>
  );
}

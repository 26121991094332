import * as React from 'react';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { ClientConfigApi } from './ClientConfigApi';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { useDispatch } from 'react-redux';
import { ClientConfigActions } from './ClientConfigModule';
import { Alert } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export interface IConfigLoaderProps {
  children: React.ReactNode;
}

/**
 * Provvede a caricare la configurazione dell'applicazione prima di procedere
 * con il rendering.
 */
export function ClientConfigLoader(props: IConfigLoaderProps) {
  const dispatch = useDispatch();
  const { response, loading, error } = useApiQuery(ClientConfigApi.getConfig, {
    data: {},
    onLoad: res => {
      dispatch(ClientConfigActions.loaded(res.data));
    }
  });

  if (loading) return <LoaderSpin full />;

  if (error)
    return (
      <div style={{ padding: '40px' }}>
        <Alert
          type="error"
          showIcon
          message="Impossibile caricare l'applicazione"
          description={getNetworkErrorMessage(
            error,
            'Si è verificato un errore durante il caricamento della configurazione.'
          )}
        />
      </div>
    );

  return <>{props.children}</>;
}

import * as React from 'react';
import clx from 'classnames';

import './PageFixedWidthWrapper.scss';

export interface PageFixedWidthWrapperProps {
  children: React.ReactNode;
  /**
   * Permette di eliminare il padding laterale al contenuto.
   */
  noPadding?: boolean;
}

/**
 * Content Layout con una larghezza massima (fissa, definita nei css)
 * e il contenuto centrato.
 */
export function PageFixedWidthWrapper(props: PageFixedWidthWrapperProps) {
  return (
    <div
      className={clx('page-fixed-width-wrapper', {
        'no-padding': props.noPadding
      })}
    >
      {props.children}
    </div>
  );
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";

@GenerateDto()
class TaxRecordSendToVerify {
    @SchemaLabel('Nota passaggio a Verifica')
    description?: string | null;
}

/**
 * Rappresentazione DTO della classe TaxRecordSendToVerify 
 * Hash: 49698802b2ed00bebbf6d57242a69206
 */
@ValidationSchema(() => TaxRecordSendToVerifySchema)
export class TaxRecordSendToVerifyDto {
  @ApiProperty({ required: false, type: String, description: 'Nota passaggio a Verifica' })
  description?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<TaxRecordSendToVerifyDto>) {
    if (values != null) {
      Object.assign(this, values instanceof TaxRecordSendToVerifyDto ? values : plainToClass(TaxRecordSendToVerifyDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await TaxRecordSendToVerifySchema.validate(classToPlain(this), options);
    return new TaxRecordSendToVerifyDto(validated);
  }
}

export const TaxRecordSendToVerifySchema = yup
  .object({
    description: yup.string().nullable().label('Nota passaggio a Verifica')
  })
  .noUnknown()
  .meta({ schemaName: "TaxRecordSendToVerifySchema" })
  .required();

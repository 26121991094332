import React from 'react';
import { Popconfirm, Button } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { DeleteOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ButtonType } from 'antd/lib/button';

export interface ButtonDeleteConfirmProps extends PopconfirmProps {
  loading?: boolean;
  size?: SizeType;
  iconOnlyButton?: boolean;
  buttonType?: ButtonType;
  buttonIcon?: React.ReactNode;
  buttonDanger?: boolean;
  disableConfirmButton?: boolean;
}

export function ButtonDeleteConfirm(props: ButtonDeleteConfirmProps) {
  const {
    placement,
    okText,
    cancelText,
    loading,
    size,
    buttonType,
    buttonIcon,
    iconOnlyButton,
    buttonDanger,
    ...otherProps
  } = props;

  return (
    <Popconfirm
      placement={placement ?? 'topRight'}
      okText={okText ?? 'Elimina'}
      cancelText={cancelText ?? 'Annulla'}
      {...otherProps}
      okButtonProps={{
        danger: true,
        type: 'primary',
        disabled: props.disableConfirmButton
      }}
    >
      <Button
        disabled={props.disabled}
        type={buttonType ?? 'primary'}
        icon={buttonIcon ?? <DeleteOutlined />}
        size={size ?? 'small'}
        loading={loading}
        danger={buttonDanger}
      >
        {iconOnlyButton ? '' : okText ?? 'Elimina'}
      </Button>
    </Popconfirm>
  );
}

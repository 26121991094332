import * as React from 'react';
import { Button, Table } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { useEffect, useState } from 'react';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { SorterResult } from 'antd/lib/table/interface';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { ProfileOutlined } from '@ant-design/icons';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { Section } from 'client/ui/section/Section';
import { TaxExportDto } from 'common/dto/generated/TaxExportDto';
import { TaxExportColumns } from './table/TaxExportColumns';
import { TaxExportModalButton } from '../modal/TaxExportModalButton';
import { TaxExportQueryDto } from 'common/dto/query/TaxExportQueryDto';
import { TaxExportApi } from 'client/components/schema/tax-export/TaxExportApi';
import { TaxExportOrderBy } from 'common/schema/tax-export/TaxExportOrderBy';

interface Params {
  filter: string;
}

export interface TaxExportListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco delle esportazioni delle schede di anagrafe tributaria
 */
export function TaxExportListPage(props: TaxExportListPageProps) {
  const [query, setQuery] = useState<TaxExportQueryDto>(
    new TaxExportQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 10
      })
    )
  );

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Export Schede Anagrafe Tributaria',
    menuKey: 'exports'
  });

  const { response, loading, error } = useApiQuery(TaxExportApi.list, {
    data: { query }
  });

  const records = response?.data.items;

  const columns = getPaginationUrlColumns<TaxExportDto, TaxExportQueryDto>(
    TaxExportColumns,
    query
  );

  // useEffect(() => {
  //   setOptions(mapContractsFilterToOptions(props.match.params.filter));
  // }, [props.match, options.filter]);

  useEffect(() => {
    setQuery(
      new TaxExportQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  }, [props.location.search]);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei contratti"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeadingRogante
        fixedWidth
        title={
          <PageHeadingTitle>
            Elenco Esportazioni per Anagrafe Tributaria
          </PageHeadingTitle>
        }
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={<TaxExportModalButton />}
      />
      <PageContent fixedWidth>
        <Section
          icon={<ProfileOutlined />}
          title="Elenco Esportazioni per Anagrafe Tributaria"
        >
          <Table<TaxExportDto>
            loading={tableLoaderSpin(loading || !records)}
            rowKey="id"
            columns={columns}
            dataSource={records}
            size="middle"
            onChange={(pagination, filters, sorter) => {
              const { field, order } = sorter as SorterResult<TaxExportDto>;

              const taxRecordQuery = new TaxExportQueryDto({
                ...query,
                pageNumber: pagination.current || query.pageNumber,
                pageSize: pagination.pageSize || query.pageSize,
                orderBy: order
                  ? (field?.toString() as TaxExportOrderBy)
                  : TaxExportOrderBy.id,
                orderByDirection: order
                  ? ColumnSortDirections[order]
                  : ColumnSortDirections.descend
              });

              setPaginationUrlQuery(props, taxRecordQuery);
            }}
            pagination={{
              hideOnSinglePage: true,
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: true,
              total: response?.data.meta.total,
              pageSize: query.pageSize,
              current: query.pageNumber,
              pageSizeOptions: ['5', '10', '20', '30', '40']
            }}
          />
        </Section>
      </PageContent>
    </CorePageLayout>
  );
}

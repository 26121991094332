import * as React from 'react';
import { SpinProps } from 'antd/lib/spin/index';
import { Loading3QuartersOutlined } from '@ant-design/icons';

export const tableLoaderSpin = (loading?: boolean) => {
  if (!loading) {
    return undefined;
  }
  const spin: SpinProps = {
    size: 'large',
    indicator: <Loading3QuartersOutlined spin />,
    spinning: loading
  };
  return spin;
};

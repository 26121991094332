import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { OfficeOrderBy } from 'common/schema/office/OfficeTypes';

@GenerateDto()
class OfficeQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
  @SchemaLabel("Ordinamento")
  orderBy?: OfficeOrderBy = OfficeOrderBy.id;
  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
  @SchemaLabel("Ordinamento")
  isRogatory?: boolean | null;
  /** Permette la ricerca per nome o codice */
  @SchemaLabel("Stringa di ricerca")
  search?: string | null;
  /** 
   * Filtra soltanto gli uffici con il codice richiesto.
   * Utilizzato per mostrare solo gli uffici di appartenenza di un utente come filtro aggiuntivo.
   * (gli uffici li abbiamo FE ma non BE quindi vengono ritornati in query)
   */
  @SchemaLabel("Uffici di appartenenza")
  subsetOfficeCodes?: string[] | null;
}

/**
 * Rappresentazione DTO della classe OfficeQuery 
 * Hash: 89a254c49c3b03dc3c587db2506ae54a
 */
@ValidationSchema(() => OfficeQuerySchema)
export class OfficeQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, description: 'Ordinamento' })
  orderBy?: OfficeOrderBy | undefined = OfficeOrderBy.id;
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;
  @ApiProperty({ required: false, type: Boolean, description: 'Ordinamento' })
  isRogatory?: boolean | null | undefined;
  /** Permette la ricerca per nome o codice */
  @ApiProperty({ required: false, type: String, description: 'Stringa di ricerca' })
  search?: string | null | undefined;
  /**
   * Filtra soltanto gli uffici con il codice richiesto.
   * Utilizzato per mostrare solo gli uffici di appartenenza di un utente come filtro aggiuntivo.
   * (gli uffici li abbiamo FE ma non BE quindi vengono ritornati in query)
   */
  @ApiProperty({ required: false, type: [String], description: 'Uffici di appartenenza' })
  subsetOfficeCodes?: string[] | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<OfficeQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof OfficeQueryDto ? values : plainToClass(OfficeQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await OfficeQuerySchema.validate(classToPlain(this), options);
    return new OfficeQueryDto(validated);
  }
}

export const OfficeQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    orderBy: yup.mixed().default(OfficeOrderBy.id).label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento'),
    isRogatory: yup.boolean().nullable().label('Ordinamento'),
    search: yup.string().nullable().label('Stringa di ricerca'),
    subsetOfficeCodes: yup.array(yup.string().required()).nullable().label('Uffici di appartenenza')
  })
  .noUnknown()
  .meta({ schemaName: "OfficeQuerySchema" })
  .required();

import React from 'react';
import clx from 'classnames';

import { Modal, ModalProps } from 'antd';

export interface ModalDialogProps extends ModalProps {
  children: React.ReactNode;
  /**
   * Larghezza della modal
   * 1000px (default) | 760px | 520px
   * può essere sovrascritto con 'width' di ModalProps
   */
  size?: 'large' | 'medium' | 'small';
}

/**
 * Standard Modal Stilizzata
 */
export function ModalDialog(props: ModalDialogProps) {
  const { children, className, width, size, ...modalProps } = props;
  // larghezza della Modal
  const modalWidth = width
    ? width
    : size === 'small'
    ? 600
    : size === 'medium'
    ? 800
    : 1000;

  return (
    <Modal
      className={clx('modal-dialog', className)}
      width={modalWidth}
      {...modalProps}
    >
      {children}
    </Modal>
  );
}

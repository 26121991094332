import { Button, Col, Collapse, message, Row, Space } from 'antd';
import { OfficeFieldItem } from 'client/components/schema/office/input/OfficeFieldItem';
import { FormikResetButton } from 'client/ui/form/button/FormikResetButton';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import {
  FormFieldItem,
  IFormFieldItemProps
} from 'client/ui/form/field/FormFieldItem';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import {
  ContractQueryDto,
  ContractQuerySchema
} from 'common/dto/query/ContractQueryDto';
import { FormikHelpers } from 'formik';
import React from 'react';

const labelWidth: ILabelWidth = 'width-140';

export interface IContractSearchFormProps {
  initialValues: ContractQueryDto;
  onFilter: (query: ContractQueryDto) => void;
  onReset: () => void;
}

export function ContractSearchForm(props: IContractSearchFormProps) {
  const { initialValues } = props;

  return (
    <FormikAugmented<ContractQueryDto>
      enableReinitialize
      validationSchema={ContractQuerySchema}
      initialValues={initialValues}
      onSubmit={async (rawValues: ContractQueryDto) => {
        try {
          const input = await rawValues.validate();
          props.onFilter(input);
        } catch (e) {
          console.error(`[ContractSearchForm] Si è verificato un errore durante l'applicazione dei filtri`, e); // prettier-ignore
          message.error(`Si è verificato un errore durante l'applicazione dei filtri`); // prettier-ignore
        }
      }}
    >
      <FormikForm editable={true} helpInTooltips>
        <Row gutter={16} align="bottom">
          <Col flex={'1 1 0'}>
            <FormFieldsContainer columns={2}>
              <FormFieldItem
                labelWidth={labelWidth}
                label="Ragione Sociale"
                name="supplierCompanyName"
                component={TextInput}
                allowClear
              />
              <FormFieldItem
                labelWidth={labelWidth}
                label="Nome Contraente"
                name="supplierFullName"
                component={TextInput}
                allowClear
              />
              <FormFieldItem
                labelWidth={labelWidth}
                label="CF/P.IVA Contraente"
                name="supplierFiscalCode"
                component={TextInput}
                allowClear
              />

              <OfficeFieldItem
                labelWidth={labelWidth}
                name="proponentOfficeId"
                isRogatory={null}
                label="Ufficio Proponente"
                allowClear
              />
            </FormFieldsContainer>
          </Col>
          <Col flex={'0 0 160px'} style={{ paddingBottom: '6px' }}>
            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
              <FormikResetButton type="default" onReset={props.onReset}>
                Reset
              </FormikResetButton>
              <FormikSendButton type="primary">Filtra</FormikSendButton>
            </Space>
          </Col>
        </Row>
      </FormikForm>
    </FormikAugmented>
  );
}

import React from 'react';
import { Formik } from 'formik';
import { Card, Button } from 'antd';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useDispatch } from 'react-redux';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import {
  ICreateAccessTokenDto,
  createAccessTokenValidator
} from 'common/dto/AuthDto';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { yup } from 'common/validation/initYup';
import { Asserts } from 'yup';
import { LdapAuthApi } from './LdapAuthApi';
import { LdapAuthDto, LdapAuthSchema } from 'common/dto/query/LdapAuthDto';
import { AuthActions } from '../../AuthModule';

interface Props {}

/**
 * Form di login all'applicativo, tramite le API di creazione AccessToken JWT
 * con credenziali LDAP
 */
export function LdapLoginForm(props: Props) {
  const [login, { loading }] = useApiMutation(
    LdapAuthApi.createAccessToken,
    {}
  );
  const dispatch = useDispatch();

  return (
    <Formik<LdapAuthDto>
      initialValues={new LdapAuthDto()}
      validate={schemaToValidator(LdapAuthSchema)}
      onSubmit={async (values, helpers) => {
        const result = await login({ data: values });
        dispatch(AuthActions.loginSucceed('jwt', result.data.accessToken));
      }}
    >
      <LoaderSpin spinning={loading} transparent>
        <Card bordered={false} style={{ minWidth: '330px' }}>
          <FormikForm layout="vertical">
            <TextInputItem
              name="username"
              autoComplete="username"
              prefix={<UserOutlined style={{ color: '#d9d9d9' }} />}
              placeholder="Username"
            />
            <TextInputItem
              name="password"
              type="password"
              autoComplete="current-password"
              prefix={<LockOutlined style={{ color: '#d9d9d9' }} />}
              placeholder="Password"
            />
            <Button htmlType="submit" type="primary" style={{ width: '100%' }}>
              Accedi
            </Button>
          </FormikForm>
        </Card>
      </LoaderSpin>
    </Formik>
  );
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from "client/ui/table/TableColumnSort";
import { ContractOrderBy, ContractState } from 'common/schema/contract/ContractTypes';

@GenerateDto()
class ContractQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
  @SchemaLabel("Ordinamento")
  orderBy?: ContractOrderBy = ContractOrderBy.id;
  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;

  @SchemaLabel('ID')
  id?: number | null;
  @SchemaLabel('Stato Contratto')
  state?: ContractState | null;
  @SchemaLabel('Oggetto')
  subject?: string | null;
  @SchemaLabel('N. Registro')
  documentNumber?: number | null;
  @SchemaLabel("Data inizio")
  stipulationDateFrom?: Date | null;
  @SchemaLabel("Data fine")
  stipulationDateTo?: Date | null;

  // Dati contraenti
  @SchemaLabel('Ragione Sociale Contraente')
  supplierCompanyName?: string | null;

  @SchemaLabel('C.F. Contraente')
  supplierFiscalCode?: string | null;

  @SchemaLabel('Ufficio Proponente')
  proponentOfficeId?: number | null;

  @SchemaLabel('Nome Contraente')
  supplierFullName?: string | null;


}

/**
 * Rappresentazione DTO della classe ContractQuery 
 * Hash: 85eb628e3c86a39e2119f8953ff1c61f
 */
@ValidationSchema(() => ContractQuerySchema)
export class ContractQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, enum: ['id', 'stipulatedAt', 'createdAt', 'validityStartAt', 'stipulatedYear', 'documentNumber'], description: 'Ordinamento' })
  orderBy?: ContractOrderBy | undefined = ContractOrderBy.id;
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;
  @ApiProperty({ required: false, type: Number, description: 'ID' })
  id?: number | null | undefined;
  @ApiProperty({ required: false, enum: ['InProgress', 'ToVerify', 'ToReview', 'InRegistration', 'Registered', 'Paid', 'Closed', 'Cancelled'], description: 'Stato Contratto' })
  state?: ContractState | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Oggetto' })
  subject?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'N. Registro' })
  documentNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data inizio' })
  stipulationDateFrom?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data fine' })
  stipulationDateTo?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ragione Sociale Contraente' })
  supplierCompanyName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'C.F. Contraente' })
  supplierFiscalCode?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Ufficio Proponente' })
  proponentOfficeId?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome Contraente' })
  supplierFullName?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractQueryDto ? values : plainToClass(ContractQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ContractQuerySchema.validate(classToPlain(this), options);
    return new ContractQueryDto(validated);
  }
}

export const ContractQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    orderBy: yup.string().oneOfEnum(ContractOrderBy).default(ContractOrderBy.id).label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento'),
    id: yup.number().nullable().label('ID'),
    state: yup.string().oneOfEnum(ContractState).nullable().label('Stato Contratto'),
    subject: yup.string().nullable().label('Oggetto'),
    documentNumber: yup.number().nullable().label('N. Registro'),
    stipulationDateFrom: yup.date().nullable().label('Data inizio'),
    stipulationDateTo: yup.date().nullable().label('Data fine'),
    supplierCompanyName: yup.string().nullable().label('Ragione Sociale Contraente'),
    supplierFiscalCode: yup.string().nullable().label('C.F. Contraente'),
    proponentOfficeId: yup.number().nullable().label('Ufficio Proponente'),
    supplierFullName: yup.string().nullable().label('Nome Contraente')
  })
  .noUnknown()
  .meta({ schemaName: "ContractQuerySchema" })
  .required();

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { UserDto } from './UserDto';
import { ConsumerDto } from './ConsumerDto';

/**
 * Rappresentazione DTO della classe TaxExport definita in: src/server/schema/tax/tax-export/TaxExport.entity.ts
 * Hash: 389d649e56f241f1ce39e79a07186131
 */
@ValidationSchema(() => TaxExportSchema)
export class TaxExportDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number, description: 'Contatore' })
  counter?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Anno' })
  year?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome File Generato' })
  fileName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Path del File' })
  filePath?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Autore' })
  authorId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  author?: UserDto | null;
  @ApiProperty({ required: false, type: Number, description: 'Ufficio Rogante' })
  rogatoryOfficeId?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Soggetto Obbligato' })
  consumerId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => ConsumerDto })
  @Type(() => ConsumerDto)
  consumer?: ConsumerDto | null;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Creazione' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Aggiornamento' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Eliminazione' })
  deletedAt?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<TaxExportDto>) {
    if (values != null) {
      Object.assign(this, values instanceof TaxExportDto ? values : plainToClass(TaxExportDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await TaxExportSchema.validate(classToPlain(this), options);
    return new TaxExportDto(validated);
  }
}

/** Interfaccia simmetrica al DTO TaxExportDto */
export type ITaxExportType = IDto<TaxExportDto>;

/**
 * DTO Paginato della classe TaxExport
 */
export class PaginatedTaxExportDto {
  @ApiProperty({ type: [TaxExportDto] })
  @Type(() => TaxExportDto)
  items!: TaxExportDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const TaxExportSchema = yup
  .object({
    id: yup.number(),
    counter: yup.number().nullable().label('Contatore'),
    year: yup.number().nullable().label('Anno'),
    fileName: yup.string().nullable().label('Nome File Generato'),
    filePath: yup.string().nullable().label('Path del File'),
    rogatoryOfficeId: yup.number().nullable().label('Ufficio Rogante'),
    consumerId: yup.number().nullable().label('Soggetto Obbligato')
  })
  .noUnknown()
  .meta({ schemaName: "TaxExportSchema" })
  .required();

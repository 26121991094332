import { SelectOption } from 'client/ui/form/input/SelectInput';
import { CountryCodes } from './CountryCodes';

export const CountryCodeOptions: SelectOption[] = CountryCodes.map(
  countryCode => {
    return {
      value: countryCode.code,
      label: `${countryCode.code} - ${countryCode.name}`
    };
  }
);

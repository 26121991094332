import { ColumnType } from 'antd/lib/table';

/**
 * Forza la tipizzazione per un oggetto dove ogni colonna è un _valore_
 */
export function createTypedColumns<T, K extends string>(
  dto: Constructor<T>,
  columns: { [key in K]: ColumnType<T> }
) {
  return columns;
}

import * as React from 'react';
import { BookOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { Section } from 'client/ui/section/Section';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { DataItem } from 'client/ui/form/field/DataItem';
import { ISODate } from 'client/ui/display/date/ISODate';
import { ContractMachine } from 'common/schema/contract/ContractMachine';
import { useCurrentUser } from 'client/components/auth/AuthModule';

export interface ContractRegistrationSectionProps {
  contract?: ContractDto;
}

export function ContractRegistrationSection(
  props: ContractRegistrationSectionProps
) {
  const { contract } = props;

  const currentUser = useCurrentUser();
  const canReadRegister = ContractMachine.can(
    contract,
    'read-register-number',
    currentUser
  );

  if (!canReadRegister.allowed) return null;

  return (
    <Section
      icon={<BookOutlined />}
      title="Dati Registro Cronologico Contratti"
      hasPadding
    >
      {/** Dati registrazione Prosa */}
      <FormFieldsContainer columns={3}>
        <DataItem inline bigger label="Anno" value={contract?.documentYear} />
        <DataItem
          inline
          bigger
          label="Registro Cronologico"
          value={contract?.documentNumber}
        />
        <DataItem
          inline
          bigger
          label="Data"
          value={<ISODate date={contract?.registeredAt} format="DD/MM/YYYY" />}
        />
      </FormFieldsContainer>

      {contract?.terminationNotes && (
        <FormFieldsContainer title="Nota di chiusura contratto">
          <DataItem label={null} value={contract?.terminationNotes} />
        </FormFieldsContainer>
      )}
    </Section>
  );
}

import { Tag, Typography } from 'antd';
const { Paragraph } = Typography;
import { ColumnProps } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { SupplierDto } from 'common/dto/generated/SupplierDto';
import React from 'react';

export const SupplierColumns: ColumnProps<SupplierDto>[] = [
  {
    title: 'Cod. Fisc / Partita Iva ',
    dataIndex: 'fiscalNumber',
    width: 200,
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => record.getFiscalNumber()
  },
  // {
  //   title: 'Partita IVA',
  //   dataIndex: 'fiscalIdCode',
  //   width: 140,
  //   render: (_, record) => record.getFiscalId()
  // },
  {
    title: 'Denominazione',
    dataIndex: 'fullname',
    sorter: true,
    // In questo modo previene di impostare la colonna a undefined.
    // Avendo un default nel dto, si crea un problema quando viene
    // impostato a undefined
    sortDirections: ['ascend', 'descend', 'ascend'],
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => record.getName()
  }
];

import { EuroOutlined, ProfileOutlined } from '@ant-design/icons';
import { useProvinceInput } from 'client/components/province/useProvinceInput';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { CurrencyInput } from 'client/ui/form/input/CurrencyInput';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Section } from 'client/ui/section/Section';
import { getProvinceStructure } from 'common/data/provinces/getProvinceStructure';
import { ConsumerDto } from 'common/dto/generated/ConsumerDto';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { useFormikContext } from 'formik';
import * as React from 'react';

export interface IConsumerMainSectionProps {}

export function ConsumerMainSection(props: IConsumerMainSectionProps) {
  const formik = useFormikContext<ConsumerDto>();
  const { provinceOptions, cityOptions } = useProvinceInput({
    provinceField: 'province',
    cityField: 'city'
  });

  return (
    <Section icon={<ProfileOutlined />} title="Dati Anagrafici" hasPadding>
      {/* Dati Economico Finanziari */}
      <FormFieldsContainer removeTopTitleSpacing columns={4}>
        <FormFieldItem
          colSpan={3}
          component={TextInput}
          name="name"
          label="Denominazione"
          placeholder="Denominazione"
        />
        <FormFieldItem
          colSpan={1}
          component={TextInput}
          name="fiscalNumber"
          label="Codice Fiscale"
          placeholder="CF"
        />
        <FormFieldItem
          colSpan={3}
          component={TextInput}
          label="Indirizzo"
          name="address"
        />
        <FormFieldItem
          colSpan={1}
          component={TextInput}
          label="CAP"
          name="zip"
        />
        <FormFieldItem
          colSpan={2}
          component={SelectInput}
          label="Provincia"
          name="province"
          showSearch
          options={provinceOptions}
          autoComplete="off"
        />
        <FormFieldItem
          colSpan={2}
          inline
          component={SelectInput}
          label="Città"
          name="city"
          showSearch
          disabled={cityOptions == null}
          options={cityOptions}
        />
      </FormFieldsContainer>
    </Section>
  );
}

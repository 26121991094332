import { Tag, Tooltip } from 'antd';
import { ContractLogic } from 'common/schema/contract/ContractLogic';
import { ContractState } from 'common/schema/contract/ContractTypes';
import React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface ContractStateTagProps {
  state?: ContractState | null | undefined;
}

export function ContractStateTag(props: ContractStateTagProps) {
  if (!props.state) return null;
  switch (props.state) {
    case ContractState.InProgress:
      return (
        <Tooltip title="Il contratto è in bozza, non è stato completato l’inserimento dei dati">
          <Tag>{ContractLogic.getStateLabel(props.state)}</Tag>
        </Tooltip>
      );
    case ContractState.ToVerify:
      return (
        <Tooltip title="Il contratto è stato compilato e inviato in verifica all’Ufficiale Rogante">
          <Tag color="orange">{ContractLogic.getStateLabel(props.state)}</Tag>
        </Tooltip>
      );
    case ContractState.ToReview:
      return (
        <Tooltip title="L’Ufficiale Rogante ha richiesto una revisione del contratto a causa di dati incompleti o incorretti">
          <Tag color="magenta">{ContractLogic.getStateLabel(props.state)}</Tag>
        </Tooltip>
      );
    case ContractState.Registered:
      return (
        <Tooltip title="Il contratto è stato inserito nel Registro Cronologico ed è stata inviata la comunicazione all'Ufficio Proponente (UD)">
          <Tag color="geekblue">{ContractLogic.getStateLabel(props.state)}</Tag>
        </Tooltip>
      );
    case ContractState.InRegistration:
      return (
        <Tooltip title="L’Ufficiale Rogante ha inserito il contratto nel Registro Cronologico. In attesa della comunicazione all'Ufficio Proponente (UD)">
          <Tag color="blue">{ContractLogic.getStateLabel(props.state)}</Tag>
        </Tooltip>
      );
    case ContractState.Paid:
      return (
        <Tooltip title="Il contratto si considera concluso e non saranno più gestite ulteriori schede anagrafe tributaria">
          <Tag color="purple">{ContractLogic.getStateLabel(props.state)}</Tag>
        </Tooltip>
      );
    case ContractState.Closed:
      return (
        <Tooltip title="Il contratto è stato chiuso">
          <Tag color="red">{ContractLogic.getStateLabel(props.state)}</Tag>
        </Tooltip>
      );
    case ContractState.Cancelled:
      return (
        <Tooltip title="Il contratto è stato annullato">
          <Tag color="red">{ContractLogic.getStateLabel(props.state)}</Tag>
        </Tooltip>
      );
    default:
      assertNever(props.state);
  }
}

export function contractStateOptions() {
  return Object.keys(ContractState).map(state => ({
    label: ContractLogic.getStateLabel(state as ContractState),
    value: state
  }));
}

import { ArrowRightOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { SectionButton } from 'client/ui/section/SectionButton';
import React from 'react';

export interface IContractParentLinkButtonProps {
  parentId: number | null | undefined;
  parentPresence: boolean | null | undefined;
  parentDocumentNumeber: number | null | undefined;
}

export function ContractParentLinkButton(
  props: IContractParentLinkButtonProps
) {
  const { parentId, parentPresence, parentDocumentNumeber } = props;

  if (parentId == null && parentDocumentNumeber == null) return null;

  return parentPresence ? (
    <SectionButton key="add" size="small" to={`/contracts/${parentId}`}>
      Vai al Contratto Esteso:{' '}
      <strong style={{ marginLeft: '4px' }}>{parentDocumentNumeber}</strong>{' '}
      <ArrowRightOutlined />
    </SectionButton>
  ) : (
    <Tooltip title="Il contratto di riferimento non è presente sul sistema">
      <Tag>
        Contratto Esteso: <strong>{parentDocumentNumeber}</strong>
      </Tag>
    </Tooltip>
  );
}

import { createStore } from 'redux';
import { RootReducer } from './RootReducer';

export function configureStore() {
  return createStore(
    RootReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__!()
  );
}

export const store = configureStore();

import * as React from 'react';
import { Button, Table } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { useEffect, useState } from 'react';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { SorterResult } from 'antd/lib/table/interface';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { IdcardOutlined, PlusOutlined } from '@ant-design/icons';
import { LinkButton } from 'client/ui/link/LinkButton';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { Section } from 'client/ui/section/Section';
import { SupplierDto } from 'common/dto/generated/SupplierDto';
import { SupplierQueryDto } from 'common/dto/query/SupplierQueryDto';
import { SupplierApi } from 'client/components/schema/supplier/SupplierApi';
import { SupplierOrderBy } from 'common/schema/supplier/SupplierTypes';
import { SupplierColumns } from './table/SupplierColumns';

interface Params {
  filter: string;
}

export interface SupplierListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco dei Contraenti
 */
export function SupplierListPage(props: SupplierListPageProps) {
  const [query, setQuery] = useState<SupplierQueryDto>(
    new SupplierQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 10
      })
    )
  );

  useBreadcrumbItem({ path: '/', title: `Home` });
  // useBreadcrumbItem({ path: '/suppliers', title: `Contraenti` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Contraenti',
    menuKey: 'suppliers'
  });

  const { response, loading, error } = useApiQuery(SupplierApi.list, {
    data: { query }
  });

  const suppliers = response?.data.items;

  const columns = getPaginationUrlColumns<SupplierDto, SupplierQueryDto>(
    SupplierColumns,
    query
  );

  useEffect(() => {
    setQuery(
      new SupplierQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  }, [props.location.search]);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei contratti"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeadingRogante
        fixedWidth
        title={<PageHeadingTitle>Elenco Contraenti</PageHeadingTitle>}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          <LinkButton
            to="/suppliers/create"
            type="primary"
            icon={<PlusOutlined />}
          >
            Nuovo Contraente
          </LinkButton>
        }
      />
      <PageContent fixedWidth>
        <Section icon={<IdcardOutlined />} title="Elenco Contraenti">
          <Table<SupplierDto>
            loading={tableLoaderSpin(loading || !suppliers)}
            rowKey="id"
            onRow={record => ({
              style: {
                cursor: 'pointer'
              },
              onClick: () => {
                props.history.push(`/suppliers/${record.id}`);
              }
            })}
            columns={columns}
            dataSource={suppliers}
            size="middle"
            onChange={(pagination, filters, sorter) => {
              const { field, order } = sorter as SorterResult<SupplierDto>;

              const fullname = filters.fullname
                ? filters.fullname[0].toString()
                : null;
              const fiscalNumber = filters.fiscalNumber
                ? filters.fiscalNumber[0].toString()
                : null;

              const contractQuery = new SupplierQueryDto({
                ...query,
                pageNumber: pagination.current || query.pageNumber,
                pageSize: pagination.pageSize || query.pageSize,
                orderBy: order
                  ? (field?.toString() as SupplierOrderBy)
                  : SupplierOrderBy.id,
                orderByDirection: order
                  ? ColumnSortDirections[order]
                  : ColumnSortDirections.descend,
                fullname,
                fiscalNumber
              });

              setPaginationUrlQuery(props, contractQuery);
            }}
            pagination={{
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: true,
              total: response?.data.meta.total,
              pageSize: query.pageSize,
              current: query.pageNumber,
              pageSizeOptions: ['5', '10', '20', '30', '40']
            }}
          />
        </Section>
      </PageContent>
    </CorePageLayout>
  );
}

import { UserDto } from 'common/dto/generated/UserDto';
import { RoleName, Roles } from 'common/permissions/Roles';
import { assertNever } from 'server/utils/typings/assertNever';
import { TaxRecordState } from './TaxRecordTypes';

export class TaxRecordLogic {
  /**
   * Dato lo stato, ritorna la label corrispondente.
   * (Permette di utilizzare la label nei messaggi d'errore
   * o nelle options di una select)
   */
  static getStateLabel(state: TaxRecordState) {
    switch (state) {
      case TaxRecordState.ToComplete:
        return 'Da Completare';
      case TaxRecordState.ToVerify:
        return 'In Verifica';
      case TaxRecordState.Refused:
        return 'Rifiutata';
      case TaxRecordState.Accepted:
        return 'Accettata';
      case TaxRecordState.NotSendable:
        return 'Non Inviabile';
      default:
        assertNever(state);
    }
  }
}

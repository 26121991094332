import * as React from 'react';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { AuthApi } from '../AuthApi';
import { useAuth, AuthActions, useCurrentUser } from '../AuthModule';
import { useDispatch } from 'react-redux';
import { UserLogic } from 'common/schema/user/UserLogic';
import { LogoutOutlined } from '@ant-design/icons';
import { logger } from 'client/core/logger/logger';
import { resolveApiPath } from 'client/core/appConfig';
import { Menu } from 'antd';

export interface ILogoutButtonProps {}

/**
 * Gestisce il logout dell'utente corrente, sia dalla sessione remota che dai
 * dati locali.
 */
export function LogoutButton(props: ILogoutButtonProps) {
  const auth = useAuth();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const [logout] = useApiMutation(AuthApi.logout, {});

  if (!auth.logged) return null;
  if (!UserLogic.canLogout(user)) return null;

  const handleLogout = async () => {
    switch (user?.originalStrategy) {
      // Tranne nel caso di IAM OpenAM, dobbiamo effettuare il reload della
      // pagina impostando `isLogged` a false.
      default:
        await logout({});
        dispatch(AuthActions.logout());
        break;
    }
  };

  return (
    <Menu.Item
      {...props}
      key="logout"
      icon={<LogoutOutlined />}
      style={{ float: 'right' }}
    >
      <a onClick={handleLogout}>Logout</a>
    </Menu.Item>
  );
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";

@GenerateDto()
class TaxExportCreateInput {
  @SchemaLabel("Anno")
  year!: number;
  @SchemaLabel("Soggetto Obbligato")
  consumerId!: number;
}

/**
 * Rappresentazione DTO della classe TaxExportCreateInput 
 * Hash: 8ad891974c42adbab043386ff2cb6a2c
 */
@ValidationSchema(() => TaxExportCreateInputSchema)
export class TaxExportCreateInputDto {
  @ApiProperty({ type: Number, description: 'Anno' })
  year!: number;
  @ApiProperty({ type: Number, description: 'Soggetto Obbligato' })
  consumerId!: number;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<TaxExportCreateInputDto>) {
    if (values != null) {
      Object.assign(this, values instanceof TaxExportCreateInputDto ? values : plainToClass(TaxExportCreateInputDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await TaxExportCreateInputSchema.validate(classToPlain(this), options);
    return new TaxExportCreateInputDto(validated);
  }
}

export const TaxExportCreateInputSchema = yup
  .object({
    year: yup.number().required().label('Anno'),
    consumerId: yup.number().required().label('Soggetto Obbligato')
  })
  .noUnknown()
  .meta({ schemaName: "TaxExportCreateInputSchema" })
  .required();

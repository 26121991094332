import { IContractType } from 'common/dto/generated/ContractDto';
import { UserDto } from 'common/dto/generated/UserDto';
import { RoleName, Roles } from 'common/permissions/Roles';
import Decimal from 'decimal.js-light';
import { assertNever } from 'server/utils/typings/assertNever';
import { ContractState } from './ContractTypes';

export class ContractLogic {
  /**
   * Dato un ruolo, ritorna l'array di stati del contratto
   * che possono esser essegnati da quel ruolo.
   */
  static getAllowedStatesByRole(roleName: RoleName) {
    switch (roleName) {
      case Roles.RogatoryUser.name:
        return [
          ContractState.ToReview,
          ContractState.Registered,
          ContractState.InRegistration
        ];
      case Roles.DecenteredUser.name:
        return [ContractState.ToVerify, ContractState.Paid];
      default:
        return null;
    }
  }

  /**
   * Dato lo stato, ritorna la label corrispondente.
   * (Permette di utilizzare la label nei messaggi d'errore
   * o nelle options di una select)
   */
  static getStateLabel(state: ContractState) {
    switch (state) {
      case ContractState.InProgress:
        return 'In Lavorazione';
      case ContractState.ToVerify:
        return 'In Verifica';
      case ContractState.ToReview:
        return 'In Revisione';
      case ContractState.Registered:
        return 'Registrato';
      case ContractState.InRegistration:
        return 'In Registrazione';
      case ContractState.Paid:
        return 'Saldato';
      case ContractState.Closed:
        return 'Chiuso';
      case ContractState.Cancelled:
        return 'Annullato';
      default:
        assertNever(state);
    }
  }

  /**
   * Ritorna vero se l'utente ha la possibilità di
   * impostare lo stato `state` a un contratto.
   * (indipendentemente dallo stato attuale del contratto)
   */
  static allowTransition(
    state: ContractState,
    user: UserDto | null | undefined
  ) {
    if (!user) return false;

    const role = user.getRole();

    const allowedStates = this.getAllowedStatesByRole(role.name);

    if (!allowedStates) return false;

    return allowedStates.includes(state);
  }

  /** Incrementa il valore della percentuale richiesta */
  static applyRate(base: Decimal, rate: Decimal) {
    // X + X * Y%
    return base.plus(base.times(rate.dividedBy(100)));
  }

  /** Ritorna il totale calcolato in base alle aliquote */
  static computeGrossAmount(
    contract: Pick<IContractType, 'netAmount' | 'cashRate' | 'vatRate'>
  ) {
    let total = new Decimal(0);
    if (!contract.netAmount) return total;

    total = contract.netAmount;

    if (contract.cashRate) {
      total = this.applyRate(total, contract.cashRate);
    }

    if (contract.vatRate) {
      total = this.applyRate(total, contract.vatRate);
    }

    return total;
  }
}

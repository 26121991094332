import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    /**
     * Controlla che il valore fornito sia fra uno di quelli permessi
     */
    oneOfEnum<T extends string, TEnumValue extends string>(
      // See https://github.com/microsoft/TypeScript/issues/30611#issuecomment-570773496
      enumValue: { [key in T]: TEnumValue },
      message?: string
    ): StringSchema<TEnumValue>;
  }

  interface NumberSchema {
    /**
     * Controlla che il valore fornito sia fra uno di quelli permessi
     */
    oneOfEnum<T extends string, TEnumValue extends number>(
      // See https://github.com/microsoft/TypeScript/issues/30611#issuecomment-570773496
      enumValue: { [key in T]: TEnumValue },
      message?: string
    ): NumberSchema<TEnumValue>;
  }
}

yup.addMethod(
  yup.mixed,
  'oneOfEnum',
  function (this: yup.BaseSchema<any>, enumValue, message) {
    return this.test({
      message:
        message ??
        'Il campo ${path} non rientra fra le opzioni disponibili: ${enumValue}.',
      name: 'oneOfEnum',
      exclusive: true,
      params: { enumValue: Object.values(enumValue) },
      test(value) {
        if (!value) return true;
        return Object.values(enumValue).includes(value);
      }
    });
  }
);

import moment from 'moment';
import * as yup from 'yup';

export interface ICheckPeriodOptions {
  /**
   * Se vero: le date possono essere valorizzate o meno indipendentemente.
   * Se falso/undefined: una data non può essere null se l'altra è valorizzata
   */
  optional?: boolean;
  /** Label da utilizzare per la data d'inizio */
  startDateLabel?: string;
  /** Label da utilizzare per la data di fine */
  endDateLabel?: string;
  /** Messaggio d'errore */
  errorMessage?: string;
}
declare module 'yup' {
  interface DateSchema {
    /**
     * Controlla validità del periodo con estremo la data.
     */
    checkPeriod(endDateName: string, options?: ICheckPeriodOptions): DateSchema;
  }
}

yup.addMethod(
  yup.date,
  'checkPeriod',
  function (
    this: yup.DateSchema<any>,
    endDateName: string,
    options?: ICheckPeriodOptions
  ) {
    return this.test({
      name: 'checkPeriod',
      exclusive: true,
      params: { endDateName, options },
      message: options?.errorMessage ?? 'Le date non sono congruenti',
      test: (startDate, context) => {
        const endDate = context.parent[endDateName];

        // Entrambe null
        if (startDate == null && endDate == null) {
          return true;
        }

        // Solo la start null
        if (startDate == null && !options?.optional) {
          return context.createError({
            message: `La ${
              options?.startDateLabel ?? "data d'inizio periodo"
            } non può essere lasciata vuota se la ${
              options?.endDateLabel ?? 'data di fine periodo'
            } è valorizzata`
          });
        }

        // Solo la end null
        if (endDate == null && !options?.optional) {
          return context.createError({
            path: endDateName,
            message: `La ${
              options?.endDateLabel ?? 'data di fine periodo'
            } non può essere lasciata vuota se la ${
              options?.startDateLabel ?? "data d'inizio periodo"
            } è valorizzata`
          });
        }

        // Entrambe definite
        // Se le date non sono in ordine
        if (
          moment(startDate).startOf('day').isAfter(moment(endDate).endOf('day'))
        ) {
          return false;
        }

        // OK
        return true;
      }
    });
  }
);

import { SyncOutlined, UserOutlined } from '@ant-design/icons';
import { message } from 'antd';
import Menu from 'antd/lib/menu';
import { usePermissions } from 'client/components/auth/AuthModule';
import React, { useCallback, useState } from 'react';
import { User } from 'server/schema/user/user/User.entity';
import { OfficeApi } from './OfficeApi';

export interface ISyncOfficeMenuItemProps {}

/**
 * Componente per la sincronizzazione degli uffici tra BE e Documentale
 */
export function SyncOfficeMenuItem(props: ISyncOfficeMenuItemProps) {
  const { ...otherProps } = props;
  const permissions = usePermissions();
  const [loading, setLoading] = useState(false);

  const LOADING_MESSAGE_KEY = 'assign-to-other-modal-message';

  const handleSync = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      message.loading({ content: 'Sincronizzazione in corso...', key: LOADING_MESSAGE_KEY, duration: 0 }); // prettier-ignore
      await OfficeApi.sync();
      message.destroy(LOADING_MESSAGE_KEY);
      message.success('Uffici sincronizzati con successo');
    } catch (e) {
      message.error(`Errore di sincronizzazione. Riprovare.`);
    } finally {
      message.destroy(LOADING_MESSAGE_KEY);
      setLoading(false);
    }
  }, []);

  if (!permissions.has('offices.sync')) return null;

  return (
    <Menu.Item
      {...otherProps}
      key="officesSync"
      icon={<SyncOutlined />}
      onClick={handleSync}
      disabled={loading}
    >
      Sincronizza Uffici
    </Menu.Item>
  );
}

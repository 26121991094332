import { ContractDto, IContractType } from 'common/dto/generated/ContractDto';
import { UserDto } from 'common/dto/generated/UserDto';
import { IDto } from 'common/dto/shared/DtoInterfaces';
import { EntityMachine } from 'common/fsm/EntityMachine';
import { AccessType } from 'common/permissions/ResourceAccessElementsTypes';
import { ContractState } from 'common/schema/contract/ContractTypes';
import { yup } from 'common/validation/initYup';
import {
  contractInRegistrationSchema,
  contractPaidSchema,
  contractRegisteredSchema,
  contractToReviewSchema,
  contractToVerifySchema
} from './ContractStateChangeValidation';

export const ContractMachine = EntityMachine.for<IContractType>()
  .withState(contract => contract.state ?? ContractState.InProgress)
  // Azioni
  .withAction('edit', {
    // Un contratto è modificabile solo se l'utente ha accesso in scrittura
    // ed è O un contratto _preregistrato_ OPPURE non è in uno stato non-modificabile (se si tratta di contratti registrati normalmente)
    allowed: (contract, user) =>
      contract.id == null ||
      (contract.canUser(AccessType.Write) &&
        (contract.preregistered ||
          ![
            ContractState.Paid,
            ContractState.Registered,
            ContractState.InRegistration
          ].includes(contract.state!))) ||
      (user != null && user.getRole()?.hasPermission('contracts.write'))
  })
  .withAction('read-register-number', {
    allowed: (contract, user) => {
      if (
        contract.state == ContractState.InRegistration &&
        !user?.getRole().hasPermission('rogatory')
      )
        return false;
      return contract.registeredAt ? true : false;
    }
  })
  // Transizioni di stato
  .withTransition(ContractState.InProgress, {
    to: ContractState.ToVerify,
    skip: (contract, user) =>
      !contract.canUser(AccessType.Write) ||
      !user?.getRole().hasPermission('contracts.state:toVerify'),
    schema: contractToVerifySchema
  })
  .withTransition(ContractState.ToVerify, ContractState.ToReview)
  .withTransition(ContractState.ToReview, {
    to: ContractState.ToVerify,
    skip: (contract, user) =>
      !contract.canUser(AccessType.Write) ||
      !user?.getRole().hasPermission('contracts.state:toVerify'),
    schema: contractToVerifySchema
  })
  .withTransition(ContractState.ToVerify, {
    to: ContractState.ToReview,
    skip: (contract, user) =>
      !contract.canUser(AccessType.Write) ||
      !user?.getRole().hasPermission('contracts.state:toReview'),
    schema: contractToReviewSchema
  })

  .withTransition(ContractState.ToVerify, {
    to: ContractState.InRegistration,
    skip: (contract, user) =>
      !contract.userAccessTypes?.includes(AccessType.Write) ||
      !user?.getRole().hasPermission('contracts.state:register'),
    schema: contractInRegistrationSchema
  })
  .withTransition(ContractState.InRegistration, {
    to: ContractState.Registered,
    skip: (contract, user) =>
      !contract.userAccessTypes?.includes(AccessType.Write) ||
      !user?.getRole().hasPermission('contracts.state:register'),
    schema: contractRegisteredSchema
  })
  .withTransition(ContractState.Registered, {
    to: ContractState.Paid,
    skip: (contract, user) =>
      !contract.userAccessTypes?.includes(AccessType.Write) ||
      !user?.getRole().hasPermission('contracts.state:paid'),
    schema: contractPaidSchema
  })
  .withTransition(ContractState.InRegistration, {
    to: ContractState.Cancelled,
    skip: checkToCancel
  })
  .withTransition(ContractState.Registered, {
    to: ContractState.Cancelled,
    skip: checkToCancel
  });

/**
 * Controlla che il contratto possa passare allo stato cancellato.
 * Il controllo è lo stesso per entrambe le transizioni.
 */
function checkToCancel(
  contract: IDto<ContractDto>,
  user: Maybe<IDto<UserDto>>
) {
  return (
    !contract.userAccessTypes?.includes(AccessType.Write) ||
    !user?.getRole().hasPermission('contracts.state:cancel')
  );
}

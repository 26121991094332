import * as React from 'react';
export interface ISectionTitleProps {
  title: React.ReactNode;
  icon?: React.ReactNode;
}

/**
 * Titolo di una sezione di contenuto (Panel collapsable)
 */
export function SectionTitle(props: ISectionTitleProps) {
  return (
    <>
      {props.icon && (
        <span
          style={{
            fontSize: '16px',
            lineHeight: 1,
            verticalAlign: 'text-bottom',
            marginRight: '12px'
          }}
        >
          {props.icon}
        </span>
      )}
      {props.title}
    </>
  );
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { UserDto } from './UserDto';
import { GenderTypes } from 'common/schema/supplier/GenderTypes';

/**
 * Rappresentazione DTO della classe Supplier definita in: src/server/schema/contract/supplier/Supplier.entity.ts
 * Hash: 5a1e35def20833d46fd080fac06a0e15
 */
@ValidationSchema(() => SupplierSchema)
export class SupplierDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number, description: 'ID Originale migrazione' })
  migrationId?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Denominazione' })
  companyName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome' })
  name?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Cognome' })
  surname?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Paese' })
  fiscalIdCountry?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Partita IVA' })
  fiscalIdCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Fiscale' })
  fiscalNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Indirizzo' })
  address?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Numero Civico' })
  civic?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CAP' })
  zip?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Comune' })
  city?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Provincia' })
  province?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nazione' })
  country?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Telefono' })
  phoneNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Fax' })
  faxNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Email' })
  email?: string | null | undefined;
  @ApiProperty({ required: false, enum: ['M', 'F'], description: 'Sesso' })
  gender?: GenderTypes | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Comune Nascita' })
  bornCity?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Provincia Nascita' })
  bornProvince?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Nascita' })
  bornDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Sede Legale' })
  hqAddress?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Sede Operativa' })
  operationsAddress?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Creazione' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Aggiornamento' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Eliminazione' })
  deletedAt?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<SupplierDto>) {
    if (values != null) {
      Object.assign(this, values instanceof SupplierDto ? values : plainToClass(SupplierDto, values));
    }
  }

  getFiscalNumber(): string {
    if (this.fiscalIdCode != null && this.fiscalIdCode !== '') {
      return this.fiscalIdCode;
    }

    return this.fiscalNumber!;
  }

  getName(): string {
    if (!this.companyName || this.companyName === '') {
      return `${this.name} ${this.surname}`;
    }

    return this.companyName;
  }

  getFiscalId(): string | null {
    if (!this.fiscalIdCountry && !this.fiscalIdCode) {
      return null;
    }

    return `${this.fiscalIdCountry}${this.fiscalIdCode}`;
  }

  getCityWithProvince(): string {
    return `${this.city ?? '--'} (${this.province ?? '--'})`;
  }

  getFullAddress(): string {
    const cityAndProvince = this.city && this.province
              ? `${this.city} (${this.province})`
              : this.city
              ? this.city
              : this.province
              ? this.province
              : null;
    const addressAndCivic = [this.address, this.civic]
            .filter(e => e != null)
            .join(' ');
    const cityZipAndCountry = [cityAndProvince, this.zip, this.country]
            .filter(e => e != null)
            .join(' ');
    return [addressAndCivic, cityZipAndCountry].join(', ');
  }

  async validate(options?: any) {
    const validated = await SupplierSchema.validate(classToPlain(this), options);
    return new SupplierDto(validated);
  }
}

/** Interfaccia simmetrica al DTO SupplierDto */
export type ISupplierType = IDto<SupplierDto>;

/**
 * DTO Paginato della classe Supplier
 */
export class PaginatedSupplierDto {
  @ApiProperty({ type: [SupplierDto] })
  @Type(() => SupplierDto)
  items!: SupplierDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const SupplierSchema = yup
  .object({
    id: yup.number(),
    companyName: yup.string().nullable().when(['name', 'surname'], {
          is: (
            name: string | null | undefined,
            surname: string | null | undefined
          ) => !name || !surname,
          then: schema =>
            schema.required(
              'Inserire la Denominazione oppure Nome e Cognome (entrambi i campi)'
            )
        }).label('Denominazione'),
    name: yup.string().nullable().label('Nome'),
    surname: yup.string().nullable().label('Cognome'),
    fiscalIdCountry: yup.string().nullable().when(['fiscalIdCode'], {
          is: (fiscalIdCode: string | null | undefined) => fiscalIdCode,
          then: schema =>
            schema.required('Inserire il Codice Paese della Partita IVA')
        }).label('Codice Paese'),
    fiscalIdCode: yup.string().nullable().label('Partita IVA'),
    fiscalNumber: yup.string().nullable().when(['fiscalIdCountry', 'fiscalIdCode'], {
          is: (
            fiscalIdCountry: string | null | undefined,
            fiscalIdCode: string | null | undefined
          ) => !fiscalIdCountry || !fiscalIdCode,
          then: schema =>
            schema.required('Inserire la Partita IVA o il Codice Fiscale')
        }).label('Codice Fiscale'),
    address: yup.string().nullable().label('Indirizzo'),
    civic: yup.string().nullable().label('Numero Civico'),
    zip: yup.string().nullable().label('CAP'),
    city: yup.string().nullable().label('Comune'),
    province: yup.string().nullable().label('Provincia'),
    country: yup.string().nullable().label('Nazione'),
    phoneNumber: yup.string().nullable().label('Telefono'),
    faxNumber: yup.string().nullable().label('Fax'),
    email: yup.string().nullable().email().label('Email'),
    gender: yup.string().oneOfEnum(GenderTypes).nullable().when(['name', 'surname'], {
          is: (
            name: string | null | undefined,
            surname: string | null | undefined
          ) => name && surname,
          then: schema => schema.required('Compilare il campo Sesso')
        }).label('Sesso'),
    bornCity: yup.string().nullable().label('Comune Nascita'),
    bornProvince: yup.string().nullable().label('Provincia Nascita'),
    bornDate: yup.date().nullable().label('Data Nascita'),
    hqAddress: yup.string().nullable().label('Sede Legale'),
    operationsAddress: yup.string().nullable().label('Sede Operativa')
  })
  .noUnknown()
  .meta({ schemaName: "SupplierSchema" })
  .required();

import { FormikValues, useFormikContext } from 'formik';
import React, { useState } from 'react';
import {
  UploadDraggerInput,
  UploadDraggerInputProps
} from './UploadDraggerInput';

export interface IUploadAsBase64InputProps<T extends FormikValues>
  extends UploadDraggerInputProps<T> {
  filenameFieldName: string;
}

/**
 * Permette l'upload di *un singolo file* come string Base64
 */
export function UploadAsBase64Input<T extends FormikValues>(
  props: IUploadAsBase64InputProps<T>
) {
  const { filenameFieldName, ...otherProps } = props;

  const formik = useFormikContext();
  const [fileList, setFileList] = useState<any[]>([]);

  return (
    <UploadDraggerInput
      fileList={fileList}
      onChange={info => {
        setFileList([...info.fileList.slice(-1)]);
      }}
      {...otherProps}
      beforeUpload={async file => {
        const reader = new FileReader();

        reader.onloadend = e => {
          const [inlineMimeType, content] = String(e.target?.result).split(','); // prettier-ignore
          formik.setFieldValue(otherProps.name, content);
          formik.setFieldValue(filenameFieldName, file.name);
        };
        reader.readAsDataURL(file);

        // Prevent upload
        return false;
      }}
      onRemove={() => {
        // Rimuovo il valore in caso di rimozione del file
        formik.setFieldValue(otherProps.name, undefined);
        formik.setFieldValue(filenameFieldName, undefined);
      }}
    />
  );
}

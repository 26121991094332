import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { TaxExportOrderBy } from 'common/schema/tax-export/TaxExportOrderBy';

@GenerateDto()
class TaxExportQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
  @SchemaLabel("Ordinamento")
  orderBy?: TaxExportOrderBy = TaxExportOrderBy.id;
  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
}

/**
 * Rappresentazione DTO della classe TaxExportQuery 
 * Hash: 395f1f8fbe1fc71b93ee1965a6dcc59b
 */
@ValidationSchema(() => TaxExportQuerySchema)
export class TaxExportQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, enum: ['id', 'year'], description: 'Ordinamento' })
  orderBy?: TaxExportOrderBy | undefined = TaxExportOrderBy.id;
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<TaxExportQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof TaxExportQueryDto ? values : plainToClass(TaxExportQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await TaxExportQuerySchema.validate(classToPlain(this), options);
    return new TaxExportQueryDto(validated);
  }
}

export const TaxExportQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    orderBy: yup.string().oneOfEnum(TaxExportOrderBy).default(TaxExportOrderBy.id).label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento')
  })
  .noUnknown()
  .meta({ schemaName: "TaxExportQuerySchema" })
  .required();

import { message } from 'antd';
import { UserApi } from 'client/components/schema/user/UserApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import React, { useCallback } from 'react';

export interface IAuthLinkProps {
  target?: string;
  href: string;
  children?: React.ReactNode;
  className?: string;
  inline?: boolean;
}

/**
 * Gestisce l'apertura di link che richiedono l'accesso come utente.
 * E.g. download di documenti.
 *
 * Prima di iniziare il redirect, fa una chiamata per refreshare il token.
 * In questo modo non si presenta l'errore 401 dovuto al token scaduto.
 */
export function AuthLink(props: IAuthLinkProps) {
  const { target, href, children, className, inline } = props;

  const [getCurrentUser] = useApiMutation(UserApi.findCurrentUser, {});

  const handleClick = useCallback(async () => {
    try {
      await getCurrentUser({});

      let a = document.createElement('a');
      if (target) {
        a.setAttribute('target', target);
      }
      a.href = href;
      a.click();
    } catch (e) {
      console.error('[Errore/AuthLink]', { error: e });
      message.error(`Si è verificato un errore. Riprovare.`);
    }
  }, [target, href]);

  return (
    <div
      onClick={handleClick}
      className={className}
      style={inline ? { display: 'inline' } : {}}
    >
      {children}
    </div>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { message, Tag, Dropdown, Button, Tooltip } from 'antd';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { ContractDto, ContractSchema } from 'common/dto/generated/ContractDto';
import { RouteComponentProps } from 'react-router';
import { EditAndSaveButton } from 'client/ui/form/button/EditAndSaveButton';
import { PageHeadingSummary } from 'client/ui/layout/page/heading/PageHeadingSummary';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { UserOutlined } from '@ant-design/icons';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { ConsumerApi } from 'client/components/schema/consumer/ConsumerApi';
import { ConsumerDto, ConsumerSchema } from 'common/dto/generated/ConsumerDto';
import { ConsumerMainSection } from './sections/ConsumerMainSection';

interface Params {
  id: string;
  filter?: string;
}

export interface ConsumerPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina del Committente
 */
export function ConsumerPage(props: ConsumerPageProps) {
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const { response, error, loading, refetch } = useApiQuery(ConsumerApi.find, {
    skip: id == null,
    data: {
      id: id!
    }
  });

  const [editable, setEditable] = useState(id == null);

  const consumer = id != null ? response?.data : new ConsumerDto();

  const [create] = useApiMutation(ConsumerApi.create, {
    invalidates: [ConsumerApi.find]
  });
  const [update] = useApiMutation(ConsumerApi.update, {
    invalidates: [ConsumerApi.find]
  });

  // const [deleteContract] = useApiMutation(ContractApi.softDelete, {
  //   invalidates: []
  // });
  // const handleDelete = useCallback(async () => {
  //   if (!id) return;
  //   if (!deletable) return;

  //   if (!deletable.allowed) {
  //     message.error(deletable.message);
  //     return;
  //   }

  //   try {
  //     await deleteContract({ data: { id } });
  //     message.success('Contratto eliminato con successo.');
  //     props.history.push('/contracts/all');
  //   } catch (e) {
  //     message.error("Errore durante l'eliminazione del contratto. Riprovare.");
  //   }
  // }, [id, deletable]);

  const title =
    id == null ? 'Nuovo Committente' : `Committente ${consumer?.name ?? ''}`;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/consumers/', title: `Committenti` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title,
    menuKey: 'admin'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere al committente"
        error={error}
      />
    );
  }

  if (!consumer) return <LoadingPage />;

  return (
    <CorePageLayout>
      <FormikAugmented<ConsumerDto>
        initialValues={consumer}
        enableReinitialize
        onReset={() => {
          setEditable(false);
        }}
        onSubmit={async rawValues => {
          try {
            const input = await rawValues.validate();
            if (id) {
              await update({ data: { input } });
              message.success('Committente modificato con successo. ');
            } else {
              const result = await create({
                data: { input }
              });
              message.success('Committente creato con successo. ');
              props.history.push(`/consumers/${result.data!.id}`);
            }
            setEditable(false);
          } catch (e) {
            message.error(
              'Si è verificato un errore durante il salvataggio del committente.'
            );
          }
        }}
        validationSchema={ConsumerSchema}
        validationContext={{
          machineActions: undefined
        }}
        // validateOnChange={false}
      >
        <FormikForm editable={editable} helpInTooltips showVisualFeedback>
          <PageHeadingRogante
            fixedWidth
            title={<PageHeadingTitle>{title}</PageHeadingTitle>}
            breadcrumbRender={() => <BreadcrumbsContainer />}
            tags={id == null ? <Tag>Non ancora salvato</Tag> : <span />}
            extra={
              <EditAndSaveButton
                isNew={id == null}
                name="Committente"
                state={[editable, setEditable]}
                whenSaved={
                  <>
                    {/* <Tooltip
                      title={
                        id != null && !deletable?.allowed && deletable?.message
                      }
                    >
                      <ButtonDeleteConfirm
                        title="Sei sicuro di voler eliminare il contratto?"
                        loading={loading}
                        size="middle"
                        disabled={id == null || !deletable?.allowed}
                        buttonIcon={<DeleteOutlined />}
                        buttonDanger
                        buttonType="primary"
                        onConfirm={handleDelete}
                      />
                    </Tooltip> */}
                  </>
                }
              />
            }
          >
            {/* {id && (
              <PageHeadingSummary
                column={3}
                items={[
                  { label: 'Oggetto', value: contract.subject, span: 3 },
                  {
                    label: 'Referente',
                    value: contract.adminReferent?.name ?? '--'
                  },
                  { label: 'RUP', value: contract.rupReferent?.name ?? '--' },
                  { label: 'DEC', value: contract.decReferent?.name ?? '--' }
                ]}
              />
            )} */}
          </PageHeadingRogante>
          <PageContent fixedWidth>
            <ConsumerMainSection />
          </PageContent>
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}

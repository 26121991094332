import React from 'react';
import { Radio } from 'antd';
import './RadioOptionBlock.scss';
import { DocumentKind } from 'common/schema/contract/contract-document/ContractDocumentTypes';
import { getDocumentKindDescription } from '../getDocumentKindDescription';

export interface RadioOptionBlockProps {
  value: DocumentKind;
  label: React.ReactNode;
}

/**
 * Elemento Radio mostrato come blocco Dedicato per Upload Documenti.
 *
 * // TODO Da rendere generico per altri progetti
 */
export function RadioOptionBlock(props: RadioOptionBlockProps) {
  const { value, label } = props;

  return (
    <Radio value={value} className="radio-option-block">
      <div>{label}</div>
      <div className="help-text">{getDocumentKindDescription(value)}</div>
    </Radio>
  );
}

/**
 * Entità "Embedded" di TypeOrm da generare
 */
export function GenerateEmbedded(): ClassDecorator {
  return (target: any) => {};
}

/**
 * Porta la funzione sul client
 */
export function GenerateClient(): PropertyDecorator {
  return (target: any) => {};
}

/**
 * Genera le entità annidate nel DTO
 */
export function GenerateNested(): PropertyDecorator {
  return (target: any) => {};
}

/**
 * Processa un DTO
 */
export function GenerateDto(): ClassDecorator {
  return (target: any) => {};
}

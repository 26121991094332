import { useField } from 'formik';
import { Col, Row, Typography } from 'antd';
import * as React from 'react';
import { useFormikFormContext } from '../FormikFormContext';
import { TextProps } from 'antd/lib/typography/Text';
import { FieldRenderComponentType } from '../input/render/FieldRenderComponentType';
import { useFormikFieldValidation } from '../hooks/validation/useFormikFieldValidation';

const { Title, Text } = Typography;

export interface IFormFieldProps<P extends {}> {
  /** Nome della proprietà nell'oggetto di input */
  name: string;

  /** Componente di rendering, i.e. `TextInput` */
  component: FieldRenderComponentType<P>;

  /** Proprietà di visualizzazione del testo (quando non è modificabile) */
  text?: TextProps;

  /** Specifica se il campo è in sola lettura */
  readOnly?: boolean;

  /** Modo alternativo di passare le props al componente per preservare i typing delle funzioni */
  componentProps?: Partial<P>;
}

/**
 * Wrapper per un Campo di Input (il tipo può essere specificato tramite `component`)
 * che può essere gestito sia in visualizzazione solo testo, sia come campo
 * di modifica (effettivo Input).
 *
 * La dinamicità fra visualizzazione e modifica è comandata dal Context creato
 * tramite il componente `<FormikForm>` con l'attributo `editable`.
 */
export function FormField<P>(props: IFormFieldProps<P> & P) {
  const {
    name,
    component: Component,
    text,
    readOnly,
    componentProps,
    ...otherProps
  } = props;
  const [field] = useField(name);

  const validation = useFormikFieldValidation(props.name, { readOnly });

  if (validation.editable) {
    return (
      <Component
        size="small"
        name={name}
        {...((otherProps as unknown) as P)}
        {...componentProps}
      />
    );
  } else {
    // In caso sia gestito il rendering di un valore dedicato, può essere
    // utilizzata una funzione a livello di `Input` che definisca la
    // trasformazione in un valore leggibile
    const value = Component.renderFieldValue
      ? Component.renderFieldValue(field.value, props)
      : field.value;

    // in caso sia una stringa aggiungiamo il componente Text
    if (typeof value === 'string') return <Text {...text}>{value}</Text>;

    // TODO Correggere in modo più esatto
    return value ?? '';
  }
}

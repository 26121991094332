import * as React from 'react';
import { Typography } from 'antd';
const { Paragraph } = Typography;

export interface ISectionSubtitleProps {
  text?: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Sottotitolo all'interno di una sezione
 */
export function SectionSubtitle(props: ISectionSubtitleProps) {
  return (
    <Paragraph type="secondary" style={{ marginBottom: '0.5em' }}>
      {props.text ?? props.children}
    </Paragraph>
  );
}

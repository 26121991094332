import { PlusOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';
import clx from 'classnames';

import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { genderOptions } from 'client/routes/supplier/edit/genderOptions';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { useFormikFieldValidation } from 'client/ui/form/hooks/validation/useFormikFieldValidation';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { RadioInput } from 'client/ui/form/input/RadioInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ConsumerDto } from 'common/dto/generated/ConsumerDto';
import { CountryCodeOptions } from 'common/schema/country-codes/CountryCodeOptions';
import { Formik, useField } from 'formik';
import * as React from 'react';
import { useRef } from 'react';
import { ConsumerApi } from '../ConsumerApi';

export interface IConsumerFieldItemProps {
  name: string;
}

/**
 * Permette di selezionare il committente.
 */
export function ConsumerFieldItem(props: IConsumerFieldItemProps) {
  const { name } = props;
  const [field, _, helpers] = useField<number | undefined>(name);
  const validation = useFormikFieldValidation(name);

  const { response } = useApiQuery(ConsumerApi.find, {
    skip: field.value == null,
    data: { id: field.value! }
  });
  const consumer = response?.data;

  return (
    <>
      <FormFieldsContainer columns={2} removeTopTitleSpacing>
        <DataItem
          className={clx({ editable: validation.editable })}
          ghost
          label="Committente"
          itemProps={{ required: validation.editable && validation.required }}
          value={
            validation.editable ? (
              <AsyncSelectInput
                // style={{ flex: '1 1 329px', maxWidth: '329px' }}
                name={name}
                placeholder="Seleziona Committente"
                size="small"
                query={{
                  apiFn: ConsumerApi.list,
                  options: (formik, search) => ({
                    data: {}
                  })
                }}
                // dropdownMatchSelectWidth={false}
                // showSearch // Non funziona "in-memory", o si implementa sull'AsyncSelectInput o si modifica la query
                responseTransform={data => data.items}
                // responseMeta={data => ({
                //   shown: data.items.length,
                //   total: data.meta.total
                // })}
                optionTransform={(consumer: ConsumerDto) => ({
                  label: `${consumer.name} (C.F. ${consumer.fiscalNumber})`,
                  value: consumer.id
                })}
              />
            ) : (
              consumer?.name ?? '--'
            )
          }
        />
        <DataItem label="Codice Fiscale" value={consumer?.fiscalNumber} />
        <DataItem label="Indirizzo" value={consumer?.address} />
        <DataItem label="Città" value={consumer?.getCityWithProvince()} />
      </FormFieldsContainer>
      <FormFieldsContainer columns={8} title="Dati Rappresentante Legale">
        <FormFieldItem
          colSpan={3}
          component={ConfigEnumInput}
          feature="supplier.values"
          enumKey="legalRole"
          label="Qualifica"
          name="consumerLegalRole"
          placeholder="Qualifica"
        />
        <FormFieldItem
          inline
          colSpan={5}
          component={TextInput}
          label="Descrizione"
          name="consumerLegalOther"
          placeholder="Descrizione Qualifica"
        />
        <FormFieldItem
          colSpan={3}
          label="Nome"
          placeholder="Nome"
          component={TextInput}
          name="consumerLegalName"
        />
        <FormFieldItem
          inline
          colSpan={3}
          label="Cognome"
          placeholder="Cognome"
          component={TextInput}
          name="consumerLegalSurname"
        />
        <FormFieldItem
          inline
          colSpan={2}
          label="Sesso"
          component={RadioInput}
          name="consumerLegalGender"
          options={genderOptions}
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={8}>
        <FormFieldItem
          colSpan={3}
          component={TextInput}
          label="Codice Fiscale"
          placeholder="Codice Fiscale"
          name="consumerLegalFiscalNumber"
        />
        <FormFieldItem
          inline
          colSpan={2}
          labelWidth={'width-140'}
          component={SelectInput}
          dropdownMatchSelectWidth={true}
          options={CountryCodeOptions}
          showSearch
          optionFilterProp="value"
          label="Sigla Nazione IVA"
          placeholder="Sigla Nazione IVA"
          name="consumerLegalFiscalIdCountry"
        />
        <FormFieldItem
          inline
          colSpan={3}
          component={TextInput}
          label="Codice IVA"
          placeholder="Codice IVA"
          name="consumerLegalFiscalIdCode"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={8}>
        <FormFieldItem
          colSpan={3}
          label="Comune Nascita"
          placeholder="Comune Nascita"
          component={TextInput}
          name="consumerLegalBornCity"
        />
        <FormFieldItem
          inline
          colSpan={2}
          label="Provincia Nascita"
          placeholder="Provincia Nascita"
          component={TextInput}
          name="consumerLegalBornProvince"
        />
        <FormFieldItem
          inline
          colSpan={3}
          label="Data Nascita"
          placeholder="Data Nascita"
          type="date-only"
          component={DatePickerInput}
          name="consumerLegalBornDate"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={3}>
        <FormFieldItem
          label="Telefono"
          placeholder="Telefono"
          component={TextInput}
          name="consumerLegalPhoneNumber"
        />
        <FormFieldItem
          inline
          label="Fax"
          placeholder="Fax"
          component={TextInput}
          name="consumerLegalFaxNumber"
        />
        <FormFieldItem
          inline
          label="Email"
          placeholder="Email"
          component={TextInput}
          name="consumerLegalEmail"
        />
      </FormFieldsContainer>
    </>
  );
}

import * as React from 'react';
import clx from 'classnames';
import { FormField, IFormFieldProps } from './FormField';
import './FormFieldItem.scss';
import { useFormikFormContext } from '../FormikFormContext';
import { useField } from 'formik';
import { FormItem } from '../FormItem';
import { ILabelWidth } from './ILabelWidth';
import { useFormikFieldValidation } from '../hooks/validation/useFormikFieldValidation';
import { RadioInput } from '../input/RadioInput';
import { CheckboxInput } from '../input/CheckboxInput';

export interface IFormFieldItemProps<P extends {}> extends IFormFieldProps<P> {
  /** Nasconde se è vuoto */
  hideIfEmpty?: boolean;

  /**
   * EREDITATE DA FormFieldLabelProps
   */
  label: React.ReactNode;
  /**
   * // TODO Verificare ed eliminare quelle che non servono
   * EREDITATE DA FormFieldLabelProps
   *
   * ghost: Mostra l'elemento senza bordo inferiore
   */
  ghost?: boolean;

  /**
   * inline: Mostra la Label e il campo di Input in linea
   */
  inline?: boolean;

  /**
   * labelWidth: Per gli elementi non in linea definisce
   * la larghezza della colonna della label
   * di default è 100px
   */
  labelWidth?: ILabelWidth;

  /**
   * ColSpan
   */
  colSpan?: number;

  /**
   * Permette di bloccare l'apertura del suggerimento di google
   * https://stackoverflow.com/questions/43132693/how-to-turn-off-html-input-form-field-suggestions
   */
  autoComplete?: null | 'off';
}

/**
 * Componente per mostrare una riga di Input con una label che sia
 * visualizzabile sia in Lettura (come semplice etichetta) sia in Scrittura
 * (come Input in base a quanto specificato).
 *
 * Per la specifica del comportamento del campo di input, vedere `FormField`
 */
export function FormFieldItem<P>(props: IFormFieldItemProps<P> & P) {
  const { label, ghost, inline, labelWidth, hideIfEmpty, ...otherProps } =
    props;

  // in caso di visualizzazione dell'Input
  // il componente è sempre senza filo inferiore (ghost)
  const [field] = useField(props.name);
  const formikForm = useFormikFormContext();
  const validation = useFormikFieldValidation(props.name, {
    readOnly: otherProps.readOnly
  });
  const borderless = formikForm.editable ?? ghost;

  const widthClass = inline ? 'width-inline' : labelWidth ?? 'width-100';

  // Editable
  const editable = validation.editable;

  // Nascondo il campo se vuoto
  if (hideIfEmpty && field.value == null) return null;

  // Escludiamo il simbolo richiesto per CheckBox o Radio
  const excludeRequired =
    props.component === (RadioInput as any) ||
    props.component === (CheckboxInput as any);

  return (
    <FormItem
      label={label}
      name={props.name}
      labelAlign="left"
      colon={false}
      required={editable && validation.required && !excludeRequired}
      className={clx('form-field-item', widthClass, {
        ghost: borderless,
        editable: editable
      })}
    >
      <FormField
        {...(otherProps as IFormFieldProps<P> & P)}
        style={{ width: '100%' }}
      />
    </FormItem>
  );
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { ConsumerDto } from './ConsumerDto';
import { UserDto } from './UserDto';

/**
 * Rappresentazione DTO della classe Office definita in: src/server/schema/organization/office/Office.entity.ts
 * Hash: a8d651a2b9f0ca8ab8ccf48186593968
 */
@ValidationSchema(() => OfficeSchema)
export class OfficeDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: Boolean, description: 'Ufficiale Rogante' })
  isRogatory!: boolean;
  @ApiProperty({ required: false, type: Number })
  uid?: number | undefined;
  @ApiProperty({ required: false, type: String })
  code?: string | undefined;
  @ApiProperty({ required: false, type: String })
  description?: string | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Organizzazione' })
  consumerId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => ConsumerDto })
  @Type(() => ConsumerDto)
  consumer?: ConsumerDto | null;
  @ApiProperty({ required: false, type: Number, description: 'ID Originale migrazione' })
  migrationId?: number | null | undefined;
  @ApiProperty({ required: false, type: Number })
  responsibleUserId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto, description: 'Utente responsabile' })
  @Type(() => UserDto)
  responsibleUser?: UserDto | null;
  @ApiProperty({ required: false, type: String })
  assignVisibility?: string | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Creazione' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Aggiornamento' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Eliminazione' })
  deletedAt?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<OfficeDto>) {
    if (values != null) {
      Object.assign(this, values instanceof OfficeDto ? values : plainToClass(OfficeDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await OfficeSchema.validate(classToPlain(this), options);
    return new OfficeDto(validated);
  }
}

/** Interfaccia simmetrica al DTO OfficeDto */
export type IOfficeType = IDto<OfficeDto>;

/**
 * DTO Paginato della classe Office
 */
export class PaginatedOfficeDto {
  @ApiProperty({ type: [OfficeDto] })
  @Type(() => OfficeDto)
  items!: OfficeDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const OfficeSchema = yup
  .object({
    id: yup.number(),
    isRogatory: yup.boolean().required().label('Ufficiale Rogante'),
    consumerId: yup.number().nullable().when('isRogatory', {
          is: true,
          then: s =>
            s.required(
              "È necessario indicare un organizzazione di appartenenza per l'Ufficio Rogante"
            ),
          otherwise: s =>
            s
              .notRequired()
              .nullable()
              .transform(() => null)
        }).label('Organizzazione'),
    responsibleUserId: yup.number().nullable()
  })
  .noUnknown()
  .meta({ schemaName: "OfficeSchema" })
  .required();

import { ArrowRightOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, message, Space, Tooltip } from 'antd';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ContractDto } from 'common/dto/generated/ContractDto';
import * as React from 'react';
import { useState } from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import {
  ContractSendToVerifyDto,
  ContractSendToVerifySchema
} from 'common/dto/schema/ContractSendToVerifyDto';
import { UploadAsBase64Input } from 'client/ui/form/input/UploadAsBase64Input';
import { ContractMachine } from 'common/schema/contract/ContractMachine';
import { ContractState } from 'common/schema/contract/ContractTypes';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { SendToVerifyReviewedForm } from './components/SendToVerifyReviewedForm';
import { ActivityLogApi } from 'client/components/schema/activity-log/ActivityLogApi';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';

export interface ContractSendToVerifyModalProps {
  contract?: ContractDto;
}

/**
 * Modal per l'invio in verifica del contratto all'Ufficiale Rogante
 *
 */
export function ContractSendToVerifyModal(
  props: ContractSendToVerifyModalProps
) {
  const { contract } = props;
  const currentUser = useCurrentUser();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [sendToVerify] = useApiMutation(ContractApi.actions.states.toVerify, {
    invalidates: [ContractApi.find, ActivityLogApi.list]
  });

  const transition = ContractMachine.to(
    contract,
    ContractState.ToVerify,
    currentUser
  );

  if (!transition.allowed && transition.skipped) return null;
  if (!contract) return null;

  return (
    <>
      <Tooltip title={!transition.allowed && transition.message}>
        <Button
          type="primary"
          disabled={!transition.allowed}
          onClick={showModal}
        >
          Invia a Ufficiale Rogante <ArrowRightOutlined />
        </Button>
      </Tooltip>

      <FormikAugmented
        enableReinitialize
        validateOnChange={false}
        initialValues={new ContractSendToVerifyDto()}
        validationSchema={ContractSendToVerifySchema}
        validationContext={{
          // Permette al validatore di cambiare nel caso
          // in cui si invii in verifica un contratto revisionato
          // (che non necessita l'upload del file)
          fromState: contract.state,
          contractOfficeId: contract.office?.id,
          userOfficeId: currentUser?.office?.id
        }}
        onSubmit={async values => {
          try {
            const input = await values.validate({
              context: {
                fromState: contract.state,
                contractOfficeId: contract.office?.id,
                userOfficeId: currentUser?.office?.id
              }
            });

            await sendToVerify({ data: { data: input, id: contract.id } });
            message.success('Operazione riuscita');
            handleCancel();
          } catch (e) {
            message.error('Si è verificato un errore. Riprovare.'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        <FormikForm nested inline editable={true} helpInTooltips>
          <ModalDialog
            title="Invia Contratto a Ufficiale Rogante per Registrazione"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={
              <Space>
                <Button onClick={handleCancel}>Annulla</Button>
                <FormikSendButton>
                  Invia a Ufficiale Rogante <ArrowRightOutlined />
                </FormikSendButton>
              </Space>
            }
          >
            {/* Mostra la modal "semplificata" con solo il content==descrizione nel caso di invio dopo la revisione */}
            {contract.state === ContractState.ToReview ? (
              <SendToVerifyReviewedForm />
            ) : (
              <>
                <Alert
                  message={
                    <>
                      Il documento in allegato <b>non</b> deve essere
                      protocollato. Il sistema lo protocollerà automaticamente
                      al momento dell'invio all'Ufficiale Rogante.
                    </>
                  }
                  type="info"
                  showIcon
                  style={{ marginBottom: '20px' }}
                />
                <FormFieldsContainer>
                  <FormFieldItem
                    labelWidth="width-180"
                    component={UploadAsBase64Input}
                    label="Lettera di richiesta in PDF"
                    name="content"
                    maxCount={1}
                    filenameFieldName="filename"
                  />
                  <FormFieldItem
                    labelWidth="width-180"
                    component={TextAreaInput}
                    label="Descrizione"
                    name="description"
                    placeholder="Descrizione"
                  />
                </FormFieldsContainer>
              </>
            )}
            {contract.office?.id !== currentUser?.office?.id && (
              <FormFieldsContainer
                title={
                  "L'ufficio con cui si è attualmente connessi è diverso dall'ufficio proponente del contratto."
                }
              >
                <FormFieldItem
                  component={CheckboxInput}
                  label={null}
                  name="allowsDifferentOffices"
                  style={{ fontSize: '1.5em' }}
                  children={
                    'Confermo di inviare il contratto con un ufficio diverso da quello registrato nel contratto.'
                  }
                />
              </FormFieldsContainer>
            )}
          </ModalDialog>
        </FormikForm>
      </FormikAugmented>
    </>
  );
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { TaxRecordState } from 'common/schema/tax-record/TaxRecordTypes';
import { ConsumerDto } from './ConsumerDto';
import { ContractDto } from './ContractDto';
import { SupplierDto } from './SupplierDto';
import { UserDto } from './UserDto';

/**
 * Rappresentazione DTO della classe TaxRecord definita in: src/server/schema/tax/tax-record/TaxRecord.entity.ts
 * Hash: 0d515261e94a21c8c70c70c22574638d
 */
@ValidationSchema(() => TaxRecordSchema)
export class TaxRecordDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number, description: 'ID Originale migrazione' })
  migrationId?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Autore' })
  authorId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  author?: UserDto | null;
  @ApiProperty({ required: false, type: Number, description: 'Ufficio' })
  officeId?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Contraente' })
  supplierId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => SupplierDto })
  @Type(() => SupplierDto)
  supplier?: SupplierDto | null;
  @ApiProperty({ required: false, type: Number, description: 'ID Contratto' })
  contractId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => ContractDto })
  @Type(() => ContractDto)
  contract?: ContractDto | null;
  @ApiProperty({ required: false, type: Number, description: 'Organizzazione' })
  consumerId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => ConsumerDto })
  @Type(() => ConsumerDto)
  consumer?: ConsumerDto | null;
  @ApiProperty({ required: false, enum: ['ToComplete', 'ToVerify', 'Refused', 'Accepted', 'NotSendable'], description: 'Stato' })
  state?: TaxRecordState | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Commento Revisione UR' })
  revisionNotes?: string | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Importo Corrisposto' })
  @TypeTransformDecimal()
  paymentAmount?: Decimal | null | undefined;
  @ApiProperty({ type: Number, description: 'Anno Pagamento' })
  paymentYear!: number;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Creazione' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Aggiornamento' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Eliminazione' })
  deletedAt?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<TaxRecordDto>) {
    if (values != null) {
      Object.assign(this, values instanceof TaxRecordDto ? values : plainToClass(TaxRecordDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await TaxRecordSchema.validate(classToPlain(this), options);
    return new TaxRecordDto(validated);
  }
}

/** Interfaccia simmetrica al DTO TaxRecordDto */
export type ITaxRecordType = IDto<TaxRecordDto>;

/**
 * DTO Paginato della classe TaxRecord
 */
export class PaginatedTaxRecordDto {
  @ApiProperty({ type: [TaxRecordDto] })
  @Type(() => TaxRecordDto)
  items!: TaxRecordDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const TaxRecordSchema = yup
  .object({
    id: yup.number(),
    officeId: yup.number().nullable().label('Ufficio'),
    supplierId: yup.number().nullable().label('Contraente'),
    contractId: yup.number().nullable().label('ID Contratto'),
    state: yup.string().oneOfEnum(TaxRecordState).nullable().label('Stato'),
    revisionNotes: yup.string().nullable().label('Commento Revisione UR'),
    paymentAmount: yup.mixed().nullable().label('Importo Corrisposto'),
    paymentYear: yup.number().required().label('Anno Pagamento')
  })
  .noUnknown()
  .meta({ schemaName: "TaxRecordSchema" })
  .required();

import Form, { FormItemProps } from 'antd/lib/form';
import clx from 'classnames';
import {
  useFormikContext,
  FormikValues,
  FormikContext,
  FormikContextType,
  getIn
} from 'formik';
import React, { useState } from 'react';
import './FormItem.scss';
import { useFormikFormContext } from './FormikFormContext';
import reactStringReplace from 'react-string-replace';
import { Tooltip } from 'antd';

type Name<T extends FormikValues> = keyof T & string;

export interface Props<T extends FormikValues> extends FormItemProps {
  name?: Name<T>;
  names?: Name<T>[];
  dependsOnChecked?: Name<T>;
  children: React.ReactNode;
  hideSuccess?: boolean;
}

/**
 * Interfaccia utilizzata per i vari wrapper single-field (i.e. `TextInputItem`)
 */
export interface SingleFormItemProps<T extends FormikValues>
  extends FormItemProps {
  dependsOnChecked?: Name<T>;
}

interface NameValidateStatus {
  hasFeedback: boolean;
  validateStatus: ValidateStatus;
  help: any;
}

type ValidateStatus =
  | ''
  | 'error'
  | 'success'
  | 'warning'
  | 'validating'
  | undefined;

const ValidateStatusPriority = [
  'validating',
  'error',
  'warning',
  'success',
  undefined
];

function maxValidateStatus(
  a: ValidateStatus,
  b: ValidateStatus
): ValidateStatus {
  if (ValidateStatusPriority.indexOf(a) <= ValidateStatusPriority.indexOf(b))
    return a;
  return b;
}

function getValidateStatus<T>(
  formik: FormikContextType<T>,
  name: Name<T>
): NameValidateStatus {
  const touched = getIn(formik.touched, name);
  const error = getIn(formik.errors, name);

  const hasFeedback = !!touched || formik.submitCount > 0;

  const validateStatus =
    hasFeedback && error ? 'error' : hasFeedback ? 'success' : undefined;

  const help = hasFeedback ? error : null;

  return { hasFeedback, validateStatus, help };
}

function joinHelps(help: any, other: any) {
  if (help == undefined) return other;
  if (other == undefined) return help;
  return `${help} ${other}`;
}

export function getAllValidateStatus<T>(
  formik: FormikContextType<T>,
  names: Name<T>[]
): NameValidateStatus {
  return names.reduce(
    (status: NameValidateStatus, name: Name<T>) => {
      const fieldStatus = getValidateStatus(formik, name);
      return {
        hasFeedback: status.hasFeedback || fieldStatus.hasFeedback,
        validateStatus: maxValidateStatus(
          status.validateStatus,
          fieldStatus.validateStatus
        ),
        help: joinHelps(status.help, fieldStatus.help)
      };
    },
    {
      hasFeedback: false,
      validateStatus: undefined,
      help: null
    }
  );
}

const AntFormItem = Form.Item;
export { AntFormItem };

/**
 * Sostituisce il nome del campo con il label dinamicamente, in caso sia
 * necessario a seguito della validazione.
 */
function replaceNamesWithLabel(
  text: string,
  names: string[],
  label: React.ReactNode
) {
  return names.reduce(
    (replaced: React.ReactNodeArray | string, name) =>
      reactStringReplace(replaced, name, () => label),
    text as React.ReactNodeArray | string
  );
}

/**
 * Campo di un input per mostrare gli errori associati.
 */
export function FormItem<T extends FormikValues>(props: Props<T>) {
  const {
    name,
    names,
    dependsOnChecked,
    label,
    className,
    ...otherProps
  } = props;
  // const [validating, setValidating] = useState(false);
  const formik = useFormikContext<T>();
  const formikForm = useFormikFormContext();

  const fieldNames = name == null ? names : [name];
  if (fieldNames == null) {
    throw new Error(
      'Impossibile mostrare i campi. Almeno uno fra name e names deve essere passato.'
    );
  }

  const validations = getAllValidateStatus(formik, fieldNames);

  // Depends on..
  if (dependsOnChecked && !formik.values[dependsOnChecked]) {
    return null;
  }

  // Gestione dell'`hideSuccess`
  const validateStatus =
    (props.hideSuccess || formikForm.hideSuccess) &&
    validations.validateStatus === 'success'
      ? undefined
      : validations.validateStatus;

  // Gestione di errori come tooltips
  const helpInTooltips = formikForm.helpInTooltips; // && validations.help != null;

  // Sostituisco il nome, se rimasto dalla validazione, con il label
  const help =
    typeof validations.help === 'string'
      ? replaceNamesWithLabel(validations.help, fieldNames, label)
      : null;

  return (
    <Form.Item
      className={clx(className, { 'help-in-tooltips': helpInTooltips })}
      hasFeedback={validations.hasFeedback}
      help={helpInTooltips ? null : <span>{help}</span>}
      validateStatus={validateStatus}
      label={label}
      {...otherProps}
    >
      {helpInTooltips ? (
        <Tooltip title={help}>
          <div>{props.children}</div>
        </Tooltip>
      ) : (
        props.children
      )}
    </Form.Item>
  );
}

import { ColumnProps } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { ContractLogic } from 'common/schema/contract/ContractLogic';
import { ContractState } from 'common/schema/contract/ContractTypes';
import React from 'react';
import { contractStateOptions, ContractStateTag } from '../../ContractStateTag';

export const ContractColumns: ColumnProps<ContractDto>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 90,
    // In questo modo previene di impostare la colonna a undefined.
    // Avendo un default nel dto, si crea un problema quando viene
    // impostato a undefined
    sortDirections: ['ascend', 'descend', 'ascend'],
    ...createColumnFilter(FilterInputType.Number)
  },
  {
    title: 'Stato',
    dataIndex: 'state',
    key: 'state',
    width: 120,
    render: (_, record) => <ContractStateTag state={record.state} />,
    ...createColumnFilter(FilterInputType.Enum, {
      options: contractStateOptions()
    })
  },
  {
    title: 'Oggetto',
    dataIndex: 'subject',
    key: 'subject',
    ...createColumnFilter(FilterInputType.Text)
  },
  {
    title: 'N. Registro',
    dataIndex: 'documentNumber',
    key: 'documentNumber',
    sorter: true,
    width: 130,
    ...createColumnFilter(FilterInputType.Number)
  },
  {
    title: 'Stipula',
    dataIndex: 'stipulatedAt',
    key: 'stipulatedAt',
    width: 120,
    sorter: true,
    render: (_, record) =>
      record.stipulatedAt ? (
        <ISODate date={record.stipulatedAt} />
      ) : (
        record.stipulatedYear
      ),
    ...createColumnFilter(FilterInputType.DateRange)
  }
  // {
  //   title: 'Decorrenza',
  //   dataIndex: 'validityStartAt',
  //   key: 'validityStartAt',
  //   width: 120,
  //   render: (_, record) => <ISODate date={record.validityStartAt} />
  // },
  // {
  //   title: 'Scadenza',
  //   dataIndex: 'validityEndAt',
  //   key: 'validityEndAt',
  //   width: 120,
  //   render: (_, record) => <ISODate date={record.validityEndAt} />
  // }
];

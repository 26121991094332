import { Role } from './Role';

const System = new Role('System', 'Utente di Sistema', [
  'current-user.read',
  // Utenti
  'users.read',
  'users.write',
  // Uffici
  'offices.read'
]);

const SuperAdmin = new Role('SuperAdmin', 'Super Amministratore', [
  'current-user.read',
  // Utenti
  'users.read',
  'users.write',
  'users.token.create',
  // Uffici
  'offices.sync',
  'offices.read',
  // Activity Log
  'activity.log.read',
  // Contratti
  'contracts.transfer'
]);

const DocumentalUser = new Role('DocumentalUser', 'Utente Documentale', [
  'current-user.read',
  // Activity Log
  'activity.log.read'
]);

const RogatoryAdmin = new Role('RogatoryAdmin', 'Amministratore UR', [
  'current-user.read',
  // Uffici
  'offices.read',
  'offices.sync',
  'supplier.read',
  'supplier.write',
  'consumers.read',
  'consumers.write',
  // Contratti
  'contracts.read',
  'contracts.write',
  'contracts.transfer',
  // Activity Log
  'activity.log.read',
  // Utenti
  'users.read',
  'users.write'
]);

const RogatoryUser = new Role('RogatoryUser', 'Gestione Contratti UR', [
  'current-user.read',
  'contracts.read:dynamic',
  'contracts.write:dynamic',
  'contracts.preregister',
  'consumers.read',
  'consumers.write',
  'rogatory',
  'contracts.state:register',
  'contracts.state:toReview',
  'contracts.state:cancel',
  'tax-records.read:dynamic',
  'tax-records.write:dynamic',
  'tax-records.state:evaluated',
  'supplier.read',
  'supplier.write',
  'tax-exports.read',
  'tax-exports.write',
  // Uffici
  'offices.read',
  'offices.sync',
  // Activity Log
  'activity.log.read'
]);

const DecenteredUser = new Role('DecenteredUser', 'Utente UD', [
  'current-user.read',
  'consumers.read',
  // Solo i contratti del proprio ufficio o propri
  'contracts.create',
  'contracts.read:dynamic',
  'contracts.write:dynamic',
  'contracts.state:toVerify',
  'contracts.state:paid',
  'tax-records.create',
  'tax-records.read:dynamic',
  'tax-records.write:dynamic',
  'tax-records.state:toVerify',
  'tax-records.edit',
  'supplier.read',
  'supplier.write',
  'contracts.preregister',
  // Uffici
  'offices.read',
  'contracts.preregister',
  // Activity Log
  'activity.log.read'
]);

/**
 * Stessi permessi di Utente UD ma senza invio
 */
const DecenteredSupportUser = new Role(
  'DecenteredSupportUser',
  'Utente Supporto UD',
  [
    'current-user.read',
    'consumers.read',
    // Solo i contratti del proprio ufficio o propri
    'contracts.create',
    'contracts.read:dynamic',
    'contracts.write:dynamic',
    // 'contracts.state:toVerify',
    // 'contracts.state:paid',
    'tax-records.create',
    'tax-records.read:dynamic',
    'tax-records.write:dynamic',
    'tax-records.state:toVerify',
    'tax-records.edit',
    'supplier.read',
    'supplier.write',
    'contracts.preregister',
    // Uffici
    'offices.read',
    'contracts.preregister',
    // Activity Log
    'activity.log.read'
  ]
);

// Utente LDAP non ancora collegato a un Ruolo applicativo
const LdapUser = new Role('LdapUser', 'Utente LDAP', ['current-user.read']);

export const Roles = {
  SuperAdmin,
  System,
  DocumentalUser,
  LdapUser,
  DecenteredUser,
  DecenteredSupportUser,
  RogatoryUser,
  RogatoryAdmin
};

export const RoleOptions = Object.keys(Roles).map(role => ({
  label: Roles[role as RoleName].displayName,
  value: Roles[role as RoleName].name
}));

export type RoleName = keyof typeof Roles;

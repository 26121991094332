import { NetworkContextState } from './NetworkContext';
import { Subject } from 'rxjs';

export function configureNetworkState(): NetworkContextState {
  return {
    invalidator: new Subject<any>()
  };
}

export const networkState = configureNetworkState();

import * as React from 'react';
import { AuditOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { Section } from 'client/ui/section/Section';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { useFormikContext } from 'formik';
import { DataItem } from 'client/ui/form/field/DataItem';
import { ConsumerFieldItem } from 'client/components/schema/consumer/input/ConsumerFieldItem';
import { OfficeFieldItem } from 'client/components/schema/office/input/OfficeFieldItem';

export interface ContractOfficeSectionProps {
  /**
   * Se attivo, permette la selezione dell'ufficio.
   * Altrimenti sarà una label con l'ufficio attualmente selezionato
   */
  officeSelection?: boolean;

  /**
   * Se officeSelection a true, permette di indicare il nome
   * del campo select per l'officeFieldItem.
   * Di default è `officeId`
   */
  officeFieldName?: string;

  /**
   * Limita la selezione degli uffici con cui l'utente può identificarsi.
   * Se `true` limita la selezione ai soli uffici rogatori.
   * Se `false` limita la selezione ai soli uffici non rogatori.
   * Se `null`/`undefined` non limita la selezione.
   */
  isRogatory: boolean | undefined | null;
}

export function ContractOfficeSection(props: ContractOfficeSectionProps) {
  const { officeSelection, officeFieldName, isRogatory } = props;
  const formik = useFormikContext<ContractDto>();

  return (
    <Section
      icon={<AuditOutlined />}
      title="Ufficiale Rogante / Ufficio Proponente"
      hasPadding
    >
      <FormFieldsContainer
        title="Ufficiale Rogante Competente"
        removeTopTitleSpacing
      >
        <OfficeFieldItem name="rogatoryOfficeId" isRogatory={true} />
      </FormFieldsContainer>

      <FormFieldsContainer title="Dati Committente" removeTopTitleSpacing>
        <ConsumerFieldItem name="consumerId" />
      </FormFieldsContainer>

      <FormFieldsContainer
        columns={officeSelection ? 2 : 3}
        title="Dati Ufficio Proponente"
      >
        {officeSelection ? (
          <OfficeFieldItem
            name={officeFieldName ?? 'officeId'}
            isRogatory={isRogatory}
            label="Ufficio Proponente"
            labelWidth="width-140"
            showOnlyUserOfficesFilter
          />
        ) : (
          <DataItem
            colSpan={2}
            label="Ufficio"
            value={formik.values.office?.description}
          />
        )}
        <DataItem inline label="Utente" value={formik.values.author?.name} />
      </FormFieldsContainer>
    </Section>
  );
}

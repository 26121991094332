import * as React from 'react';
import { Avatar, Button, Popover, Typography } from 'antd';
import './HeaderUserData.scss';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ArrowDownOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { AuthRoleSwitcher } from 'client/components/auth/roles/AuthRoleSwitcher';

const { Text } = Typography;

export interface UserSubMenuProps {}

/**
 * Visualizza il nome ed il ruolo dell'utente loggato.
 */
export function HeaderUserData(props: UserSubMenuProps) {
  const currentUser = useCurrentUser();

  if (!currentUser) return null;

  return (
    <Popover
      trigger="click"
      align={{ points: ['tr', 'br'] }}
      content={<AuthRoleSwitcher />}
    >
      <div className="header-user-data">
        <div className="name-container">
          <Text ellipsis className="name">
            {currentUser?.name}
            {currentUser?.role && (
              <span className="role">
                - {currentUser?.getRole().displayName}
              </span>
            )}
          </Text>
        </div>
        <div className="office-container">
          <Text ellipsis className="office" strong>
            {currentUser?.documentalOfficeCode} -{' '}
            {currentUser?.documentalOfficeDescription}
          </Text>
        </div>
      </div>
    </Popover>
  );
}

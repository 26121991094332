import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';


@GenerateDto()
class ActivityLogQuery {
    @SchemaPageNumber()
    pageNumber!: number;
    @SchemaPageSize()
    pageSize!: number;

    @SchemaLabel('Entità')
    entity?: string | null;

    @SchemaLabel('Id Entità')
    entityId?: number | null;

    @SchemaLabel('Da data')
    dateFrom?: Date | null;

    @SchemaLabel('A data')
    dateTo?: Date | null;

    @SchemaLabel('Ordina per')
    orderBy?: string = 'createdAt';
    @SchemaLabel("Direzione di Ordinamento")
    orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
}

/**
 * Rappresentazione DTO della classe ActivityLogQuery 
 * Hash: bdfbfc348aeec4c7d950f8ba5206e1dc
 */
@ValidationSchema(() => ActivityLogQuerySchema)
export class ActivityLogQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, type: String, description: 'Entità' })
  entity?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Id Entità' })
  entityId?: number | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Da data' })
  dateFrom?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'A data' })
  dateTo?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ordina per' })
  orderBy?: string | undefined = 'createdAt';
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ActivityLogQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ActivityLogQueryDto ? values : plainToClass(ActivityLogQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ActivityLogQuerySchema.validate(classToPlain(this), options);
    return new ActivityLogQueryDto(validated);
  }
}

export const ActivityLogQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    entity: yup.string().nullable().label('Entità'),
    entityId: yup.number().nullable().label('Id Entità'),
    dateFrom: yup.date().nullable().label('Da data'),
    dateTo: yup.date().nullable().label('A data'),
    orderBy: yup.string().default('createdAt').label('Ordina per'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento')
  })
  .noUnknown()
  .meta({ schemaName: "ActivityLogQuerySchema" })
  .required();

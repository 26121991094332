import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { SupplierDto, SupplierSchema } from './SupplierDto';
import { GenderTypes } from 'common/schema/supplier/GenderTypes';

/**
 * Rappresentazione DTO della classe ContractSupplier definita in: src/server/schema/contract/contract-supplier/ContractSupplier.entity.ts
 * Hash: 9e790be55f8293dd82bd5c51f6ad7cf6
 */
@ValidationSchema(() => ContractSupplierSchema)
export class ContractSupplierDto {
  @ApiProperty({ required: false, type: Number, description: 'Contratto' })
  contractId!: number;
  @ApiProperty({ required: false, type: Number, description: 'Contraente' })
  supplierId!: number;
  @ApiProperty({ required: false, type: () => SupplierDto })
  @Type(() => SupplierDto)
  supplier!: SupplierDto;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Importo Contratto' })
  @TypeTransformDecimal()
  amount?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Qualifica' })
  legalRole?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione Qualifica' })
  legalOther?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome' })
  legalName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Cognome' })
  legalSurname?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Paese' })
  legalFiscalIdCountry?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Partita IVA' })
  legalFiscalIdCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Fiscale' })
  legalFiscalNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Telefono' })
  legalPhoneNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Fax' })
  legalFaxNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Email' })
  legalEmail?: string | null | undefined;
  @ApiProperty({ required: false, enum: ['M', 'F'], description: 'Sesso' })
  legalGender?: GenderTypes | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Comune Nascita' })
  legalBornCity?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Provincia Nascita' })
  legalBornProvince?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Nascita' })
  legalBornDate?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractSupplierDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractSupplierDto ? values : plainToClass(ContractSupplierDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ContractSupplierSchema.validate(classToPlain(this), options);
    return new ContractSupplierDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ContractSupplierDto */
export type IContractSupplierType = IDto<ContractSupplierDto>;

/**
 * DTO Paginato della classe ContractSupplier
 */
export class PaginatedContractSupplierDto {
  @ApiProperty({ type: [ContractSupplierDto] })
  @Type(() => ContractSupplierDto)
  items!: ContractSupplierDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ContractSupplierSchema = yup
  .object({
    contractId: yup.number().label('Contratto'),
    supplierId: yup.number().required().label('Contraente'),
    amount: yup.mixed().nullable().label('Importo Contratto'),
    legalRole: yup.string().nullable().label('Qualifica'),
    legalOther: yup.string().nullable().when('legalRole', {
          is: (role: string) => ['90', '95'].includes(role),
          then: schema =>
            schema.required('Inserire la descrizione per il Rappresentante Legale'),
          otherwise: schema =>
            schema
              .notRequired()
              .nullable()
              .transform(() => null)
        }).label('Descrizione Qualifica'),
    legalName: yup.string().nullable().label('Nome'),
    legalSurname: yup.string().nullable().label('Cognome'),
    legalFiscalIdCountry: yup.string().nullable().label('Codice Paese'),
    legalFiscalIdCode: yup.string().nullable().label('Partita IVA'),
    legalFiscalNumber: yup.string().nullable().label('Codice Fiscale'),
    legalPhoneNumber: yup.string().nullable().label('Telefono'),
    legalFaxNumber: yup.string().nullable().label('Fax'),
    legalEmail: yup.string().nullable().label('Email'),
    legalGender: yup.string().oneOfEnum(GenderTypes).nullable().label('Sesso'),
    legalBornCity: yup.string().nullable().label('Comune Nascita'),
    legalBornProvince: yup.string().nullable().label('Provincia Nascita'),
    legalBornDate: yup.date().nullable().label('Data Nascita')
  })
  .noUnknown()
  .meta({ schemaName: "ContractSupplierSchema" })
  .required();

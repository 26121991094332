import clx from 'classnames';

import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { useFormikFieldValidation } from 'client/ui/form/hooks/validation/useFormikFieldValidation';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { SupplierDto } from 'common/dto/generated/SupplierDto';
import { SupplierQueryDto } from 'common/dto/query/SupplierQueryDto';
import { SupplierOrderBy } from 'common/schema/supplier/SupplierTypes';
import { useField } from 'formik';
import * as React from 'react';
import { useCallback } from 'react';
import { SupplierApi } from '../SupplierApi';

export interface ISupplierFieldItemProps {
  name: string;
  objectName: string;
}

/**
 * Permette di selezionare il fornitore.
 */
export function SupplierFieldItem(props: ISupplierFieldItemProps) {
  const { name, objectName } = props;
  const [field, , helpers] = useField<number | undefined>(name);
  const [objectField, , objectHelpers] = useField<SupplierDto | undefined>(
    objectName
  );
  const validation = useFormikFieldValidation(name);

  const supplier = objectField.value;
  // const { response } = useApiQuery(SupplierApi.find, {
  //   skip: field.value == null,
  //   data: { id: field.value! }
  // });
  // const supplier = response?.data;

  return (
    <FormFieldsContainer columns={2} removeTopTitleSpacing>
      <DataItem
        colSpan={2}
        className={clx({ editable: validation.editable })}
        ghost
        label="Contraente"
        itemProps={{ required: validation.editable && validation.required }}
        value={
          validation.editable ? (
            <AsyncSelectInput
              // style={{ flex: '1 1 329px', maxWidth: '329px' }}
              name={name}
              objectName={objectName}
              placeholder="Seleziona Fornitore"
              size="small"
              query={{
                apiFn: SupplierApi.list,
                options: (formik, search, pagination) => ({
                  data: {
                    query: new SupplierQueryDto({
                      pageNumber: 1,
                      pageSize: pagination?.pageSize ?? 20,
                      fullname: search,
                      orderBy: SupplierOrderBy.fullname,
                      orderByDirection: ColumnSortDirections.ascend
                    })
                  }
                })
              }}
              refreshOnSearch
              dropdownMatchSelectWidth={false}
              showSearch
              responseTransform={data => data.items}
              responseMeta={data => ({
                shown: data.items.length,
                total: data.meta.total
              })}
              optionTransform={(supplier: SupplierDto) => ({
                label: `${supplier.getName()} (${supplier.getFiscalNumber()})`,
                value: supplier.id
              })}
              loadInitialValue={{
                apiFn: SupplierApi.find,
                options: (formik, search) => ({
                  skip: formik.values[name] == null,
                  data: {
                    id: formik.values[name]
                  }
                })
              }}
            />
          ) : (
            supplier?.getName() ?? '--'
          )
        }
      />
      <DataItem label="Partita IVA" value={supplier?.getFiscalNumber()} />
      <DataItem label="Indirizzo" value={supplier?.getFullAddress()} />
    </FormFieldsContainer>
  );
}

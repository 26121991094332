import { ContractDocumentDto } from 'common/dto/generated/ContractDocumentDto';
import { yup } from 'common/validation/initYup';
import { DocumentKind } from './contract-document/ContractDocumentTypes';

// TODO HI Completare gli schemi

/** Validazione per passare allo stato "In Verifica" */
export const contractToVerifySchema = yup
  .object({
    rogatoryOfficeId: yup.number().required().label('Ufficiale Rogante'),
    suppliers: yup.array().min(1, "È necessario inserire almeno un contraente per inviare il contratto in verifica"), // prettier-ignore
    documents: yup.array().test('documentPresenceTest', 
    'È necessario caricare il documento "Atto Principale" per inviare il contratto in verifica', 
    (documents: ContractDocumentDto[] | undefined) => {
      // Controlla che sia presente il mainDocument e che sia uno solo
      const mainDocument = documents?.filter(document => document.kind === DocumentKind.Principal);
      return !!mainDocument && mainDocument.length === 1;
    }).label('Documenti contratto') // prettier-ignore
  })
  .required();

/** Validazione per passare allo stato "In Revisione" */
export const contractToReviewSchema = yup.object({}).required().noUnknown();

/** Validazione per passare allo stato "Da Registrare" */
export const contractInRegistrationSchema = yup
  .object({})
  .required()
  .noUnknown();

/** Validazione per passare allo stato "Registrato" */
export const contractRegisteredSchema = yup.object({}).required().noUnknown();

/** Validazione per passare allo stato "Registrato con Scheda" */
export const contractRegisteredWithCardSchema = yup
  .object({})
  .required()
  .noUnknown();

/** Validazione per passare allo stato "Saldato" */
export const contractPaidSchema = yup.object({}).required().noUnknown();

import { apiClient } from 'client/core/network/apiClient';
import { OfficeDto, PaginatedOfficeDto } from 'common/dto/generated/OfficeDto';
import { OfficeQueryDto } from 'common/dto/query/OfficeQueryDto';

export const OfficeApi = {
  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(OfficeDto).get(`/offices/${opts.id}`),

  list: (opts: { query: OfficeQueryDto }) =>
    apiClient.requestWithResponseType(PaginatedOfficeDto).get(`/offices`, {
      params: opts.query
    }),

  sync: () =>
    apiClient.request().post<{ success: boolean }>(`/offices/actions/sync`)
};

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaPageNumber, SchemaPageSize, SchemaLabel } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from "client/ui/table/TableColumnSort";
import { SupplierOrderBy } from "common/schema/supplier/SupplierTypes";

@GenerateDto()
class SupplierQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
  @SchemaLabel("Denominazione")
  fullname?: string | null;
  @SchemaLabel('CF/P.IVA')
  fiscalNumber?: string | null;
  @SchemaLabel("Ordinamento")
  orderBy?: SupplierOrderBy = SupplierOrderBy.id;
  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
}

/**
 * Rappresentazione DTO della classe SupplierQuery 
 * Hash: 1b019b4e68551d8585e777d0c10aa8b4
 */
@ValidationSchema(() => SupplierQuerySchema)
export class SupplierQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, type: String, description: 'Denominazione' })
  fullname?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CF/P.IVA' })
  fiscalNumber?: string | null | undefined;
  @ApiProperty({ required: false, enum: ['id', 'createdAt', 'fullName'], description: 'Ordinamento' })
  orderBy?: SupplierOrderBy | undefined = SupplierOrderBy.id;
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<SupplierQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof SupplierQueryDto ? values : plainToClass(SupplierQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await SupplierQuerySchema.validate(classToPlain(this), options);
    return new SupplierQueryDto(validated);
  }
}

export const SupplierQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(1000).integer().positive().label('Dimensione pagina'),
    fullname: yup.string().nullable().label('Denominazione'),
    fiscalNumber: yup.string().nullable().label('CF/P.IVA'),
    orderBy: yup.string().oneOfEnum(SupplierOrderBy).default(SupplierOrderBy.id).label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento')
  })
  .noUnknown()
  .meta({ schemaName: "SupplierQuerySchema" })
  .required();

import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import React from 'react';

export function SendToVerifyReviewedForm() {
  return (
    <>
      <FormFieldsContainer>
        {/* <FormErrorsNotification /> */}
        <FormFieldItem
          labelWidth="width-140"
          component={TextAreaInput}
          label="Nota di revisione"
          name="content"
          placeholder="Descrizione"
          rows={6}
        />
      </FormFieldsContainer>
    </>
  );
}

import * as React from 'react';
import clx from 'classnames';
import { Col, Row, RowProps, Typography } from 'antd';
import { SectionSubtitle } from 'client/ui/section/SectionSubtitle';
const { Title, Text, Paragraph } = Typography;

export interface FormFieldsContainerProps extends Omit<RowProps, 'children'> {
  /**
   * Array di elementi da posizionare nelle colonne
   * */
  children: React.ReactNode;
  /**
   * Numero di colonne
   * */
  columns?: 1 | 2 | 3 | 4 | 6 | 8 | 12;

  /**
   * Permette l'inserimento di un titolo
   * */
  title?: string;
  /**
   * Sopra un titolo viene inserito uno spazio bianco di default
   * removeTopTitleSpacing permette di eliminarlo
   * */
  removeTopTitleSpacing?: boolean;
}
/**
 * Elememento contenitore di Riga per FormFieldLabel
 * */
export function FormFieldsContainer(props: FormFieldsContainerProps) {
  const { children, columns, title, removeTopTitleSpacing, ...rowsProps } =
    props;
  const span = 24 / (columns ?? 1);

  return (
    <Row gutter={24} {...rowsProps}>
      {props.title && (
        <Col
          span={24}
          style={props.removeTopTitleSpacing ? {} : { paddingTop: '14px' }}
        >
          <SectionSubtitle text={props.title} />
        </Col>
      )}
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) return null;
        return (
          <Col key={index} span={span * (child.props.colSpan ?? 1)}>
            {child}
          </Col>
        );
      })}
    </Row>
  );
}

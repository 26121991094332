import { Tag } from 'antd';
import { TaxRecordLogic } from 'common/schema/tax-record/TaxRecordLogic';
import { TaxRecordState } from 'common/schema/tax-record/TaxRecordTypes';
import React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface TaxRecordStateTagProps {
  state?: TaxRecordState | null | undefined;
}

export function TaxRecordStateTag(props: TaxRecordStateTagProps) {
  if (!props.state) return null;
  switch (props.state) {
    case TaxRecordState.Accepted:
      return (
        <Tag color="green">{TaxRecordLogic.getStateLabel(props.state)}</Tag>
      );
    case TaxRecordState.ToVerify:
      return (
        <Tag color="orange">{TaxRecordLogic.getStateLabel(props.state)}</Tag>
      );
    case TaxRecordState.ToComplete:
      return (
        <Tag color="blue">{TaxRecordLogic.getStateLabel(props.state)}</Tag>
      );
    case TaxRecordState.Refused:
      return <Tag color="red">{TaxRecordLogic.getStateLabel(props.state)}</Tag>;
    case TaxRecordState.NotSendable:
      return <Tag>{TaxRecordLogic.getStateLabel(props.state)}</Tag>;
    default:
      assertNever(props.state);
  }
}

import * as React from 'react';
import { Button, Table } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  ContractOrderBy,
  ContractState
} from 'common/schema/contract/ContractTypes';
import { useEffect, useState } from 'react';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { AuditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { LinkButton } from 'client/ui/link/LinkButton';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { ContractColumns } from './table/ContractColumns';
import { Section } from 'client/ui/section/Section';
import { ContractQueryDto } from 'common/dto/query/ContractQueryDto';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { getDateRange, getFilter } from 'client/ui/table/TableColumnFilter';
import { ContractSearchForm } from './table/ContractSearchForm';
import { omit, uniq } from 'lodash';
import { SectionButton } from 'client/ui/section/SectionButton';
import { SectionExtraFieldContainer } from 'client/ui/section/SectionExtraFieldContainer';
import { GhostCollapse } from 'client/ui/collapse/GhostCollapse';
import { Roles } from 'common/permissions/Roles';
import { ContractTransferModalForm } from '../modal/ContractTransferModalForm';

interface Params {
  filter: string;
}

export interface ContractListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco dei Contratti
 */
export function ContractListPage(props: ContractListPageProps) {
  const [query, setQuery] = useState<ContractQueryDto>(
    new ContractQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 10
      })
    )
  );
  const [searchVisible, setSearchVisible] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Contratti',
    menuKey: 'contracts'
  });

  const currentUser = useCurrentUser();

  const { response, loading, error } = useApiQuery(ContractApi.list, {
    data: { query }
  });

  const contracts = response?.data.items;

  const columns = getPaginationUrlColumns<ContractDto, ContractQueryDto>(
    ContractColumns,
    query,
    {
      stipulatedAt: ['stipulationDateFrom', 'stipulationDateTo']
    }
  );

  const rowSelection: TableRowSelection<ContractDto> | undefined = currentUser
    ?.getRole()
    .hasPermission('contracts.transfer')
    ? {
        type: 'checkbox',
        hideSelectAll: true,
        selectedRowKeys: selected,
        onSelect: (record, selection) => {
          if (selection) {
            setSelected([...selected, record.id!]);
          } else {
            setSelected(selected.filter(id => id !== record.id));
          }
        }
      }
    : undefined;

  // useEffect(() => {
  //   setOptions(mapContractsFilterToOptions(props.match.params.filter));
  // }, [props.match, options.filter]);

  useEffect(() => {
    setQuery(
      new ContractQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  }, [props.location.search]);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei contratti"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeadingRogante
        fixedWidth
        title={<PageHeadingTitle>Elenco Contratti</PageHeadingTitle>}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          <>
            {currentUser?.getRole().hasPermission('contracts.transfer') && (
              <ContractTransferModalForm selectedContracts={selected} />
            )}
            {currentUser?.getRole().hasPermission('contracts.create') && (
              <LinkButton
                to="/contracts/create"
                type="primary"
                icon={<PlusOutlined />}
              >
                Nuovo Contratto
              </LinkButton>
            )}
            {currentUser?.getRole().hasPermission('contracts.preregister') && (
              <LinkButton
                to="/contracts/preregistered"
                type="primary"
                icon={<PlusOutlined />}
              >
                Nuovo Contratto Preregistrato
              </LinkButton>
            )}
          </>
        }
      />
      <PageContent fixedWidth>
        <Section
          icon={<AuditOutlined />}
          title="Elenco Contratti"
          extra={
            <SectionExtraFieldContainer>
              <SectionButton
                type="default"
                children="Ricerca Avanzata"
                icon={<SearchOutlined />}
                onClick={() => setSearchVisible(!searchVisible)}
              />
            </SectionExtraFieldContainer>
          }
        >
          <GhostCollapse visible={searchVisible}>
            <ContractSearchForm
              initialValues={query}
              onFilter={(updatedQuery: ContractQueryDto) => {
                const contractQuery = new ContractQueryDto({
                  ...query,
                  ...updatedQuery
                });
                setPaginationUrlQuery(props, contractQuery);
              }}
              onReset={() => {
                // Rimuovo dalla query i campi dei filtri avanzati
                const tableQuery = omit(query, [
                  'supplierCompanyName',
                  'supplierFullName',
                  'supplierFiscalCode',
                  'proponentOfficeId'
                ]);

                const contractQuery = new ContractQueryDto(tableQuery);
                setPaginationUrlQuery(props, contractQuery);
              }}
            />
          </GhostCollapse>
          <Table<ContractDto>
            rowSelection={rowSelection}
            loading={tableLoaderSpin(loading || !contracts)}
            rowKey="id"
            onRow={record => ({
              style: {
                cursor: 'pointer'
              },
              onClick: () => {
                props.history.push(`/contracts/${record.id}`);
              }
            })}
            columns={columns}
            dataSource={contracts}
            size="middle"
            onChange={(pagination, filters, sorter) => {
              const { field, order } = sorter as SorterResult<ContractDto>;

              const state = getFilter(filters, 'state') as
                | ContractState
                | undefined;

              const subject = getFilter(filters, 'subject') as
                | string
                | undefined;

              const documentNumber = getFilter(filters, 'documentNumber') as
                | number
                | undefined;

              const id = getFilter(filters, 'id') as number | undefined;

              const stipulationDateRange = getDateRange(filters.stipulatedAt);

              const contractQuery = new ContractQueryDto({
                ...query,
                id,
                state,
                subject,
                documentNumber,
                stipulationDateFrom: stipulationDateRange.from,
                stipulationDateTo: stipulationDateRange.to,
                pageNumber: pagination.current || query.pageNumber,
                pageSize: pagination.pageSize || query.pageSize,
                orderBy: order
                  ? (field?.toString() as ContractOrderBy)
                  : ContractOrderBy.id,
                orderByDirection: order
                  ? ColumnSortDirections[order]
                  : ColumnSortDirections.descend
              });

              setPaginationUrlQuery(props, contractQuery);
            }}
            pagination={{
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: true,
              total: response?.data.meta.total,
              pageSize: query.pageSize,
              current: query.pageNumber,
              pageSizeOptions: ['5', '10', '20', '30', '40']
            }}
          />
        </Section>
      </PageContent>
    </CorePageLayout>
  );
}

import * as React from 'react';
import clx from 'classnames';
import { Row, Col, Typography, Form, FormItemProps } from 'antd';
import { ILabelWidth } from './ILabelWidth';

const { Title, Text } = Typography;

export interface FormFieldLabelProps {
  label: React.ReactNode;
  children: React.ReactNode;
  className?: string;

  /**
   * ghost: Mostra l'elemento senza bordo inferiore
   */
  ghost?: boolean;

  /**
   * nested: Per gli elementi figli di altri FormFieldLabel
   * per eliminare gli spazi verticali incluso il filo per mantenere gli allineamenti
   */
  nested?: boolean;

  /**
   * inline: Mostra la Label e il campo di Input in linea
   */
  inline?: boolean;

  /**
   * labelWidth: Per gli elementi non in linea definisce
   * la larghezza della colonna della label
   * di default è 100px
   */
  labelWidth?: ILabelWidth;

  /**
   * Altre proprietà del `Form.Item`
   */
  itemProps?: FormItemProps<any>;

  /**
   * ColSpan
   */
  colSpan?: number;

  /**
   * bigger: Mostra la Label e il campo di Input con un corpo maggiorato per metterli in evidenza
   */
  bigger?: boolean;
}

/**
 * Semplice etichetta che contiene un elemento (che sia un campo di input
 * o un valore statico o un `FormField`).
 *
 * Per l'utilizzo con un campo di input, vedere `form-field-item`
 */
export function FormFieldLabel(props: FormFieldLabelProps) {
  const widthClass = props.inline
    ? 'width-inline'
    : props.labelWidth ?? 'width-100';
  return (
    <Form.Item
      label={props.label}
      labelAlign="left"
      colon={false}
      {...props.itemProps}
      className={clx(
        'form-field-item',
        props.className,
        widthClass,
        'help-in-tooltips',
        {
          ghost: props.ghost,
          nested: props.nested
        }
      )}
    >
      {props.children}
    </Form.Item>
  );
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from 'cli/generate/decorators/GenerateDecorators';
import { SchemaLabel } from 'cli/generate/decorators/SchemaDecorators';

@GenerateDto()
class ContractSendToReview {
  @SchemaLabel('Nota di Richiesta')
  content!: string;
}

/**
 * Rappresentazione DTO della classe ContractSendToReview 
 * Hash: 93ecb8b7f7c9486ac8fcffce3d141273
 */
@ValidationSchema(() => ContractSendToReviewSchema)
export class ContractSendToReviewDto {
  @ApiProperty({ type: String, description: 'Nota di Richiesta' })
  content!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractSendToReviewDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractSendToReviewDto ? values : plainToClass(ContractSendToReviewDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ContractSendToReviewSchema.validate(classToPlain(this), options);
    return new ContractSendToReviewDto(validated);
  }
}

export const ContractSendToReviewSchema = yup
  .object({
    content: yup.string().required().label('Nota di Richiesta')
  })
  .noUnknown()
  .meta({ schemaName: "ContractSendToReviewSchema" })
  .required();

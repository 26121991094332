import { DocumentKind } from 'common/schema/contract/contract-document/ContractDocumentTypes';
import { assertNever } from 'server/utils/typings/assertNever';

export function getDocumentKindDescription(
  kind: DocumentKind | null | undefined
) {
  if (!kind) return null;
  switch (kind) {
    case DocumentKind.Act:
      return 'Delibera, determina o altro documento presupposto alla richiesta';
    case DocumentKind.Attachment:
      return 'Documento che fisicamente è presente in allegato al contratto / atto principale, come ad esempio le planimetrie o il capitolato, ecc.';
    case DocumentKind.Other:
      return 'Documenti accessori non allegati al contratto ma di interesse per la richiesta. Saranno fascicolati ma non inseriti nel registro';
    case DocumentKind.Principal:
      return 'Contratto o altro Atto oggetto della presente richiesta';
    default:
      assertNever(kind);
  }
}

import { AxiosInstance } from 'axios';
import { message } from 'antd';
import { shouldRetryRequest } from './retryInterceptor';

export function errorsInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(undefined, error => {
    const { config, response } = error;

    // Redirect
    if (response?.status === 302 && response?.data?.redirect != null) {
      window.location.href = response.data.redirect;
    }

    if (
      response == null ||
      response.status < 400 ||
      response.data == null ||
      response.data.message == null ||
      shouldRetryRequest(error)
    ) {
      return Promise.reject(error);
    }

    // Il caso speciale di 401 Unauthorized lo gestiamo senza avvisi, perché
    // potrebbe essere ritentato o richiedere semplicemente un login.
    if (response.status === 401) return Promise.reject(error);

    // Avviso.
    message.error(response.data.message);

    return Promise.reject(error);
  });
}

import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router';

/**
 * Chiude le modal alla navigazione
 */
export function useModalHistoryCleanup() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      Modal.destroyAll();
    });
    return () => {
      unlisten();
    };
  }, []);
}

import React, { useState } from 'react';
import { Alert, message, Tag, Tooltip } from 'antd';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { ContractDto, ContractSchema } from 'common/dto/generated/ContractDto';
import { RouteComponentProps } from 'react-router';
import { ContractGeneralSection } from './sections/ContractGeneralSection';
import { EditAndSaveButton } from 'client/ui/form/button/EditAndSaveButton';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { ContractStateTag } from '../ContractStateTag';
import { ContractOfficeSection } from './sections/ContractOfficeSection';
import { ContractSuppliersSection } from './sections/ContractSuppliersSection';
import { ContractEconomicSection } from './sections/ContractEconomicSection';
import { ContractOthersSection } from './sections/ContractOthersSection';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { ContractTaxRecordsSection } from './sections/ContractTaxRecordsSection';
import { ContractSendToVerifyModal } from '../modal/ContractSendToVerifyModal';
import { ContractDocumentSection } from './sections/ContractDocumentSection';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ContractRegistrationSection } from './sections/ContractRegistrationSection';
import { ContractSendToReviewModal } from '../modal/ContractSendToReviewModal';
import { ContractMachine } from 'common/schema/contract/ContractMachine';
import { ContractTerminationModal } from '../modal/ContractTerminationModal';
import { ContractRegistrationDoneModal } from '../modal/ContractRegistrationDoneModal';
import { ContractSendToRegistrationModal } from '../modal/ContractSendToRegistrationModal';
import { MigratedTag } from '../MigratedTag';
import { PreRegisteredTag } from '../PreRegisteredTag';
import { ContractCancelModal } from '../modal/ContractCancelModal';
import { ContractActivityLogSection } from './sections/ContractActivityLogSection';
import { OfficeLogic } from 'common/permissions/OfficeLogic';
import { ContractRestoreModal } from '../modal/ContractRestoreModal';
import { ContractState } from 'common/schema/contract/ContractTypes';

interface Params {
  id: string;
  filter?: string;
}

export interface ContractPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina del Contratto
 */
export function ContractPage(props: ContractPageProps) {
  // Prendo l'ID del contratto
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const { response, error, loading, refetch } = useApiQuery(ContractApi.find, {
    skip: id == null,
    data: {
      id: id!
    }
  });

  // const deletable = ContractMachine.can(contract, 'delete', true);
  const deletable = true;
  const [editing, setEditing] = useState(id == null);

  const currentUser = useCurrentUser();

  const contract =
    id != null
      ? response?.data
      : new ContractDto({
          hasStampDuty: false,
          office: currentUser!.office,
          author: currentUser
        });

  const editable = ContractMachine.can(contract, 'edit', currentUser);

  const [create] = useApiMutation(ContractApi.create, {
    invalidates: [ContractApi.find]
  });
  const [update] = useApiMutation(ContractApi.update, {
    invalidates: [ContractApi.find]
  });

  // const [deleteContract] = useApiMutation(ContractApi.softDelete, {
  //   invalidates: []
  // });
  // const handleDelete = useCallback(async () => {
  //   if (!id) return;
  //   if (!deletable) return;

  //   if (!deletable.allowed) {
  //     message.error(deletable.message);
  //     return;
  //   }

  //   try {
  //     await deleteContract({ data: { id } });
  //     message.success('Contratto eliminato con successo.');
  //     props.history.push('/contracts/all');
  //   } catch (e) {
  //     message.error("Errore durante l'eliminazione del contratto. Riprovare.");
  //   }
  // }, [id, deletable]);

  const contractTitle =
    id == null ? 'Nuovo Contratto' : `Contratto ID ${contract?.id}`;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/contracts/', title: `Contratti` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: contractTitle,
    menuKey: 'contracts'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere al contratto"
        error={error}
      />
    );
  }

  if (!contract) return <LoadingPage />;

  return (
    <CorePageLayout>
      <FormikAugmented<ContractDto>
        initialValues={contract}
        enableReinitialize
        onReset={() => {
          setEditing(false);
        }}
        onSubmit={async rawValues => {
          try {
            const input = await rawValues.validate();
            if (id) {
              await update({ data: { input } });
              message.success('Contratto modificato con successo. ');
            } else {
              const result = await create({
                data: { input }
              });
              message.success('Contratto creato con successo. ');
              props.history.push(`/contracts/${result.data!.id}`);
            }
            setEditing(false);
          } catch (e) {
            message.error(
              'Si è verificato un errore durante la creazione del contratto.'
            );
          }
        }}
        validationSchema={ContractSchema}
        validateOnChange={false}
        validationContext={{
          machineActions: undefined
        }}
      >
        <FormikForm
          editable={editing && editable.allowed}
          helpInTooltips
          showVisualFeedback
        >
          <PageHeadingRogante
            fixedWidth
            title={<PageHeadingTitle>{contractTitle}</PageHeadingTitle>}
            breadcrumbRender={() => <BreadcrumbsContainer />}
            // TODO Potremmo renderlo un comportamento standard
            tags={
              id == null ? (
                <Tag>Non ancora salvato</Tag>
              ) : (
                <>
                  <ContractStateTag state={contract.state} />
                  <MigratedTag contract={contract} />
                  <PreRegisteredTag contract={contract} />
                </>
              )
            }
            extra={
              <>
                {editable.allowed && (
                  <EditAndSaveButton
                    isNew={id == null}
                    disabled={OfficeLogic.isNotAssignVisible(
                      currentUser?.office?.assignVisibility
                    )}
                    name="Contratto"
                    state={[editing, setEditing]}
                    whenSaved={
                      <>
                        <ContractSendToVerifyModal
                          key="to-verify"
                          contract={contract}
                        />
                        <ContractSendToReviewModal
                          key="to-review"
                          contract={contract}
                        />
                        <ContractSendToRegistrationModal
                          contract={contract}
                          key="to-register"
                        />
                      </>
                    }
                  />
                )}
                <ContractCancelModal contract={contract} key="cancelled" />
                <ContractRestoreModal
                  contract={contract}
                  key="restored-registered"
                  restoredState={ContractState.Registered}
                />
                <ContractRestoreModal
                  contract={contract}
                  key="restored-in-registration"
                  restoredState={ContractState.InRegistration}
                />
                <ContractRegistrationDoneModal
                  contract={contract}
                  key="registered"
                />
                <ContractTerminationModal
                  contract={contract}
                  key="to-termination"
                />
              </>
            }
          >
            {
              id && null
              // <PageHeadingSummary
              //   column={1}
              //   items={[{ label: 'Oggetto', value: contract.subject }]}
              // />
            }
          </PageHeadingRogante>
          <PageContent fixedWidth>
            {OfficeLogic.isNotAssignVisible(
              currentUser?.office?.assignVisibility
            ) && (
              <Alert
                message={`Non è possibile ${
                  id ? `modificare` : `creare`
                } il contratto in quanto l'ufficio non è visibile per l'assegnazione`}
                type="warning"
                showIcon
              />
            )}
            <ContractRegistrationSection contract={contract} />
            <ContractOfficeSection isRogatory={false} />
            <ContractGeneralSection editable={editing && editable.allowed} />
            <ContractEconomicSection />
            <ContractSuppliersSection />
            <ContractDocumentSection
              // È editabile quando il contratto è stato salvato
              editable={!editing && editable.allowed}
              allowed={editable.allowed}
              contractId={contract.id}
            />
            <ContractOthersSection editable={editing && editable.allowed} />
            <ContractTaxRecordsSection contract={contract} />
            {currentUser?.getRole().hasPermission('activity.log.read') &&
              contract.id && (
                <ContractActivityLogSection contractId={contract.id} />
              )}
          </PageContent>
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}

import React from 'react';
import { FormikValues } from 'formik';
import { FormItem, SingleFormItemProps } from '../FormItem';
import { TextInput, TextInputProps } from './TextInput';

interface Props<T extends FormikValues> extends TextInputProps<T> {
  itemProps?: SingleFormItemProps<T>;
  label?: React.ReactNode;
}

/**
 * Form Item per input testuale.
 */
export function TextInputItem<T extends FormikValues>(props: Props<T>) {
  const { name, label, itemProps, ...otherProps } = props;

  return (
    <FormItem {...itemProps} name={name} label={label}>
      <TextInput {...otherProps} name={name} />
    </FormItem>
  );
}

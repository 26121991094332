import * as React from 'react';
import { Button, message, Table, Tooltip } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import {
  AuditOutlined,
  CheckCircleOutlined,
  LockOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import { LinkButton } from 'client/ui/link/LinkButton';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { Section } from 'client/ui/section/Section';
import { UserDto } from 'common/dto/generated/UserDto';
import { UserApi } from 'client/components/schema/user/UserApi';
import { UserListQueryDto } from 'common/dto/query/UserListQueryDto';
import { UserListColums } from 'client/components/schema/user/table/UserListColumns';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { UserQueryDto } from 'common/dto/query/UserQueryDto';
import { useEffect, useState } from 'react';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import { SorterResult } from 'antd/lib/table/interface';
import { orderBy } from 'lodash';
import { getFilter } from 'client/ui/table/TableColumnFilter';
import { UserOrderBy } from 'common/schema/user/UserTypes';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';

interface Params {
  filter: string;
}

export interface IUserListPageProps extends RouteComponentProps<Params> {}

export function UserListPage(props: IUserListPageProps) {
  const [query, setQuery] = useState<UserListQueryDto>(
    new UserListQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 10,
        includeDeleted: true
      })
    )
  );

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/users', title: `Utenti` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Titolo',
    menuKey: 'admin'
  });

  const [disable] = useApiMutation(UserApi.softDelete, {
    invalidates: [UserApi.listAll]
  });

  const [restore] = useApiMutation(UserApi.restore, {
    invalidates: [UserApi.listAll]
  });

  const { response, loading, error } = useApiQuery(UserApi.listAll, {
    data: { query }
  });

  const users = response?.data.items;

  const columns = getPaginationUrlColumns<UserDto, UserListQueryDto>(
    UserListColums,
    query
  );

  useEffect(() => {
    setQuery(
      new UserListQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 10,
          includeDeleted: true
        })
      )
    );
  }, [props.location.search]);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei utenti"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeadingRogante
        fixedWidth
        title={<PageHeadingTitle>Elenco Utenti</PageHeadingTitle>}
        breadcrumbRender={() => <BreadcrumbsContainer />}
      />
      <PageContent fixedWidth>
        <Section icon={<AuditOutlined />} title="Elenco Utenti">
          <Table<UserDto>
            loading={loading || !users}
            rowKey="id"
            columns={[
              ...columns,
              {
                title: 'Azioni',
                dataIndex: 'actions',
                width: 100,
                align: 'right',
                render: (_, record: UserDto) => (
                  <>
                    {!record.deletedAt ? (
                      <ButtonDeleteConfirm
                        okText="Disabilita"
                        iconOnlyButton
                        title={"Disabilitare l'utente?"}
                        buttonType="link"
                        buttonDanger
                        size="large"
                        onConfirm={async () => {
                          await disable({ data: record });
                          message.success('Utente disabilitato');
                        }}
                      />
                    ) : (
                      <ButtonDeleteConfirm
                        okText="Ripristina"
                        iconOnlyButton
                        buttonIcon={<CheckCircleOutlined />}
                        title={"Ripristinare l'utente?"}
                        buttonType="link"
                        size="large"
                        onConfirm={async () => {
                          await restore({ data: record });
                          message.success('Utente ripristinato');
                        }}
                      />
                    )}
                  </>
                )
              }
            ]}
            dataSource={users}
            size="middle"
            onChange={(pagination, filters, sorter) => {
              const { field, order } = sorter as SorterResult<UserDto>;
              const name = getFilter(filters, 'name') as string | undefined;

              const userQuery = new UserListQueryDto({
                ...query,
                name,
                pageNumber: pagination.current,
                pageSize: pagination.pageSize,
                orderBy: order
                  ? (field?.toString() as UserOrderBy)
                  : UserOrderBy.id,
                orderByDirection: order
                  ? ColumnSortDirections[order]
                  : ColumnSortDirections.descend
              });

              setPaginationUrlQuery(props, userQuery);
            }}
            pagination={{
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: true,
              total: response?.data.meta.total,
              pageSize: query.pageSize,
              current: query.pageNumber,
              pageSizeOptions: ['5', '10', '20', '30', '40']
            }}
          />
        </Section>
      </PageContent>
    </CorePageLayout>
  );
}

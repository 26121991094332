import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { UserDto } from './UserDto';

/**
 * Rappresentazione DTO della classe Consumer definita in: src/server/schema/contract/consumer/Consumer.entity.ts
 * Hash: 9cfafeb8bfe84cfd0207f2c2643112e8
 */
@ValidationSchema(() => ConsumerSchema)
export class ConsumerDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number, description: 'ID Originale migrazione' })
  migrationId?: number | null | undefined;
  @ApiProperty({ type: String, description: 'Denominazione' })
  name!: string;
  @ApiProperty({ type: String, description: 'Codice Fiscale' })
  fiscalNumber!: string;
  @ApiProperty({ type: String, description: 'Indirizzo' })
  address!: string;
  @ApiProperty({ type: String, description: 'CAP' })
  zip!: string;
  @ApiProperty({ type: String, description: 'Comune' })
  city!: string;
  @ApiProperty({ type: String, description: 'Provincia' })
  province!: string;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Creazione' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Aggiornamento' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data di Eliminazione' })
  deletedAt?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ConsumerDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ConsumerDto ? values : plainToClass(ConsumerDto, values));
    }
  }

  getCityWithProvince(): string {
    return `${this.city ?? '--'} (${this.province ?? '--'})`;
  }

  getFullAddress(): string {
    const cityAndProvince = this.city && this.province
              ? `${this.city} (${this.province})`
              : this.city
              ? this.city
              : this.province
              ? this.province
              : null;
    const addressAndCivic = [this.address].filter(e => e != null).join(' ');
    const cityZipAndCountry = [cityAndProvince, this.zip]
            .filter(e => e != null)
            .join(' ');
    return [addressAndCivic, cityZipAndCountry].join(', ');
  }

  async validate(options?: any) {
    const validated = await ConsumerSchema.validate(classToPlain(this), options);
    return new ConsumerDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ConsumerDto */
export type IConsumerType = IDto<ConsumerDto>;

/**
 * DTO Paginato della classe Consumer
 */
export class PaginatedConsumerDto {
  @ApiProperty({ type: [ConsumerDto] })
  @Type(() => ConsumerDto)
  items!: ConsumerDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ConsumerSchema = yup
  .object({
    id: yup.number(),
    name: yup.string().required().label('Denominazione'),
    fiscalNumber: yup.string().required().label('Codice Fiscale'),
    address: yup.string().required().label('Indirizzo'),
    zip: yup.string().required().label('CAP'),
    city: yup.string().required().label('Comune'),
    province: yup.string().required().label('Provincia')
  })
  .noUnknown()
  .meta({ schemaName: "ConsumerSchema" })
  .required();

type Dto = { __dto: any };

export function isDto(dto: any): dto is Dto {
  // TODO Cambiare assolutamente
  return (
    dto != null &&
    typeof dto === 'object' &&
    typeof dto['validate'] === 'function'
  );
}

type PickDtoKey<K, T> = K extends '__dto'
  ? never
  : K extends 'validate'
  ? never
  : K;

/**
 * Trasforma un DTO e i tipi annidati in Interfacce semplici.
 */
export type IDto<T> = {
  [K in keyof T as PickDtoKey<K, T>]: T[K] extends Dto
    ? IDto<T[K]> | T[K]
    : T[K] extends Dto | null | undefined
    ? IDto<NonNullable<T[K]>> | T[K]
    : T[K] extends Array<Dto>
    ? Array<IDto<ArrayItem<T[K]>>>
    : T[K] extends Array<Dto> | null | undefined
    ? Array<IDto<ArrayItem<NonNullable<T[K]>>>>
    : T[K];
};

export type IDtoPartial<T> = {
  [K in keyof T as T[K] extends Function
    ? never
    : K extends '__dto'
    ? never
    : K]?: T[K] extends Dto
    ? IDtoPartial<T[K]> | undefined
    : T[K] extends Dto | null | undefined
    ? IDtoPartial<NonNullable<T[K]>> | null | undefined
    : T[K] extends Array<Dto>
    ? Array<IDtoPartial<ArrayItem<T[K]>>> | undefined
    : T[K] extends Array<Dto> | null | undefined
    ? Array<IDtoPartial<ArrayItem<NonNullable<T[K]>>>> | undefined
    : T[K] | undefined;
};

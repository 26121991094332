import * as React from 'react';
import { Button, Table } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { AuditOutlined, PlusOutlined } from '@ant-design/icons';
import { LinkButton } from 'client/ui/link/LinkButton';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { Section } from 'client/ui/section/Section';
import { ConsumerColumns } from './table/ConsumerColumns';
import { ConsumerDto } from 'common/dto/generated/ConsumerDto';
import { ConsumerApi } from 'client/components/schema/consumer/ConsumerApi';

interface Params {
  filter: string;
}

export interface ConsumerListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco dei Committenti
 */
export function ConsumerListPage(props: ConsumerListPageProps) {
  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/consumers', title: `Committenti` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Titolo',
    menuKey: 'admin'
  });

  const { response, loading, error } = useApiQuery(ConsumerApi.list, {
    data: {}
  });

  const consumers = response?.data.items;

  const columns = ConsumerColumns;

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei committenti"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeadingRogante
        fixedWidth
        title={<PageHeadingTitle>Elenco Committenti</PageHeadingTitle>}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          <LinkButton
            to="/consumers/create"
            type="primary"
            icon={<PlusOutlined />}
          >
            Nuovo Committente
          </LinkButton>
        }
      />
      <PageContent fixedWidth>
        <Section icon={<AuditOutlined />} title="Elenco Committenti">
          <Table<ConsumerDto>
            loading={loading || !consumers}
            rowKey="id"
            onRow={record => ({
              style: {
                cursor: 'pointer'
              },
              onClick: () => {
                props.history.push(`/consumers/${record.id}`);
              }
            })}
            columns={columns}
            dataSource={consumers}
            size="middle"
            pagination={false}
          />
        </Section>
      </PageContent>
    </CorePageLayout>
  );
}

import * as yup from 'yup';
import { Maybe, AnyObject } from 'yup/lib/types'; // Per maybe, see: https://github.com/TypeStrong/ts-node#help-my-types-are-missing

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > {
    /**
     * Richiede che sia disponibile l'azione specificata per la modifica del campo,
     * altrimenti provvede ad eliminarlo dal DTO (`strip`)
     */
    needsAction(action: string): StringSchema<TType, TContext, TOut>;
  }

  class BaseSchema {
    /**
     * Richiede che sia disponibile l'azione specificata per la modifica del campo,
     * altrimenti provvede ad eliminarlo dal DTO (`strip`)
     */
    needsAction(action: string): BaseSchema;
  }

  interface NumberSchema {
    /**
     * Richiede che sia disponibile l'azione specificata per la modifica del campo,
     * altrimenti provvede ad eliminarlo dal DTO (`strip`)
     */
    needsAction(action: string): NumberSchema;
  }

  interface DateSchema {
    /**
     * Richiede che sia disponibile l'azione specificata per la modifica del campo,
     * altrimenti provvede ad eliminarlo dal DTO (`strip`)
     */
    needsAction(action: string): DateSchema;
  }

  interface BooleanSchema {
    /**
     * Richiede che sia disponibile l'azione specificata per la modifica del campo,
     * altrimenti provvede ad eliminarlo dal DTO (`strip`)
     */
    needsAction(action: string): BooleanSchema;
  }

  interface NotRequiredArraySchema<T> {
    /**
     * Richiede che sia disponibile l'azione specificata per la modifica del campo,
     * altrimenti provvede ad eliminarlo dal DTO (`strip`)
     */
    needsAction(action: string): NotRequiredArraySchema<T>;
  }
}

/**
 * Richiede che sia disponibile l'azione specificata per la modifica del campo,
 * altrimenti provvede ad eliminarlo dal DTO (`strip`)
 */
yup.addMethod(
  yup.mixed,
  'needsAction',
  function (this: yup.BaseSchema<any>, action: string) {
    return this.when(
      ['$machineActions'],
      (machineActions: string[], schema: yup.BaseSchema<any>) => {
        // Se le azioni non sono presenti, vuol dire che siamo in una situazione
        // in cui il context _non è_ definito. Per evitare lo strip involontario
        // di alcuni campi, ritorniamo lo schema as-is.
        if (machineActions == null) return schema;

        return machineActions.includes(action)
          ? schema
          : schema.notRequired().meta({ needsAction: action }).strip();
      }
    );
  }
);

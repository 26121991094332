import {
  EditOutlined,
  EyeOutlined,
  FolderOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Alert, Button, message, Space, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { ContractDocumentApi } from 'client/components/schema/contract/document/ContractDocumentApi';
import { ContractDocumentEditModal } from 'client/components/schema/contract/document/ContractDocumentEditModal';
import { ContractDocumentListColumns } from 'client/components/schema/contract/document/table/ContractDocumentListColumns';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { Section } from 'client/ui/section/Section';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { ContractDocumentDto } from 'common/dto/generated/ContractDocumentDto';
import { ContractDocumentQueryDto } from 'common/dto/query/ContractDocumentQueryDto';
import { DocumentKind } from 'common/schema/contract/contract-document/ContractDocumentTypes';
import React, { useState } from 'react';

export interface IContractDocumentSectionProps {
  editable: boolean;
  allowed: boolean;
  contractId: number | null;
}

export function ContractDocumentSection(props: IContractDocumentSectionProps) {
  const { editable, contractId, allowed } = props;
  const isSaved = contractId != null;

  const baseQuery = {
    pageSize: 10,
    page: 1,
    contractId
  };
  const [query, setQuery] = useState<ContractDocumentQueryDto>(
    new ContractDocumentQueryDto(baseQuery)
  );

  const [current, setCurrent] = useState<ContractDocumentDto | null>(null);

  const { response, loading, error } = useApiQuery(ContractDocumentApi.list, {
    data: { params: query },
    skip: !isSaved
  });

  const documents = response?.data;

  const [remove, removeHelpers] = useApiMutation(ContractDocumentApi.remove, {
    invalidates: [ContractDocumentApi.list, ContractApi.find]
  });

  const columns: ColumnProps<ContractDocumentDto>[] = [
    ...ContractDocumentListColumns,
    {
      width: 130,
      align: 'right',
      render: (_: any, document: ContractDocumentDto) => {
        return (
          <Space direction="horizontal" size="small">
            <Button
              onClick={() => {
                setCurrent(document);
              }}
              icon={editable && allowed ? <EditOutlined /> : <EyeOutlined />}
              type="link"
              size="small"
            >
              {editable && allowed ? 'Modifica' : 'Dettaglio'}
            </Button>

            {editable && allowed && (
              <ButtonDeleteConfirm
                title={'Eliminare il documento?'}
                iconOnlyButton
                onConfirm={async () => {
                  await remove({ data: document });
                  message.success('Documento rimosso');
                }}
                buttonDanger
                buttonType="link"
                size="small"
              />
            )}
          </Space>
        );
      }
    }
  ];

  return (
    <Section
      icon={<FolderOutlined />}
      title="Documenti e allegati"
      extra={
        allowed &&
        isSaved && (
          <Tooltip
            title={
              !editable
                ? 'È necessario salvare per aggiungere nuovi documenti'
                : ''
            }
          >
            <Button
              disabled={!editable}
              icon={<PlusOutlined />}
              type="primary"
              size="small"
              onClick={e => {
                setCurrent(
                  new ContractDocumentDto({ kind: DocumentKind.Principal })
                );
                e.stopPropagation();
              }}
            >
              Allega Documento
            </Button>
          </Tooltip>
        )
      }
    >
      {!isSaved && (
        <Alert
          type="info"
          showIcon
          message="Contratto non salvato"
          description="Una volta che il contratto sarà salvato, sarà possibile allegare documenti e atti ad esso."
        />
      )}
      {isSaved && (
        <Table<ContractDocumentDto>
          loading={removeHelpers.loading || loading}
          rowKey="id"
          columns={columns}
          dataSource={documents?.items ?? []}
          size="middle"
          onChange={(pagination, filters, sorter) => {
            const { field, order } =
              sorter as SorterResult<ContractDocumentDto>;

            const newQuery = new ContractDocumentQueryDto({
              ...query,
              page: pagination.current || query.page,
              pageSize: pagination.pageSize || query.pageSize,
              orderBy: order ? field?.toString() : 'createdAt',
              orderByDirection: order
                ? ColumnSortDirections[order]
                : ColumnSortDirections.descend
            });

            setQuery(newQuery);
          }}
          pagination={{
            hideOnSinglePage: true,
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSize: query.pageSize,
            current: query.page,
            total: documents?.meta.total,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      )}
      {isSaved && (
        <ContractDocumentEditModal
          documents={documents?.items}
          contractId={contractId}
          editable={editable && allowed}
          current={current}
          setCurrent={setCurrent}
        />
      )}
    </Section>
  );
}

import React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { sortBy } from 'lodash';

interface IBreadcrumbItem {
  /** Il path a cui punta il link dell'elemento del BC */
  path?: string;
  /** Viene utilizzato al posto di title per confrontare se è cambiato */
  key?: string;
  /** Il titolo dell'elemento del BC */
  title: React.ReactNode;
  /** se si vuole un highlight su una voce specifica del menu, diversa da quella di default (calcolata sull'URL). */
  menuKey?: string;
  sideMenuKey?: string;
  /* Permette di gestire l'ordinamento degli item. Di default è uguale alla
   * lunghezza del path. Maggior priority, più sarà a dx l'item
   */
  priority?: number;
}

interface IBreadcrumbContext {
  items: IBreadcrumbItem[];
  setItems: (update: (items: IBreadcrumbItem[]) => IBreadcrumbItem[]) => void;
}

export const BreadcrumbContext = createContext<IBreadcrumbContext>({
  items: [],
  setItems: (() => {}) as any
});

export function BreadcrumbProvider(props: React.PropsWithChildren<{}>) {
  const [items, setItems] = useState([] as IBreadcrumbItem[]);

  return (
    <BreadcrumbContext.Provider value={{ items, setItems }}>
      {props.children}
    </BreadcrumbContext.Provider>
  );
}

function areItemsEqual(a: IBreadcrumbItem, b: IBreadcrumbItem) {
  const aKey = a.key == null ? a.title : a.key;
  const bKey = b.key == null ? b.title : b.key;
  if (aKey !== bKey) return false;
  if (a.path !== b.path) return false;
  return true;
}

function getKeyFromPath(path: string | undefined) {
  if (!path) return [];
  const pathList = path.split('/');
  const mainPath = pathList[1];
  const secondaryPath = pathList[2];

  const selectedKeys =
    pathList.length > 2 && mainPath === 'admin'
      ? [mainPath + '/' + secondaryPath]
      : [mainPath];

  return selectedKeys;
}

/**
 * Registra un elemento di navigazione nei breadcrumb.
 */
export const useBreadcrumbItem = (item: IBreadcrumbItem) => {
  const ctx = useContext(BreadcrumbContext);
  const compareKey = item.key == null ? item.title : item.key;

  useEffect(() => {
    // console.log('pushing', item);
    if (!item.priority) {
      item.priority = item.path ? item.path.split('/').length : 0;
    }

    ctx.setItems(items => {
      return sortBy([...items, item], i => i.priority);
    });

    // Cleanup
    return () => {
      ctx.setItems(items => {
        return items.filter(i => {
          return !areItemsEqual(i, item);
        });
      });
    };
  }, [item.path, compareKey, item.menuKey, item.sideMenuKey]);
};

export const useBreadcrumbs = () => {
  const ctx = useContext(BreadcrumbContext);
  return ctx.items;
};

// TODO/LOW cercare di semplificare il codice
/**
 * Restituisce l'indice dell'ultimo elemento nel BC che presenta una menuKey
 */
export const getMenuKeyIndex = (
  ctx: IBreadcrumbContext,
  fn: (item: IBreadcrumbItem) => boolean
): number => {
  const lastIndex = ctx.items.slice().reverse().findIndex(fn);

  return ctx.items.length - 1 - lastIndex;
};

export const useCurrentMenuKey = () => {
  // Prendo il contesto
  const ctx = useContext(BreadcrumbContext);
  // Se il contesto non contiene items, non selezionare nessuna key
  if (!ctx.items || ctx.items.length === 0) return [];
  const menuKeyIndex = getMenuKeyIndex(ctx, item => !!item.menuKey);

  // Se c'è un elemento che presenta una menuKey, ritornala
  // (Nel caso più item con menuKey, ritorna quella dell'item più a dx nel BC)
  // Se non c'è nessuna menuKey fornita, estrai quella di default dal path.
  return menuKeyIndex >= 0 && ctx.items[menuKeyIndex]?.menuKey
    ? [ctx.items[menuKeyIndex].menuKey!]
    : getKeyFromPath(ctx.items.slice(-1)[0].path);
};

export const useCurrentSideMenuKey = () => {
  // Prendo il contesto
  const ctx = useContext(BreadcrumbContext);

  // Se il contesto non contiene items, non selezionare nessuna key
  if (!ctx.items || ctx.items.length === 0) return [];

  const sideMenuKeyIndex = getMenuKeyIndex(ctx, item => !!item.sideMenuKey);

  // Se c'è un elemento che presenta una menuKey, ritornala
  // (Nel caso più item con menuKey, ritorna quella dell'item più a dx nel BC)
  // Se non c'è nessuna menuKey fornita, estrai quella di default dal path.
  return sideMenuKeyIndex >= 0 && ctx.items[sideMenuKeyIndex]?.sideMenuKey
    ? [ctx.items[sideMenuKeyIndex].sideMenuKey!]
    : getKeyFromPath(ctx.items.slice(-1)[0].path);
};

import * as React from 'react';
import { Button } from 'antd';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonProps } from 'antd/lib/button/button';

export interface LinkButtonProps extends ButtonProps {
  to: LinkProps['to'];
  linkProps?: Omit<LinkProps, 'to'>;
}

/**
 * Button con funzionalità del Link di react-router-dom
 */
export function LinkButton(props: LinkButtonProps) {
  const { to, linkProps, ...buttonProps } = props;
  return (
    <Link to={to} {...linkProps}>
      <Button {...buttonProps} />
    </Link>
  );
}

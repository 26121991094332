import { apiClient } from 'client/core/network/apiClient';
import {
  PaginatedTaxExportDto,
  TaxExportDto
} from 'common/dto/generated/TaxExportDto';
import { TaxExportCreateInputDto } from 'common/dto/query/TaxExportCreateInputDto';
import { TaxExportQueryDto } from 'common/dto/query/TaxExportQueryDto';

export const TaxExportApi = {
  create: (opts: { input: TaxExportCreateInputDto }) =>
    apiClient
      .requestWithResponseType(TaxExportDto)
      .post(`/tax-exports`, opts.input),

  find: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(TaxExportDto)
      .get(`/tax-exports/${opts.id}`),

  list: (opts: { query: TaxExportQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedTaxExportDto)
      .get(`/tax-exports`, {
        params: opts.query
      })
};

import { UserStateTag } from 'client/routes/contract/user/UserStateTag';
import { createTypedColumns } from 'client/ui/table/createTypedColumns';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { UserDto } from 'common/dto/generated/UserDto';
import React from 'react';
import { UserRoleTag } from '../view/UserRoleTag';

export const UserColumns = createTypedColumns(UserDto, {
  id: {
    title: 'Id',
    dataIndex: 'id'
  },
  login: {
    title: 'Login',
    dataIndex: 'login'
  },
  name: {
    title: 'Nome',
    dataIndex: 'name',
    sorter: true,
    ...createColumnFilter(FilterInputType.Text)
  },
  role: {
    title: 'Ruolo',
    dataIndex: 'role',
    render: (_: any, record: UserDto) => {
      return <UserRoleTag role={record.role} />;
    }
  },
  state: {
    title: 'Stato',
    dataIndex: 'state',
    render: (_: any, record: UserDto) => {
      const disabled = record.deletedAt != null;
      return <UserStateTag disabled={disabled} />;
    }
  }
});

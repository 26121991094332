import { ArrowRightOutlined } from '@ant-design/icons';
import { Tag, Typography } from 'antd';
const { Paragraph } = Typography;
import { ColumnProps } from 'antd/lib/table';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { ISODate } from 'client/ui/display/date/ISODate';
import { TaxRecordDto } from 'common/dto/generated/TaxRecordDto';
import React from 'react';
import { Link } from 'react-router-dom';
import { TaxRecordStateTag } from '../../TaxRecordStateTag';

export const TaxRecordColumns: ColumnProps<TaxRecordDto>[] = [
  {
    title: 'Anno',
    dataIndex: 'paymentYear',
    width: 75,
    sorter: true,
    // In questo modo previene di impostare la colonna a undefined.
    // Avendo un default nel dto, si crea un problema quando viene
    // impostato a undefined
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Stato',
    dataIndex: 'state',
    key: 'state',
    width: 110,
    render: (_, record) => <TaxRecordStateTag state={record.state} />
  },
  {
    title: 'Contratto',
    dataIndex: 'contractId',
    width: 105,
    render: (_, record) => (
      <div onClick={e => e.stopPropagation()}>
        <Link to={`/contracts/${record.contractId}`}>
          #{record.contractId} <ArrowRightOutlined />
        </Link>
      </div>
    )
  },
  {
    title: 'Committente',
    dataIndex: 'consumerId',
    width: 115,
    render: (_, record) => record.consumer?.name
  },
  {
    title: 'Contraente',
    dataIndex: 'supplierId',
    render: (_, record) => record.supplier?.getName()
  },
  {
    title: 'Importo Corrisposto',
    dataIndex: 'paymentAmount',
    width: 160,
    align: 'right',
    render: (_, record) => (
      <CurrencyRender currency="EUR" value={record.paymentAmount} />
    )
  }
];

import { message, Space } from 'antd';
import { useUserAuthorities } from 'client/components/schema/user/hooks/useUserAuthorities';
import { UserApi } from 'client/components/schema/user/UserApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { UserAuthoritiesDto } from 'common/dto/documental/UserAuthoritiesDto';
import {
  UserAuthoritiesSelectDto,
  UserAuthoritiesSelectSchema
} from 'common/dto/documental/UserAuthoritiesSelectDto';
import { Formik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { AuthActions, useCurrentUser } from '../AuthModule';

export interface IAuthRoleSwitcherProps {}

/**
 * Componente che permette la selezione del Ruolo e dell'Ufficio dell'utente
 * corrente, in base ai ruoli e agli uffici disponibili sul documentale.
 */
export function AuthRoleSwitcher(props: IAuthRoleSwitcherProps) {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const { response, loading, error } = useUserAuthorities(currentUser);

  const [setAuthorities] = useApiMutation(
    UserApi.documental.authorities.set,
    {}
  );

  const profile = response?.data;

  if (!currentUser) return null;

  return (
    <div style={{ width: '400px' }}>
      <FormikAugmented<UserAuthoritiesSelectDto>
        validationSchema={UserAuthoritiesSelectSchema}
        validationContext={{}}
        initialValues={
          new UserAuthoritiesSelectDto({
            roleId: currentUser.documentalRoleId,
            officeId: currentUser.documentalOfficeId
          })
        }
        onSubmit={async values => {
          try {
            const input = await values.validate();
            await setAuthorities({ data: { input } });
            dispatch(AuthActions.reloadUser());
            // message.success('Ruolo e ufficio selezionati correttamente.');
          } catch (e: any) {
            message.error(
              `Si è verificato un errore durante la selezione del ruolo.`
            );
          }
        }}
      >
        <FormikForm
          disabled={loading}
          editable
          helpInTooltips
          requiredMark={false}
        >
          <FormFieldsContainer>
            <FormFieldItem
              component={SelectInput}
              labelWidth="width-70"
              name="roleId"
              label="Ruolo"
              loading={loading}
              options={profile?.roles.map(role => ({
                label: role.description,
                value: role.id
              }))}
            />
            <FormFieldItem
              component={SelectInput}
              labelWidth="width-70"
              name="officeId"
              label="Ufficio"
              loading={loading}
              options={profile?.offices.map(office => ({
                label: `${office.code} - ${office.description}`,
                value: office.id
              }))}
            />
            <Space direction="vertical" style={{ width: '100%' }} align="end">
              <FormikSendButton disabledIfPristine>Seleziona</FormikSendButton>
            </Space>
          </FormFieldsContainer>
        </FormikForm>
      </FormikAugmented>
    </div>
  );
}

import * as React from 'react';
import clx from 'classnames';
import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { SpinProps } from 'antd/lib/spin/index';
import './LoaderSpin.scss';

export interface LoaderSpinProps extends SpinProps {
  full?: boolean;
  transparent?: boolean;
  hasScroll?: boolean;
  children?: React.ReactNode;
}

/**
 * Spinning Loader.
 */
export function LoaderSpin(props: LoaderSpinProps) {
  const { full, transparent, hasScroll, ...otherProps } = props;

  return (
    <div
      className={clx('loader-spin', {
        'full-height': full,
        'no-background': transparent,
        'spin-contain-scroller': hasScroll
      })}
    >
      <Spin
        size="large"
        {...otherProps}
        indicator={<Loading3QuartersOutlined spin />}
      />
    </div>
  );
}

import { Button } from 'antd';
import { useFormikContext } from 'formik';
import React from 'react';

export interface IFormikCancelModalProps {
  onClick?: () => void;
  children?: React.ReactNode;
}

/**
 * Annulla il formik e chiude il modal.
 */
export function FormikCancelModal(props: IFormikCancelModalProps) {
  const { onClick, children } = props;
  const formik = useFormikContext();

  const handleCancel = () => {
    onClick?.();
    formik.resetForm();
  };

  return <Button onClick={handleCancel}>{children}</Button>;
}

import React from 'react';
import clx from 'classnames';
import './SectionExtraFieldContainer.scss';

export interface SectionExtraFieldContainerProps {
  children: React.ReactNode;
  className?: string;
  width?: string;
}

/**
 * Contenitore per FormFieldItem come extra di una sezione (Panel collapsable)
 * Esempio utilizzo...
 * <Section extra={<SectionExtraFieldContainer ... /> ... />
 */
export function SectionExtraFieldContainer(
  props: SectionExtraFieldContainerProps
) {
  const { className, children, width } = props;

  return (
    <div
      style={width ? { width: width } : {}}
      className={clx('section-extra-field-container', className)}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  );
}

import { Empty } from 'antd';
import { ContractDto } from 'common/dto/generated/ContractDto';
import React from 'react';

export interface IPreviewTransfer {
  contracts: ContractDto[];
  total: number;
}

export interface IPreviewContractListToTransferProps {
  previewTransfer: IPreviewTransfer | null;
}

/**
 * Crea l'anteprima degli ultimi 10 contratti pronti al trasferimento.
 */
export function PreviewContractListToTransfer(
  props: IPreviewContractListToTransferProps
) {
  const { previewTransfer } = props;
  if (!previewTransfer) return null;
  const { contracts, total } = previewTransfer;

  const contractPreview =
    contracts.length > 0 ? (
      // Elenco puntato con un'anteprima degli ultimi contratti
      <>
        Anteprima {Math.min(10, contracts.length)} di {total} contratti pronti
        al trasferimento:
        <ul>
          {contracts.map(contract => (
            <li key={contract.id}>
              {contract.id} - {contract.subject}
            </li>
          ))}
        </ul>
      </>
    ) : (
      <Empty description="Nessun contratto trovato" />
    );

  return contractPreview;
}

export const CountryCodes = [
  {
    id: 1,
    code: 'AD',
    name: 'Andorra'
  },
  {
    id: 2,
    code: 'AE',
    name: 'Emirati Arabi Uniti'
  },
  {
    id: 3,
    code: 'AF',
    name: 'Afghanistan'
  },
  {
    id: 4,
    code: 'AG',
    name: 'Antigua e Barbuda'
  },
  {
    id: 5,
    code: 'AI',
    name: 'Anguilla'
  },
  {
    id: 6,
    code: 'AL',
    name: 'Albania'
  },
  {
    id: 7,
    code: 'AM',
    name: 'Armenia'
  },
  {
    id: 8,
    code: 'AO',
    name: 'Angola'
  },
  {
    id: 9,
    code: 'AQ',
    name: 'Antartide'
  },
  {
    id: 10,
    code: 'AR',
    name: 'Argentina'
  },
  {
    id: 11,
    code: 'AS',
    name: 'Samoa Americane'
  },
  {
    id: 12,
    code: 'AT',
    name: 'Austria'
  },
  {
    id: 13,
    code: 'AU',
    name: 'Australia'
  },
  {
    id: 14,
    code: 'AW',
    name: 'Aruba'
  },
  {
    id: 15,
    code: 'AX',
    name: 'Isole Åland'
  },
  {
    id: 16,
    code: 'AZ',
    name: 'Azerbaigian'
  },
  {
    id: 17,
    code: 'BA',
    name: 'Bosnia ed Erzegovina'
  },
  {
    id: 18,
    code: 'BB',
    name: 'Barbados'
  },
  {
    id: 19,
    code: 'BD',
    name: 'Bangladesh'
  },
  {
    id: 20,
    code: 'BE',
    name: 'Belgio'
  },
  {
    id: 21,
    code: 'BF',
    name: 'Burkina Faso'
  },
  {
    id: 22,
    code: 'BG',
    name: 'Bulgaria'
  },
  {
    id: 23,
    code: 'BH',
    name: 'Bahrein'
  },
  {
    id: 24,
    code: 'BI',
    name: 'Burundi'
  },
  {
    id: 25,
    code: 'BJ',
    name: 'Benin'
  },
  {
    id: 26,
    code: 'BL',
    name: 'Saint-Barthélemy'
  },
  {
    id: 27,
    code: 'BM',
    name: 'Bermuda'
  },
  {
    id: 28,
    code: 'BN',
    name: 'Brunei'
  },
  {
    id: 29,
    code: 'BO',
    name: 'Bolivia'
  },
  {
    id: 30,
    code: 'BQ',
    name: 'Isole BES'
  },
  {
    id: 31,
    code: 'BR',
    name: 'Brasile'
  },
  {
    id: 32,
    code: 'BS',
    name: 'Bahamas'
  },
  {
    id: 33,
    code: 'BT',
    name: 'Bhutan'
  },
  {
    id: 34,
    code: 'BV',
    name: 'Isola Bouvet'
  },
  {
    id: 35,
    code: 'BW',
    name: 'Botswana'
  },
  {
    id: 36,
    code: 'BY',
    name: 'Bielorussia'
  },
  {
    id: 37,
    code: 'BZ',
    name: 'Belize'
  },
  {
    id: 38,
    code: 'CA',
    name: 'Canada'
  },
  {
    id: 39,
    code: 'CC',
    name: 'Isole Cocos e Keeling'
  },
  {
    id: 40,
    code: 'CD',
    name: 'Repubblica Democratica del Congo'
  },
  {
    id: 41,
    code: 'CF',
    name: 'Repubblica Centrafricana'
  },
  {
    id: 42,
    code: 'CG',
    name: 'Repubblica del Congo'
  },
  {
    id: 43,
    code: 'CH',
    name: 'Svizzera'
  },
  {
    id: 44,
    code: 'CI',
    name: "Costa d'Avorio"
  },
  {
    id: 45,
    code: 'CK',
    name: 'Isole Cook'
  },
  {
    id: 46,
    code: 'CL',
    name: 'Cile'
  },
  {
    id: 47,
    code: 'CM',
    name: 'Camerun'
  },
  {
    id: 48,
    code: 'CN',
    name: 'Cina'
  },
  {
    id: 49,
    code: 'CO',
    name: 'Colombia'
  },
  {
    id: 50,
    code: 'CR',
    name: 'Costa Rica'
  },
  {
    id: 51,
    code: 'CU',
    name: 'Cuba'
  },
  {
    id: 52,
    code: 'CV',
    name: 'Capo Verde'
  },
  {
    id: 53,
    code: 'CW',
    name: 'Curaçao'
  },
  {
    id: 54,
    code: 'CX',
    name: 'Isola del Natale'
  },
  {
    id: 55,
    code: 'CY',
    name: 'Cipro'
  },
  {
    id: 56,
    code: 'CZ',
    name: 'Repubblica Ceca'
  },
  {
    id: 57,
    code: 'DE',
    name: 'Germania'
  },
  {
    id: 58,
    code: 'DJ',
    name: 'Gibuti'
  },
  {
    id: 59,
    code: 'DK',
    name: 'Danimarca'
  },
  {
    id: 60,
    code: 'DM',
    name: 'Dominica'
  },
  {
    id: 61,
    code: 'DO',
    name: 'Repubblica Dominicana'
  },
  {
    id: 62,
    code: 'DZ',
    name: 'Algeria'
  },
  {
    id: 63,
    code: 'EC',
    name: 'Ecuador'
  },
  {
    id: 64,
    code: 'EE',
    name: 'Estonia'
  },
  {
    id: 65,
    code: 'EG',
    name: 'Egitto'
  },
  {
    id: 66,
    code: 'EH',
    name: 'Sahara Occidentale'
  },
  {
    id: 67,
    code: 'ER',
    name: 'Eritrea'
  },
  {
    id: 68,
    code: 'ES',
    name: 'Spagna'
  },
  {
    id: 69,
    code: 'ET',
    name: 'Etiopia'
  },
  {
    id: 70,
    code: 'FI',
    name: 'Finlandia'
  },
  {
    id: 71,
    code: 'FJ',
    name: 'Figi'
  },
  {
    id: 72,
    code: 'FK',
    name: 'Isole Falkland'
  },
  {
    id: 73,
    code: 'FM',
    name: 'Stati Federati di Micronesia'
  },
  {
    id: 74,
    code: 'FO',
    name: 'Isole Fær Øer'
  },
  {
    id: 75,
    code: 'FR',
    name: 'Francia'
  },
  {
    id: 76,
    code: 'GA',
    name: 'Gabon'
  },
  {
    id: 77,
    code: 'GB',
    name: 'Regno Unito'
  },
  {
    id: 78,
    code: 'GD',
    name: 'Grenada'
  },
  {
    id: 79,
    code: 'GE',
    name: 'Georgia'
  },
  {
    id: 80,
    code: 'GF',
    name: 'Guyana francese'
  },
  {
    id: 81,
    code: 'GG',
    name: 'Guernsey'
  },
  {
    id: 82,
    code: 'GH',
    name: 'Ghana'
  },
  {
    id: 83,
    code: 'GI',
    name: 'Gibilterra'
  },
  {
    id: 84,
    code: 'GL',
    name: 'Groenlandia'
  },
  {
    id: 85,
    code: 'GM',
    name: 'Gambia'
  },
  {
    id: 86,
    code: 'GN',
    name: 'Guinea'
  },
  {
    id: 87,
    code: 'GP',
    name: 'Guadalupa'
  },
  {
    id: 88,
    code: 'GQ',
    name: 'Guinea Equatoriale'
  },
  {
    id: 89,
    code: 'GR',
    name: 'Grecia'
  },
  {
    id: 90,
    code: 'GS',
    name: 'Georgia del Sud e isole Sandwich meridionali'
  },
  {
    id: 91,
    code: 'GT',
    name: 'Guatemala'
  },
  {
    id: 92,
    code: 'GU',
    name: 'Guam'
  },
  {
    id: 93,
    code: 'GW',
    name: 'Guinea-Bissau'
  },
  {
    id: 94,
    code: 'GY',
    name: 'Guyana'
  },
  {
    id: 95,
    code: 'HK',
    name: 'Hong Kong'
  },
  {
    id: 96,
    code: 'HM',
    name: 'Isole Heard e McDonald'
  },
  {
    id: 97,
    code: 'HN',
    name: 'Honduras'
  },
  {
    id: 98,
    code: 'HR',
    name: 'Croazia'
  },
  {
    id: 99,
    code: 'HT',
    name: 'Haiti'
  },
  {
    id: 100,
    code: 'HU',
    name: 'Ungheria'
  },
  {
    id: 101,
    code: 'ID',
    name: 'Indonesia'
  },
  {
    id: 102,
    code: 'IE',
    name: 'Irlanda'
  },
  {
    id: 103,
    code: 'IL',
    name: 'Israele'
  },
  {
    id: 104,
    code: 'IM',
    name: 'Isola di Man'
  },
  {
    id: 105,
    code: 'IN',
    name: 'India'
  },
  {
    id: 106,
    code: 'IO',
    name: "Territori Britannici dell'Oceano Indiano"
  },
  {
    id: 107,
    code: 'IQ',
    name: 'Iraq'
  },
  {
    id: 108,
    code: 'IR',
    name: 'Iran'
  },
  {
    id: 109,
    code: 'IS',
    name: 'Islanda'
  },
  {
    id: 110,
    code: 'IT',
    name: 'Italia'
  },
  {
    id: 111,
    code: 'JE',
    name: 'Jersey'
  },
  {
    id: 112,
    code: 'JM',
    name: 'Giamaica'
  },
  {
    id: 113,
    code: 'JO',
    name: 'Giordania'
  },
  {
    id: 114,
    code: 'JP',
    name: 'Giappone'
  },
  {
    id: 115,
    code: 'KE',
    name: 'Kenya'
  },
  {
    id: 116,
    code: 'KG',
    name: 'Kirghizistan'
  },
  {
    id: 117,
    code: 'KH',
    name: 'Cambogia'
  },
  {
    id: 118,
    code: 'KI',
    name: 'Kiribati'
  },
  {
    id: 119,
    code: 'KM',
    name: 'Comore'
  },
  {
    id: 120,
    code: 'KN',
    name: 'Saint Kitts e Nevis'
  },
  {
    id: 121,
    code: 'KP',
    name: 'Corea del Nord'
  },
  {
    id: 122,
    code: 'KR',
    name: 'Corea del Sud'
  },
  {
    id: 123,
    code: 'KW',
    name: 'Kuwait'
  },
  {
    id: 124,
    code: 'KY',
    name: 'Isole Cayman'
  },
  {
    id: 125,
    code: 'KZ',
    name: 'Kazakistan'
  },
  {
    id: 126,
    code: 'LA',
    name: 'Laos'
  },
  {
    id: 127,
    code: 'LB',
    name: 'Libano'
  },
  {
    id: 128,
    code: 'LC',
    name: 'Santa Lucia'
  },
  {
    id: 129,
    code: 'LI',
    name: 'Liechtenstein'
  },
  {
    id: 130,
    code: 'LK',
    name: 'Sri Lanka'
  },
  {
    id: 131,
    code: 'LR',
    name: 'Liberia'
  },
  {
    id: 132,
    code: 'LS',
    name: 'Lesotho'
  },
  {
    id: 133,
    code: 'LT',
    name: 'Lituania'
  },
  {
    id: 134,
    code: 'LU',
    name: 'Lussemburgo'
  },
  {
    id: 135,
    code: 'LV',
    name: 'Lettonia'
  },
  {
    id: 136,
    code: 'LY',
    name: 'Libia'
  },
  {
    id: 137,
    code: 'MA',
    name: 'Marocco'
  },
  {
    id: 138,
    code: 'MC',
    name: 'Monaco'
  },
  {
    id: 139,
    code: 'MD',
    name: 'Moldavia'
  },
  {
    id: 140,
    code: 'ME',
    name: 'Montenegro'
  },
  {
    id: 141,
    code: 'MF',
    name: 'Saint-Martin'
  },
  {
    id: 142,
    code: 'MG',
    name: 'Madagascar'
  },
  {
    id: 143,
    code: 'MH',
    name: 'Isole Marshall'
  },
  {
    id: 144,
    code: 'MK',
    name: 'Macedonia del Nord'
  },
  {
    id: 145,
    code: 'ML',
    name: 'Mali'
  },
  {
    id: 146,
    code: 'MM',
    name: 'Birmania'
  },
  {
    id: 147,
    code: 'MN',
    name: 'Mongolia'
  },
  {
    id: 148,
    code: 'MO',
    name: 'Macao'
  },
  {
    id: 149,
    code: 'MP',
    name: 'Isole Marianne Settentrionali'
  },
  {
    id: 150,
    code: 'MQ',
    name: 'Martinica'
  },
  {
    id: 151,
    code: 'MR',
    name: 'Mauritania'
  },
  {
    id: 152,
    code: 'MS',
    name: 'Montserrat'
  },
  {
    id: 153,
    code: 'MT',
    name: 'Malta'
  },
  {
    id: 154,
    code: 'MU',
    name: 'Mauritius'
  },
  {
    id: 155,
    code: 'MV',
    name: 'Maldive'
  },
  {
    id: 156,
    code: 'MW',
    name: 'Malawi'
  },
  {
    id: 157,
    code: 'MX',
    name: 'Messico'
  },
  {
    id: 158,
    code: 'MY',
    name: 'Malaysia'
  },
  {
    id: 159,
    code: 'MZ',
    name: 'Mozambico'
  },
  {
    id: 160,
    code: 'NA',
    name: 'Namibia'
  },
  {
    id: 161,
    code: 'NC',
    name: 'Nuova Caledonia'
  },
  {
    id: 162,
    code: 'NE',
    name: 'Niger'
  },
  {
    id: 163,
    code: 'NF',
    name: 'Isola Norfolk'
  },
  {
    id: 164,
    code: 'NG',
    name: 'Nigeria'
  },
  {
    id: 165,
    code: 'NI',
    name: 'Nicaragua'
  },
  {
    id: 166,
    code: 'NL',
    name: 'Paesi Bassi'
  },
  {
    id: 167,
    code: 'NO',
    name: 'Norvegia'
  },
  {
    id: 168,
    code: 'NP',
    name: 'Nepal'
  },
  {
    id: 169,
    code: 'NR',
    name: 'Nauru'
  },
  {
    id: 170,
    code: 'NU',
    name: 'Niue'
  },
  {
    id: 171,
    code: 'NZ',
    name: 'Nuova Zelanda'
  },
  {
    id: 172,
    code: 'OM',
    name: 'Oman'
  },
  {
    id: 173,
    code: 'PA',
    name: 'Panama'
  },
  {
    id: 174,
    code: 'PE',
    name: 'Perù'
  },
  {
    id: 175,
    code: 'PF',
    name: 'Polinesia Francese'
  },
  {
    id: 176,
    code: 'PG',
    name: 'Papua Nuova Guinea'
  },
  {
    id: 177,
    code: 'PH',
    name: 'Filippine'
  },
  {
    id: 178,
    code: 'PK',
    name: 'Pakistan'
  },
  {
    id: 179,
    code: 'PL',
    name: 'Polonia'
  },
  {
    id: 180,
    code: 'PM',
    name: 'Saint-Pierre e Miquelon'
  },
  {
    id: 181,
    code: 'PN',
    name: 'Isole Pitcairn'
  },
  {
    id: 182,
    code: 'PR',
    name: 'Porto Rico'
  },
  {
    id: 183,
    code: 'PS',
    name: 'Stato di Palestina'
  },
  {
    id: 184,
    code: 'PT',
    name: 'Portogallo'
  },
  {
    id: 185,
    code: 'PW',
    name: 'Palau'
  },
  {
    id: 186,
    code: 'PY',
    name: 'Paraguay'
  },
  {
    id: 187,
    code: 'QA',
    name: 'Qatar'
  },
  {
    id: 188,
    code: 'RE',
    name: 'Riunione'
  },
  {
    id: 189,
    code: 'RO',
    name: 'Romania'
  },
  {
    id: 190,
    code: 'RS',
    name: 'Serbia'
  },
  {
    id: 191,
    code: 'RU',
    name: 'Russia'
  },
  {
    id: 192,
    code: 'RW',
    name: 'Ruanda'
  },
  {
    id: 193,
    code: 'SA',
    name: 'Arabia Saudita'
  },
  {
    id: 194,
    code: 'SB',
    name: 'Isole Salomone'
  },
  {
    id: 195,
    code: 'SC',
    name: 'Seychelles'
  },
  {
    id: 196,
    code: 'SD',
    name: 'Sudan'
  },
  {
    id: 197,
    code: 'SE',
    name: 'Svezia'
  },
  {
    id: 198,
    code: 'SG',
    name: 'Singapore'
  },
  {
    id: 199,
    code: 'SH',
    name: "Sant'Elena, Isola di Ascensione e Tristan da Cunha"
  },
  {
    id: 200,
    code: 'SI',
    name: 'Slovenia'
  },
  {
    id: 201,
    code: 'SJ',
    name: 'Svalbard e Jan Mayen'
  },
  {
    id: 202,
    code: 'SK',
    name: 'Slovacchia'
  },
  {
    id: 203,
    code: 'SL',
    name: 'Sierra Leone'
  },
  {
    id: 204,
    code: 'SM',
    name: 'San Marino'
  },
  {
    id: 205,
    code: 'SN',
    name: 'Senegal'
  },
  {
    id: 206,
    code: 'SO',
    name: 'Somalia'
  },
  {
    id: 207,
    code: 'SR',
    name: 'Suriname'
  },
  {
    id: 208,
    code: 'SS',
    name: 'Sudan del Sud'
  },
  {
    id: 209,
    code: 'ST',
    name: 'São Tomé e Príncipe'
  },
  {
    id: 210,
    code: 'SV',
    name: 'El Salvador'
  },
  {
    id: 211,
    code: 'SX',
    name: 'Sint Maarten'
  },
  {
    id: 212,
    code: 'SY',
    name: 'Siria'
  },
  {
    id: 213,
    code: 'SZ',
    name: 'Swaziland'
  },
  {
    id: 214,
    code: 'TC',
    name: 'Isole Turks e Caicos'
  },
  {
    id: 215,
    code: 'TD',
    name: 'Ciad'
  },
  {
    id: 216,
    code: 'TF',
    name: 'Territori Francesi del Sud'
  },
  {
    id: 217,
    code: 'TG',
    name: 'Togo'
  },
  {
    id: 218,
    code: 'TH',
    name: 'Thailandia'
  },
  {
    id: 219,
    code: 'TJ',
    name: 'Tagikistan'
  },
  {
    id: 220,
    code: 'TK',
    name: 'Tokelau'
  },
  {
    id: 221,
    code: 'TL',
    name: 'Timor Est'
  },
  {
    id: 222,
    code: 'TM',
    name: 'Turkmenistan'
  },
  {
    id: 223,
    code: 'TN',
    name: 'Tunisia'
  },
  {
    id: 224,
    code: 'TO',
    name: 'Tonga'
  },
  {
    id: 225,
    code: 'TR',
    name: 'Turchia'
  },
  {
    id: 226,
    code: 'TT',
    name: 'Trinidad e Tobago'
  },
  {
    id: 227,
    code: 'TV',
    name: 'Tuvalu'
  },
  {
    id: 228,
    code: 'TW',
    name: 'Repubblica di Cina'
  },
  {
    id: 229,
    code: 'TZ',
    name: 'Tanzania'
  },
  {
    id: 230,
    code: 'UA',
    name: 'Ucraina'
  },
  {
    id: 231,
    code: 'UG',
    name: 'Uganda'
  },
  {
    id: 232,
    code: 'UM',
    name: 'Isole minori esterne degli Stati Uniti'
  },
  {
    id: 233,
    code: 'US',
    name: "Stati Uniti d'America"
  },
  {
    id: 234,
    code: 'UY',
    name: 'Uruguay'
  },
  {
    id: 235,
    code: 'UZ',
    name: 'Uzbekistan'
  },
  {
    id: 236,
    code: 'VA',
    name: 'Città del Vaticano'
  },
  {
    id: 237,
    code: 'VC',
    name: 'Saint Vincent e Grenadine'
  },
  {
    id: 238,
    code: 'VE',
    name: 'Venezuela'
  },
  {
    id: 239,
    code: 'VG',
    name: 'Isole Vergini britanniche'
  },
  {
    id: 240,
    code: 'VI',
    name: 'Isole Vergini americane'
  },
  {
    id: 241,
    code: 'VN',
    name: 'Vietnam'
  },
  {
    id: 242,
    code: 'VU',
    name: 'Vanuatu'
  },
  {
    id: 243,
    code: 'WF',
    name: 'Wallis e Futuna'
  },
  {
    id: 244,
    code: 'WS',
    name: 'Samoa'
  },
  {
    id: 245,
    code: 'YE',
    name: 'Yemen'
  },
  {
    id: 246,
    code: 'YT',
    name: 'Mayotte'
  },
  {
    id: 247,
    code: 'ZA',
    name: 'Sudafrica'
  },
  {
    id: 248,
    code: 'ZM',
    name: 'Zambia'
  },
  {
    id: 249,
    code: 'ZW',
    name: 'Zimbabwe'
  },
  {
    id: 250,
    code: 'XX',
    name: 'Sconosciuto'
  }
];

import { yup } from "common/validation/initYup";
import { ContractDto, ContractSchema } from "../generated/ContractDto";
import { PreRegisteredContractBaseSchema } from "./PreRegisteredContractBaseDto";




export const PreRegisteredContractSchema = yup.object().shape({
    ...ContractSchema.fields, 
    ...PreRegisteredContractBaseSchema.fields
})

export type IPreRegisteredContractDto = yup.InferType<typeof PreRegisteredContractSchema>;
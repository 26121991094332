import * as React from 'react';
import { Button, Table } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { useEffect, useState } from 'react';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { SorterResult } from 'antd/lib/table/interface';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { ProfileOutlined } from '@ant-design/icons';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { Section } from 'client/ui/section/Section';
import { TaxRecordDto } from 'common/dto/generated/TaxRecordDto';
import { TaxRecordColumns } from './table/TaxRecordColumns';
import { TaxRecordQueryDto } from 'common/dto/query/TaxRecordQueryDto';
import { TaxRecordApi } from 'client/components/schema/tax-record/TaxRecordApi';
import { TaxRecordOrderBy } from 'common/schema/tax-record/TaxRecordTypes';

interface Params {
  filter: string;
}

export interface TaxRecordListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco delle Schede Anagrafiche
 */
export function TaxRecordListPage(props: TaxRecordListPageProps) {
  const [query, setQuery] = useState<TaxRecordQueryDto>(
    new TaxRecordQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 10
      })
    )
  );

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Schede Anagrafe Tributaria',
    menuKey: 'tax'
  });

  const { response, loading, error } = useApiQuery(TaxRecordApi.list, {
    data: { query }
  });

  const records = response?.data.items;

  const columns = getPaginationUrlColumns<TaxRecordDto, TaxRecordQueryDto>(
    TaxRecordColumns,
    query
  );

  useEffect(() => {
    setQuery(
      new TaxRecordQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  }, [props.location.search]);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle schede anagrafe tributaria"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeadingRogante
        fixedWidth
        title={
          <PageHeadingTitle>Elenco Schede Anagrafe Tributaria</PageHeadingTitle>
        }
        breadcrumbRender={() => <BreadcrumbsContainer />}
      />
      <PageContent fixedWidth>
        <Section
          icon={<ProfileOutlined />}
          title="Elenco Schede Anagrafe Tributaria"
        >
          <Table<TaxRecordDto>
            loading={tableLoaderSpin(loading || !records)}
            rowKey="id"
            onRow={record => ({
              style: {
                cursor: 'pointer'
              },
              onClick: () => {
                props.history.push(`/tax/records/${record.id}`);
              }
            })}
            columns={columns}
            dataSource={records}
            size="middle"
            onChange={(pagination, filters, sorter) => {
              const { field, order } = sorter as SorterResult<TaxRecordDto>;

              const taxRecordQuery = new TaxRecordQueryDto({
                ...query,
                pageNumber: pagination.current || query.pageNumber,
                pageSize: pagination.pageSize || query.pageSize,
                orderBy: order
                  ? (field?.toString() as TaxRecordOrderBy)
                  : TaxRecordOrderBy.id,
                orderByDirection: order
                  ? ColumnSortDirections[order]
                  : ColumnSortDirections.descend
              });

              setPaginationUrlQuery(props, taxRecordQuery);
            }}
            pagination={{
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: true,
              total: response?.data.meta.total,
              pageSize: query.pageSize,
              current: query.pageNumber,
              pageSizeOptions: ['5', '10', '20', '30', '40']
            }}
          />
        </Section>
      </PageContent>
    </CorePageLayout>
  );
}

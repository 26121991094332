import { useCallback, useState } from 'react';
import { BaseRelationEditor, RelationEditor } from './useRelationEditor';

export interface RelationActiveHandle<T> {
  /** Avvia la modifica di un elemento */
  open: (record: T) => void;
  /** Annulla le modifiche */
  cancel: () => void;
  /** Applica le modifiche */
  apply: (input: T) => 'updated' | 'added';
  /** Elemento corrente */
  current: T | null;
  /** E' un nuovo elemento quello in modifica? */
  isNew: boolean;
  /** È visibile? */
  isVisible: boolean;
}

/**
 * Ritorna un handle per gestire la selezione di un elemento attivo della
 * relazione che si sta modificando.
 * Permette di gestire una Modal o un Drawer di modifica "inline"
 */
export function useRelationActiveHandle<T>(
  editor: BaseRelationEditor<T>
): RelationActiveHandle<T> {
  const [current, setCurrent] = useState<T | null>(null);

  const isNew = current == null || !editor.items.includes(current);

  const open = useCallback((record: T) => {
    setCurrent(record);
  }, []);

  const cancel = useCallback(() => {
    setCurrent(null);
  }, []);

  /** Applica le modifiche all'elemento corrente (sia in update che in add) */
  const apply = useCallback(
    (input: T) => {
      // Necessario per il "reinitialize" di Formik
      setCurrent(input);

      if (!isNew) {
        editor.update(current!, input);
        setCurrent(null);
        return 'updated';
      } else {
        editor.add(input);
        setCurrent(null);
        return 'added';
      }
    },
    [current, isNew]
  );

  return {
    open,
    cancel,
    apply,
    current,
    isNew: current == null || !editor.items.includes(current),
    isVisible: current != null
  };
}

import { logger } from 'client/core/logger/logger';
import { yup } from 'common/validation/initYup';
import { useFormikContext } from 'formik';
import { debounce, memoize } from 'lodash';
import { useFormikAugmentedContext } from '../../FormikAugmentedContext';
import { useFormikFormContext } from '../../FormikFormContext';

const debouncedLog = memoize((text: string) =>
  debounce(logger.warn, 200)(text)
);

interface FormikFieldValidationOptions {
  /** Il campo è in sola lettura, non deve mai essere editabile. */
  readOnly?: boolean;
}

/**
 * Ottiene le opzioni di validazioni da Formik utilizzando l'introspezione
 * sullo schema di Yup. Richiede un `<FormikAugmented />`
 */
// TODO Ottimizzare
export function useFormikFieldValidation(
  field: string,
  options?: FormikFieldValidationOptions
) {
  const formik = useFormikContext();
  const formikForm = useFormikFormContext();
  const formikAugmented = useFormikAugmentedContext();

  // Se stiamo utilizzando Formik diamo un avviso
  if (!formikAugmented) {
    if (process.env.NODE_ENV === 'development') {
      debouncedLog(`[FormikAugmented] Attenzione! Accesso al campo "${field}", ma non siamo in un "<FormikAugmented />" (sostituire <Formik /> con la versione aumentata)`); // prettier-ignore
    }

    return {
      editable: formikForm.editable,
      stripped: false,
      required: false
    };
  }

  // Readonly
  if (options?.readOnly) {
    return {
      editable: false,
      stripped: false,
      required: false
    };
  }

  try {
    const schema = yup
      .reach(
        formikAugmented.schema,
        field,
        formik.initialValues,
        formikAugmented.context
      )
      .resolve({
        value: formik.values,
        context: formikAugmented.context,
        parent: formik.values
      });

    const description = schema.describe();

    const stripped = schema.spec?.strip ?? false;

    return {
      /**
       * Editabile
       */
      editable: formikForm.editable && !stripped,

      /**
       * Proprietà rimossa dallo schema
       */
      stripped,

      /**
       * Proprietà richiesta
       */
      required: description.tests.some((t: any) => t.name === 'required'),

      /**
       * Label
       */
      label: description.label as string | null
    };
  } catch (e: any) {
    if (process.env.NODE_ENV === 'development') {
      const schemaName = formikAugmented.schema.meta()?.schemaName ?? 'N.A.';
      debouncedLog(
        `[FormikAugmented] Attenzione! Il campo "${field}" non è definito nello schema di Yup "${schemaName}". Se è intenzionalmente in sola lettura, specificare la prop "readOnly".
        Assicurarsi di aver inserito un <FormikAugmented /> al posto di <Formik /> se si è in un Form annidato (i.e. Modal)`); // prettier-ignore
    }

    // Non è stato trovato nello schema.
    return {
      editable: formikForm.editable,
      stripped: false,
      required: false
    };
  }
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { plainToClass, classToPlain } from 'class-transformer';
import { ApiProperty } from '@nestjs/swagger';
import type { IDtoPartial } from 'common/dto/shared/DtoInterfaces';

/**
 * Rappresentazione DTO della classe ContractTransfer
 */
@ValidationSchema(() => ContractTransferSchema)
export class ContractTransferDto {
  @ApiProperty({ type: Number, description: 'Ufficio di Partenza' })
  senderOfficeId?: number;
  @ApiProperty({ type: Number, description: 'Ufficio di Destinazione' })
  receiverOfficeId!: number;
  @ApiProperty({
    type: [Number],
    description: 'Elenco degli ID dei contratti da trasferire'
  })
  ids?: number[];
  @ApiProperty({
    type: Boolean,
    description: 'Indica se è un trasferimento singolo'
  })
  isSingleTransfer?: boolean;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractTransferDto>) {
    if (values != null) {
      Object.assign(
        this,
        values instanceof ContractTransferDto
          ? values
          : plainToClass(ContractTransferDto, values)
      );
    }
  }

  async validate(options?: any) {
    const validated = await ContractTransferSchema.validate(
      classToPlain(this),
      options
    );
    return new ContractTransferDto(validated);
  }
}

export const ContractTransferSchema = yup
  .object({
    // Elenco degli ID dei contratti da trasferire richiesto solo se è un trasferimento singolo
    ids: yup
      .array()
      .of(yup.number())
      .when(['$isSingleTransfer'], {
        is: true,
        then: schema => schema.required()
      })
      .default(() => [])
      .label('Elenco Contratti'),
    senderOfficeId: yup
      .number()
      // Se è un trasferimento singolo, l'ufficio di partenza non è richiesto
      .when(['$isSingleTransfer'], {
        is: false,
        then: schema => schema.required()
      })
      .label('Ufficio di Partenza'),
    receiverOfficeId: yup
      .number()
      .transform(v => (v == null ? undefined : v))
      .required()
      .label('Ufficio di Destinazione')
      .notOneOf(
        [yup.ref('senderOfficeId')],
        'Ufficio di Partenza e Destinazione non possono essere uguali'
      ),
    isSingleTransfer: yup
      .boolean()
      .default(false)
      .label('Trasferimento Singolo')
  })
  .noUnknown()
  .meta({ schemaName: 'ContractTransferSchema' })
  .required();

import * as React from 'react';
import clx from 'classnames';
import './UploadInputWrapper.scss';

export interface UploadInputWrapperProps {
  className?: string;
  children: React.ReactNode;
}

/**
 * Container per assegnare gli stili ai componenti Drawer o Upload di Ant
 */
export function UploadInputWrapper(props: UploadInputWrapperProps) {
  return (
    <div className={clx('upload-input-wrapper', props.className, {})}>
      {props.children}
    </div>
  );
}

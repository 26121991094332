import { isPlainObject } from 'lodash';

/**
 * Funzione per codificare come stringa un oggetto, per gestirne la dipendenza
 * in un hook (i.e. `useEffect`) senza doversi preoccupare di ordinamento
 * di chiavi.
 */
export function objToKey<T extends Record<string, any>>(obj: T): T | string {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const sortedObj = Object.keys(obj)
    .sort()
    .reduce((result: Record<string, any>, key) => {
      result[key] = objToKey(obj[key]);
      return result;
    }, {});
  return JSON.stringify(sortedObj);
}

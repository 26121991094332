import { Tag, Typography } from 'antd';
const { Paragraph } = Typography;
import { ColumnProps } from 'antd/lib/table';
import { IFixturesContract } from 'client/fixtures/FixturesTableContract';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { ISODate } from 'client/ui/display/date/ISODate';
import { ConsumerDto } from 'common/dto/generated/ConsumerDto';
import React from 'react';

export const ConsumerColumns: ColumnProps<ConsumerDto>[] = [
  {
    title: 'Codice',
    dataIndex: 'id',
    sorter: true,
    width: 90,
    // In questo modo previene di impostare la colonna a undefined.
    // Avendo un default nel dto, si crea un problema quando viene
    // impostato a undefined
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Denominazione',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Codice Fiscale',
    dataIndex: 'fiscalNumber',
    width: 120
  }
];

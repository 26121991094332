import * as React from 'react';
import Select, { OptionProps, SelectProps } from 'antd/lib/select';
import {
  FormikValues,
  useField,
  useFormikContext,
  FieldInputProps,
  FieldHelperProps,
  FormikContextType
} from 'formik';
import {
  ApiGet,
  ApiQueryOptions,
  useApiQuery
} from 'client/core/network/hooks/useApiQuery';
import { Modal, Spin } from 'antd';
import { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { AsyncSelectInputMeta } from './AsyncSelectInputMeta';
import { useFormikFormContext } from '../FormikFormContext';
import { SelectOption } from './SelectInput';
import { useAsyncSelectInitialValue } from '../hooks/input/useAsyncSelectInitialValue';

type Item<R extends Array<any>> = R extends Array<infer I> ? I : never;

type AsyncSelectQueryFn<T extends FormikValues, A, R> = (
  formik: FormikContextType<T>,
  search?: string | undefined,
  pagination?: { page: number; pageSize: number }
) => ApiQueryOptions<A, R>;

/**
 * Dati aggiuntivi visualizzabili nella select.
 */
export type AsyncSelectMeta = {
  shown: number;
  total: number;
};

export interface AsyncSelectInputProps<
  T extends FormikValues,
  A,
  R,
  O extends Array<any>
> extends SelectProps<any> {
  name: string;
  /** Permette di gestire anche un campo oggetto, i.e. `supplier` oltre che `supplierId` */
  objectName?: string;
  /** Permette di caricare con una query le opzioni. */
  query: {
    apiFn: ApiGet<A, R>;
    options: ApiQueryOptions<A, R> | AsyncSelectQueryFn<T, A, R>;
  };
  refreshOnSearch?: boolean;
  shouldConfirmOnChange?: () => boolean;
  confirmOnChangeMessage?: string;
  responseTransform?: (response: R) => O;
  responseMeta?: (response: R) => AsyncSelectMeta;
  optionTransform: (option: Item<O>) => SelectOption;
  /** Gestisce impostazioni aggiuntive alla selezione */
  onAfterSelect?: (option: Item<O> | undefined) => void;
  /**
   * Trasforma i value del componente Select (string | number) permettendo di gestire
   * i valori come oggetti nel formikField. ex.
   * {
   *   from: (value) => value.id,
   *   to: (value) => ({id: value})
   * }
   * permette di avere oggetti {id: number} come value del formikField.
   */
  valueTransform?: {
    from: (value: T | null | undefined) => any;
    to: (value: any, options?: Item<O>[]) => T | null | undefined;
  };
  /**
   * Permette di caricare il valore iniziale, che potrebbe non essere presente
   * nella query, non permettendo al componente di renderizzare corettamente la label.
   */
  loadInitialValue?: {
    apiFn: ApiGet<any, any>;
    options: ApiQueryOptions<any, any> | AsyncSelectQueryFn<T, any, any>;
  };
  initialValueTransform?: (response: any | null) => Item<O>;

  /**
   * Gestione metadati aggiuntivi
   */
  extraMeta?: React.ReactNode;
}

type InferArray<T> = T extends any[] ? T : never;

/**
 * Select collegata direttamente a Formik.
 */
// TODO: Gestire errori
export function AsyncSelectInput<
  T extends FormikValues,
  A,
  R,
  O extends Array<any>
>(props: AsyncSelectInputProps<T, A, R, O>) {
  const {
    name,
    objectName,
    query,
    responseTransform,
    responseMeta,
    optionTransform,
    refreshOnSearch,
    onAfterSelect,
    confirmOnChangeMessage,
    shouldConfirmOnChange,
    valueTransform,
    loadInitialValue,
    initialValueTransform,
    extraMeta,
    ...otherProps
  } = props;
  const confirmOnChange = shouldConfirmOnChange ?? (() => false);
  const [field, meta, helpers] = useField<T>(name);
  const [search, setSearch] = useState(undefined as string | undefined);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 20 });
  const formik = useFormikContext<T>();
  const { response, loading, error } = useApiQuery(
    query.apiFn,
    typeof query.options === 'function'
      ? query.options(formik, search, pagination)
      : query.options
  );

  const { disabled } = useFormikFormContext();

  const responseTransformFn = responseTransform ?? (i => i as unknown as O);
  const valueTransformFrom = valueTransform?.from ?? ((i: T) => i);
  const valueTransformTo = valueTransform?.to ?? ((i, options) => i as any);

  // Ricerca
  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
      setPagination({ page: 1, pageSize: 20 });
    }, 200),
    [refreshOnSearch]
  );

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (
        e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
        e.currentTarget.scrollHeight
      ) {
        if (responseMeta && response) {
          const meta = responseMeta(response.data);
          if (meta.shown < meta.total) {
            setPagination(p => ({ ...p, pageSize: p.pageSize + 20 }));
          }
        }
      }
    },
    [responseMeta, response, field.value]
  );

  const transformedResponse =
    (response?.data && responseTransformFn(response?.data)) ??
    ([] as unknown as O);

  // Creo le opzioni associate all`'item'` in modo da poterlo passare
  // alla callback `onSelect`
  const options = transformedResponse.map((item: Item<O>) => ({
    option: optionTransform(item),
    item
  }));

  // Gestione initialValue
  const [initialValueShow, setInitialValueShow] = useState(!!loadInitialValue);

  const {
    option: initialValueOption,
    loading: initialValueLoading,
    error: intitialValueError
  } = useAsyncSelectInitialValue({
    loadInitialValue,
    initialValueTransform,
    optionTransform,
    search
  });

  useEffect(() => {
    /** Gestisco quando nascondere l'option aggiuntiva dell'initial value */

    // In caso di ricerca non mostrarla
    if (!!search && search !== '') {
      setInitialValueShow(false);
      return;
    }

    // Nel caso non esista
    if (!initialValueOption) {
      setInitialValueShow(false);
      return;
    }

    // Nel caso sia già elencata
    if (options.some(opt => opt.option.value === initialValueOption?.value)) {
      setInitialValueShow(false);
      return;
    }

    // Altrimenti, mostrala sempre
    setInitialValueShow(true);
  }, [options, initialValueOption, loadInitialValue]);

  // ModalConfirm
  const createModalConfirm = useCallback(
    (onOk: () => void) => {
      return Modal.confirm({
        title: 'Attenzione',
        content:
          confirmOnChangeMessage ??
          'Sei sicuro di voler svolgere questa operazione?',
        onOk
      });
    },
    [confirmOnChangeMessage]
  );

  // Chiamata quando viene modificata la scelta della select
  const onChangeValue = useCallback(
    (value, nextValue) => {
      helpers.setValue(valueTransformTo(nextValue, transformedResponse));
      const option = options.find(o => o.option.value === value);

      // Sincronizziamo il campo "objectName", ovvero l'item selezionato.
      if (objectName) {
        formik.setFieldValue(objectName, option?.item);
      }

      if (search) setSearch(undefined); // Altrimenti rimarrebbe il filtro attivo
      if (onAfterSelect) {
        onAfterSelect(option?.item);
      }
    },
    [options]
  );

  // Informo che esistono altri dati
  return (
    <Select<any>
      {...otherProps}
      disabled={otherProps.disabled || disabled}
      loading={loading}
      notFoundContent={
        loading ? <Spin size="small" /> : 'Nessun elemento trovato.'
      }
      value={valueTransformFrom(field.value)}
      filterOption={!refreshOnSearch}
      onSearch={refreshOnSearch ? handleSearch : undefined}
      onPopupScroll={handleScroll}
      onChange={value => {
        const nextValue = value == undefined ? null : value;
        // Non apre la modal se la Select è vuota (undefined)
        if (confirmOnChange() && field.value != undefined) {
          createModalConfirm(() => onChangeValue(value, nextValue));
        } else {
          onChangeValue(value, nextValue);
        }
      }}
      dropdownRender={menu => (
        <div>
          {menu}
          <AsyncSelectInputMeta
            extraValues={initialValueShow ? 1 : 0}
            meta={
              responseMeta && response ? responseMeta(response.data) : undefined
            }
            extraMeta={extraMeta}
          />
        </div>
      )}
    >
      {initialValueShow && mapToComponent(initialValueOption!)}
      {options.map(({ option, item }) => mapToComponent(option))}
    </Select>
  );
}

function mapToComponent(option: SelectOption | null | undefined) {
  if (!option) {
    return null;
  }
  return (
    <Select.Option key={option.value} {...option}>
      {option.label}
    </Select.Option>
  );
}

import {
  DisconnectOutlined,
  EditOutlined,
  EuroOutlined,
  EyeOutlined,
  IdcardOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Col, Input, message, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { FixturesSupplierData } from 'client/fixtures/FixturesSupplierData';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { Section } from 'client/ui/section/Section';
import { SectionButton } from 'client/ui/section/SectionButton';
import { SupplierDto } from 'common/dto/generated/SupplierDto';
import { useFormikContext } from 'formik';
import Decimal from 'decimal.js-light';
import React, { useCallback, useRef, useState } from 'react';
import {
  SupplierEditHandle,
  SupplierEditModal
} from 'client/routes/supplier/edit/SupplierEditModal';
import { ContractSupplierDto } from 'common/dto/generated/ContractSupplierDto';
import { ContractSupplierEditModal } from 'client/components/schema/contract-supplier/edit/ContractSupplierEditModal';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';

// TODO Funzione per ottenere l'importo lordo del Fornitore relativamente ad un contratto
const getContractSupplierAmount = (
  supplerId: number,
  contractId?: number
): Decimal => {
  // query su ContractSupplier (ritorna amount | Importo Contratto)
  const amount = new Decimal(1000000);
  return amount;
};

export interface ContractSuppliersSectionProps {
  contractId?: number | undefined;
}

export function ContractSuppliersSection(props: ContractSuppliersSectionProps) {
  const { contractId } = props;
  const editor = useRelationEditor<ContractSupplierDto>('suppliers');

  const formContext = useFormikFormContext();
  const formik = useFormikContext();

  const editable = formContext.editable;

  const supplierColumns: ColumnsType<ContractSupplierDto> = [
    {
      title: 'Denominazione',
      dataIndex: 'name',
      render: (_, record) => record.supplier.getName()
    },
    {
      title: 'Cod. Fiscale / P. Iva',
      dataIndex: 'fiscalNumber',
      render: (_, record) => record.supplier.getFiscalNumber()
    },
    {
      title: 'Indirizzo',
      dataIndex: 'address',
      render: (_, record) => record.supplier.getFullAddress()
    },
    {
      title: 'Importo Lordo',
      dataIndex: 'amount',
      align: 'right',
      render: (_, record) => (
        <CurrencyRender currency="EUR" value={record.amount} />
      )
    },
    // TODO: Inserire pulsante visualizzazione dettaglio contraente
    editable
      ? {
          align: 'right',
          render: (_, record) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                children="Modifica"
                size="small"
                type="link"
                disabled={!editable}
                onClick={() => editor.handle.open(record)}
              />
              <ButtonDeleteConfirm
                title={'Scollegare Contraente?'}
                okText="Scollega"
                iconOnlyButton
                onConfirm={() => editor.remove(record)}
                buttonIcon={<DisconnectOutlined />}
                size="small"
                disabled={!editable}
                buttonDanger
                buttonType="link"
              />
            </Space>
          )
        }
      : {
          align: 'right',
          render: record => (
            <Button
              icon={<EyeOutlined />}
              children="Dettagli"
              size="small"
              type="link"
              onClick={() => editor.handle.open(record)}
            />
          )
        }
  ];

  return (
    <Section
      icon={<IdcardOutlined />}
      title="Contraenti"
      extra={
        editable && (
          <SectionButton
            key="add"
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            onClick={e => {
              editor.handle.open(new ContractSupplierDto({ contractId }));
              e.stopPropagation();
            }}
          >
            Associa Contraente
          </SectionButton>
        )
      }
    >
      <Table
        columns={supplierColumns}
        rowKey="id"
        dataSource={editor.items}
        size="middle"
        pagination={false}
        // pagination={{
        //   size: 'small',
        //   position: ['bottomRight'],
        //   showSizeChanger: true,
        //   pageSizeOptions: ['5', '10', '20', '30', '40']
        // }}
      />
      <ContractSupplierEditModal
        contractId={contractId}
        editor={editor}
        editable={editable}
      />
    </Section>
  );
}

import { sortBy, toPairs, groupBy } from 'lodash';
import { IComuni, IProvinces } from './Provinces';
import comuni from './comuni.json';

let provinces: IProvinces = [];

/**
 * Trasforma la lista di comuni in una lista di province con i propri comuni.
 */
export function getProvinceStructure(): IProvinces {
  // Se ho già calcolato le province, riuso il dato in RAM
  if (provinces.length > 0) return provinces;

  const grouped = groupBy(comuni, c => c.sigla);
  let values = toPairs(grouped).map(([sigla, comuni]) => {
    return {
      sigla,
      nome: comuni[0].provincia.nome,
      comuni: sortBy(
        comuni.map(comune => ({ nome: comune.nome })),
        'nome'
      )
    };
  });

  provinces = sortBy(values, 'nome');

  return provinces;
}

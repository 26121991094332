import { useEffect, useCallback, useState, useRef } from 'react';
import { useFormikContext, getIn, FormikContextType } from 'formik';
import { isEqual } from 'lodash';

/**
 * Riconosce se il campo indicato non è specificato (`undefined`)
 * e non ritorna nulla.
 */
const getInFormik = (
  formik: FormikContextType<any>,
  field: string | undefined
) => {
  if (!field) return undefined;
  return getIn(formik.values, field);
};

/**
 * Riconosce un cambiamento fra un insieme di input di Formik, e chiama
 * la callback quando si verifica una differenza.
 *
 * @param onChange
 * @param sourceFields
 */
export function useFormikFieldChange(
  onChange: (
    formik: FormikContextType<any>,
    values: any[],
    previousValues: any[]
  ) => void,
  sourceFields: (string | undefined)[],
  options?: {
    /**
     * Permette di sapere quando i campi cambiano anche se stiamo gestendo
     * un cambiamento dovuto all'`enableReinitialize` non di una modifica
     * utente.
     */
    listenInitialValues: boolean;
  }
) {
  const formik = useFormikContext<any>();

  const isSourceDefined = sourceFields.every(field => field != null);

  const initialValues = sourceFields.map(field => getInFormik(formik, field));
  const previousValues = useRef<any[]>(initialValues);
  const handleChange = useCallback(onChange, []);

  useEffect(() => {
    if (!isSourceDefined) return;

    const values = sourceFields.map(field => getInFormik(formik, field));
    if (!isEqual(values, previousValues.current)) {
      if (formik.dirty || options?.listenInitialValues) {
        // console.log('different', values, previousValues.current, formik.dirty);
        handleChange(formik, values, previousValues.current);
      }

      // Aggiorniamo solamente i valori (per di un `enableReinitialize` sono
      // cambiati, ma il form non è "cambiato")
      previousValues.current = values;
    }
  }, [formik.values]);
}

import { CloseOutlined } from '@ant-design/icons';
import { Button, message, Space, Typography } from 'antd';
const { Text, Paragraph } = Typography;
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { TaxRecordDto } from 'common/dto/generated/TaxRecordDto';
import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { TaxRecordState } from 'common/schema/tax-record/TaxRecordTypes';
import { TaxRecordApi } from 'client/components/schema/tax-record/TaxRecordApi';
import {
  EvaluateTaxRecordDto,
  EvaluateTaxRecordSchema
} from 'common/dto/schema/EvaluateTaxRecordDto';
import { TaxRecordMachine } from 'server/schema/tax/tax-record/TaxRecordMachine';

export interface TaxRecordNotSendableModalProps {
  taxRecord?: TaxRecordDto;
}

/**
 * Modal per segnare come non inviabile la scheda anagrafica
 * da parte di UR (Ufficiale Rogante)
 */
export function TaxRecordNotSendableModal(
  props: TaxRecordNotSendableModalProps
) {
  const { taxRecord } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const currentUser = useCurrentUser();

  if (!taxRecord) return null;

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [notSendable] = useApiMutation(
    TaxRecordApi.actions.states.notSendable,
    {
      invalidates: [TaxRecordApi.find]
    }
  );

  const transition = TaxRecordMachine.to(
    taxRecord,
    TaxRecordState.NotSendable,
    currentUser
  );

  if (!transition.allowed && transition.skipped) return null;

  return (
    <>
      <Button
        type="primary"
        danger={true}
        disabled={!transition.allowed}
        onClick={showModal}
      >
        Scarta <CloseOutlined />
      </Button>

      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={new EvaluateTaxRecordDto()}
        validate={schemaToValidator(EvaluateTaxRecordSchema)}
        onSubmit={async values => {
          try {
            const input = await values.validate();

            await notSendable({ data: { id: taxRecord.id } });
            message.success('Operazione riuscita');
            handleCancel();
          } catch (e) {
            message.error('Si è verificato un errore. Riprovare.'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        <FormikForm nested inline editable helpInTooltips>
          <ModalDialog
            title="Scarto Scheda Anagrafica"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={
              <Space>
                <Button onClick={handleCancel}>Annulla</Button>
                <FormikSendButton>
                  Scarta <CloseOutlined />
                </FormikSendButton>
              </Space>
            }
          >
            <FormFieldsContainer>
              <FormFieldItem
                component={TextAreaInput}
                label="Descrizione"
                name="description"
                placeholder="Descrizione"
              />
            </FormFieldsContainer>
          </ModalDialog>
        </FormikForm>
      </Formik>
    </>
  );
}

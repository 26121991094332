import React, { useContext } from 'react';

export interface IFormikFormContext {
  /** Permette di nascondere il `success` dai FormItem */
  hideSuccess?: boolean;
  /** Specifica se mostrare gli errori di validazione in un tooltip */
  helpInTooltips?: boolean;
  /** Disabilita tutti gli input sottostanti */
  disabled?: boolean;
  /** Modalità di modifica abilitata o disabilitata */
  editable?: boolean;
}

export const FormikFormContext = React.createContext<IFormikFormContext>({
  hideSuccess: false,
  helpInTooltips: false,
  disabled: false
});

export const useFormikFormContext = () => useContext(FormikFormContext);

import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { UserDto } from 'common/dto/generated/UserDto';
import { UserApi } from '../UserApi';

/**
 * Hook per recuperare il profilo dell'utente dal documentale (informazioni sugli uffici/ruoli, ...)
 */
export function useUserAuthorities(
  currentUser: UserDto | null,
  skip?: boolean
) {
  const result = useApiQuery(UserApi.documental.authorities.list, {
    skip: currentUser == null && skip,
    data: {}
  });

  return result;
}

/**
 * Ritorna la lista degli ID degli uffici del profilo utente
 */
export function useAuthoritiesOfficeCodes(
  currentUser: UserDto | null,
  options?: { skip?: boolean }
) {
  const result = useUserAuthorities(currentUser, options?.skip);
  return {
    officeCodes:
      result.response?.data?.offices.map(office => office.code) ?? [],
    loading: result.loading,
    error: result.error
  };
}

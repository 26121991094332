export enum AccessibleResources {
  Contract = 'Contract'
}

export enum AccessType {
  Read = 'read',
  Write = 'write'
}

export enum TargetType {
  User = 'User',
  Office = 'Office',
  Responsible = 'OfficeResponsible'
}

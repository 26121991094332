import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useCallback } from 'react';

export interface IEditAndSaveButtonProps {
  /** Titolo */
  name?: string;
  /** Funzioni di modifica */
  state: [editable: boolean, setEditable: Function];
  /** Pulsanti da mostrare solo quando *non* si è in modifica */
  whenSaved?: React.ReactNode;
  /** Se la risorsa è nuova, rimuovi il pulsante annulla */
  isNew?: boolean;

  disabled?: boolean;
}

/**
 * Pulsante di modifica e salvataggio
 */
export function EditAndSaveButton(props: IEditAndSaveButtonProps) {
  const {
    name,
    state: [editable, setEditable],
    whenSaved,
    isNew,
    disabled
  } = props;

  const formik = useFormikContext();

  const handleEdit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setEditable(true);
      e.stopPropagation();
    },
    [setEditable]
  );

  if (!editable) {
    return (
      <>
        {whenSaved}
        <Button key="edit" icon={<EditOutlined />} onClick={handleEdit}>
          Modifica {name ?? ''}
        </Button>
      </>
    );
  }

  return (
    <>
      {!isNew && (
        <Button key="cancel" danger htmlType="reset">
          Annulla
        </Button>
      )}
      <Button
        key="submit"
        type="primary"
        htmlType="submit"
        disabled={disabled || !formik.dirty}
        loading={formik.isSubmitting}
        icon={<SaveOutlined />}
      >
        Salva {name ?? ''}
      </Button>
    </>
  );
}

import { apiClient } from 'client/core/network/apiClient';
import {
  TaxRecordDto,
  PaginatedTaxRecordDto
} from 'common/dto/generated/TaxRecordDto';
import { TaxRecordQueryDto } from 'common/dto/query/TaxRecordQueryDto';
import { EvaluateTaxRecordDto } from 'common/dto/schema/EvaluateTaxRecordDto';
import { TaxRecordSendToVerifyDto } from 'common/dto/schema/TaxRecordSendToVerifyDto';

export const TaxRecordApi = {
  create: (opts: { input: TaxRecordDto }) =>
    apiClient
      .requestWithResponseType(TaxRecordDto)
      .post(`/tax-records`, opts.input),

  // TODO: Verificare che non dia problemi, di solito è utilizzato { id: number, input: TaxRecordDto }
  update: (opts: { input: TaxRecordDto }) =>
    apiClient
      .requestWithResponseType(TaxRecordDto)
      .put(`/tax-records/${opts.input.id}`, opts.input),

  find: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(TaxRecordDto)
      .get(`/tax-records/${opts.id}`),

  list: (opts: { query: TaxRecordQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedTaxRecordDto)
      .get(`/tax-records`, {
        params: opts.query
      }),

  actions: {
    states: {
      toVerify: (opts: { id: number; data: TaxRecordSendToVerifyDto }) =>
        apiClient
          .request()
          .post(`/tax-records/${opts.id}/actions/to-verify`, opts.data),
      refused: (opts: { id: number; data: EvaluateTaxRecordDto }) =>
        apiClient.request().post(`/tax-records/${opts.id}/actions/refused`),
      accepted: (opts: { id: number }) =>
        apiClient.request().post(`/tax-records/${opts.id}/actions/accepted`),
      notSendable: (opts: { id: number }) =>
        apiClient.request().post(`/tax-records/${opts.id}/actions/not-sendable`)
    }
  }
};

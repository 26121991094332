import React from 'react';
import clx from 'classnames';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import './SectionButton.scss';
import { LinkProps } from 'react-router-dom';
import { LinkButton } from '../link/LinkButton';

export interface SectionButtonProps extends ButtonProps {
  to?: LinkProps['to'];
}

/**
 * Pulsante di azione posizionato a destra del titolo di una sezione (Panel collapsable)
 * Esempio utilizzo...
 * <Section extra={<SectionButton ... /> ... />
 */
export function SectionButton(props: SectionButtonProps) {
  const { className, size, to, target, ...otherProps } = props;

  if (to)
    return (
      <LinkButton
        className={clx('section-button', props.className)}
        to={to}
        linkProps={{ target: target }}
        size="small"
        type="primary"
        {...otherProps}
      />
    );

  return (
    <Button
      className={clx('section-button', props.className)}
      size="small"
      type="primary"
      {...otherProps}
    />
  );
}

import React from 'react';
import { message } from 'antd';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { RouteComponentProps } from 'react-router';
import { ContractGeneralSection } from './sections/ContractGeneralSection';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { PageHeadingRogante } from 'client/ui/layout/header-rogante/PageHeadingRogante';
import { ContractOfficeSection } from './sections/ContractOfficeSection';
import { ContractSuppliersSection } from './sections/ContractSuppliersSection';
import { ContractEconomicSection } from './sections/ContractEconomicSection';
import { ContractOthersSection } from './sections/ContractOthersSection';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { ContractTaxRecordsSection } from './sections/ContractTaxRecordsSection';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ContractRegistrationSection } from './sections/ContractRegistrationSection';
import { FormikSaveButton } from 'client/ui/button/FormikSaveButton';
import {
  IPreRegisteredContractDto,
  PreRegisteredContractSchema
} from 'common/dto/schema/PreRegisteredContractDto';
import { Section } from 'client/ui/section/Section';
import { BookOutlined } from '@ant-design/icons';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { NotFoundPage } from 'client/components/errors/not-found/NotFoundPage';
import { PreRegistereContractInfo } from './sections/PreRegistereContractInfo';
import { PreregisteredContractTaxRecordsSection } from './sections/PreregisteredContractTaxRecordsSection';
import { ContractDocumentSection } from './sections/ContractDocumentSection';

export interface PreregisteredContractPageProps extends RouteComponentProps {}

/**
 * Pagina del Contratto
 */
export function PreregisteredContractPage(
  props: PreregisteredContractPageProps
) {
  const currentUser = useCurrentUser();

  const contract = new ContractDto({
    hasStampDuty: false,
    authorId: currentUser?.id,
    author: currentUser
  }) as any;

  const [createPreRegistered] = useApiMutation(
    ContractApi.createPreRegistered,
    { invalidates: [ContractApi.find] }
  );

  const contractTitle = 'Contratto Preregistrato';

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/contracts/', title: `Contratti` });
  useBreadcrumbItem({
    priority: 200,
    path: '',
    title: contractTitle,
    menuKey: 'contracts'
  });

  if (!currentUser?.getRole().hasPermission('contracts.preregister')) {
    return <NotFoundPage />;
  }

  return (
    <CorePageLayout>
      <FormikAugmented<IPreRegisteredContractDto>
        initialValues={contract}
        enableReinitialize
        onSubmit={async rawValues => {
          try {
            const input = await PreRegisteredContractSchema.validate(rawValues);

            const result = await createPreRegistered({
              data: { input }
            });
            message.success('Contratto creato con successo. ');
            props.history.push(`/contracts/${result.data!.id}`);
          } catch (e) {
            message.error(
              'Si è verificato un errore durante la creazione del contratto.'
            );
          }
        }}
        validationSchema={PreRegisteredContractSchema}
        validateOnChange={false}
        validationContext={{
          machineActions: undefined
        }}
      >
        <FormikForm helpInTooltips showVisualFeedback editable={true}>
          <PageHeadingRogante
            fixedWidth
            title={<PageHeadingTitle>{contractTitle}</PageHeadingTitle>}
            breadcrumbRender={() => <BreadcrumbsContainer />}
            extra={<FormikSaveButton name="Contratto" />}
          ></PageHeadingRogante>
          <PageContent fixedWidth>
            <PreRegistereContractInfo />
            <Section
              icon={<BookOutlined />}
              title="Dati Registro Cronologico Contratti"
              hasPadding
            >
              <FormFieldsContainer columns={3}>
                <FormFieldItem
                  component={NumberInput}
                  label="Anno"
                  name="documentYear"
                />
                <FormFieldItem
                  component={NumberInput}
                  label="Numero Cronologico"
                  name="documentNumber"
                  labelWidth="width-140"
                />
                <FormFieldItem
                  component={DatePickerInput}
                  label="Data"
                  name="registeredAt"
                  type="from"
                />
              </FormFieldsContainer>
            </Section>
            <ContractRegistrationSection contract={contract} />
            <ContractOfficeSection
              officeSelection={true}
              officeFieldName="officeId"
              // Permetti la selezione di qualunque ufficio (rogatorio e non)
              isRogatory={null}
            />
            <ContractGeneralSection editable={true} />
            <PreregisteredContractTaxRecordsSection />
            <ContractEconomicSection />
            <ContractSuppliersSection />
            {/* Viene mostrata ma non è interagibile finché non si salva */}
            <ContractDocumentSection
              // Non è mai editabile da questa pagina, ma solo una volta salvato (diventando un contratto a tutti gli effeti)
              editable={false}
              allowed={true}
              contractId={null}
            />
            <ContractOthersSection editable={true} />
            <ContractTaxRecordsSection contract={contract} />
          </PageContent>
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}

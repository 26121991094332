import { Button, message } from 'antd';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { OfficeApi } from 'client/components/schema/office/OfficeApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ContractDto } from 'common/dto/generated/ContractDto';
import {
  ContractTransferDto,
  ContractTransferSchema
} from 'common/dto/schema/ContractTransferDto';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { IPreviewTransfer } from './components/PreviewContractListToTransfer';
import { ContractTransferModal } from './ContractTransferModal';

export interface IContractTransferModalProps {
  selectedContracts: number[];
}

/**
 * Form contenente la modale per il trasferimento di contratti.
 */
export function ContractTransferModalForm(props: IContractTransferModalProps) {
  const { selectedContracts } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewTransfer, setPreviewTransfer] =
    useState<IPreviewTransfer | null>(null);

  const initialValues = useMemo(() => new ContractTransferDto(), []);

  const isSingleTransfer = selectedContracts && selectedContracts.length > 0;

  const [listTransferContracts] = useApiMutation(
    ContractApi.listTransferContracts,
    {}
  );

  const [transferContracts] = useApiMutation(ContractApi.transferContracts, {});

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setPreviewTransfer(null);
    setIsModalVisible(false);
  };

  const hasContracts = previewTransfer && previewTransfer.contracts.length > 0;

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Trasferisci a ufficio
      </Button>
      <FormikAugmented<ContractTransferDto>
        enableReinitialize
        validateOnChange={false}
        initialValues={initialValues}
        validationContext={{ isSingleTransfer }}
        validationSchema={ContractTransferSchema}
        onSubmit={async values => {
          try {
            const input = await values.validate({
              context: { isSingleTransfer }
            });
            input.ids = isSingleTransfer ? selectedContracts : [];
            input.isSingleTransfer = isSingleTransfer;
            // Se non ho ancora caricato la lista dei contratti, la carico altrimenti trasferisco
            if (!hasContracts) {
              const response = await listTransferContracts({
                data: input
              });

              // Impostiamo l'anteprima del trasferimento
              setPreviewTransfer({
                contracts: response.data.contracts,
                total: response.data.total
              });
            } else {
              await transferContracts({ data: { input } });
              message.success('Trasferimento riuscito');
              handleCancel();
            }
          } catch (e) {
            message.error('Si è verificato un errore. Riprovare.'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        <>
          <FormErrorsNotification />
          <FormikForm
            nested
            inline
            editable={!hasContracts || isSingleTransfer}
          >
            <ContractTransferModal
              isModalVisible={isModalVisible}
              handleCancel={handleCancel}
              hasContracts={hasContracts}
              previewTransfer={previewTransfer}
              isSingleTransfer={isSingleTransfer}
            />
          </FormikForm>
        </>
      </FormikAugmented>
    </>
  );
}

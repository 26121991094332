import { PermissionsType } from './Permissions';

export class Role<T extends string> {
  constructor(
    public name: T,
    public displayName: string,
    public permissions: PermissionsType[]
  ) {}

  /**
   * Controlla se un ruolo ha a disposizione il permesso specificato.
   * In caso sia passato più di un permesso, controlla se ALMENO UNO è presente.
   */
  public hasPermission(permission: PermissionsType | PermissionsType[]) {
    return Array.isArray(permission)
      ? permission.some(p => this.permissions.includes(p))
      : this.permissions.includes(permission);
  }
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaNotRequired, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";



@GenerateDto()
class SendFileNotification {
    @SchemaLabel('Nota e Assegnazione Ufficiale Rogante')
    description!: string;

    @SchemaLabel('Nome file')
    filename!: string;

    @SchemaLabel('File')
    content!: string;
}

/**
 * Rappresentazione DTO della classe SendFileNotification 
 * Hash: 04ba9a270132ec24fea8dcbb699f2841
 */
@ValidationSchema(() => SendFileNotificationSchema)
export class SendFileNotificationDto {
  @ApiProperty({ type: String, description: 'Nota e Assegnazione Ufficiale Rogante' })
  description!: string;
  @ApiProperty({ type: String, description: 'Nome file' })
  filename!: string;
  @ApiProperty({ type: String, description: 'File' })
  content!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<SendFileNotificationDto>) {
    if (values != null) {
      Object.assign(this, values instanceof SendFileNotificationDto ? values : plainToClass(SendFileNotificationDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await SendFileNotificationSchema.validate(classToPlain(this), options);
    return new SendFileNotificationDto(validated);
  }
}

export const SendFileNotificationSchema = yup
  .object({
    description: yup.string().required().label('Nota e Assegnazione Ufficiale Rogante'),
    filename: yup.string().required().label('Nome file'),
    content: yup.string().required().label('File')
  })
  .noUnknown()
  .meta({ schemaName: "SendFileNotificationSchema" })
  .required();

import { AxiosInstance } from 'axios';
import { classToPlain, plainToClass } from 'class-transformer';
import { logger } from 'client/core/logger/logger';
import { isDto } from 'common/dto/shared/DtoInterfaces';
import { isPlainObject, mapValues } from 'lodash';

const ALLOWED_CODES = [200, 201];

/**
 * Decodifica i JSON come inviati dal server tramite i DTO e class-transformer
 *
 * @see DtoSerializerInterceptor
 */
export function dtoSerializerInterceptor(axios: AxiosInstance) {
  // Codifica
  axios.interceptors.request.use(config => {
    if (config.data != null && isDto(config.data)) {
      config.data = classToPlain(config.data);
    }

    if (config.params != null && isDto(config.params)) {
      config.params = classToPlain(config.params);
    }

    return config;
  });

  // Decodifica
  axios.interceptors.response.use(response => {
    if (!ALLOWED_CODES.includes(response?.status)) {
      return response;
    }

    if (response == null || response.data == null || !response.config.dto) {
      return response;
    }

    response.data = plainToClass(response.config.dto, response.data);

    return response;
  });
}

export type ActivityLogEntityType = 'contract' | 'supplier';

export enum ActivityLogAction {
  /** Creazione entità */
  create = 'create',
  /** Eliminazione entità */
  delete = 'delete',
  /** Aggiornamento entità */
  update = 'update',
  /** Trasferimento entità */
  transferOffice = 'transferOffice',
  /** Annullamento Cancellazione */
  restore = 'restore',
  /** Modifica stato entità */
  changeState = 'changeState',
  /** Aggiunto elemento a entità */
  add = 'add',
  /** Rimozione elemento da entità */
  remove = 'remove',
  /** Un log di errore */
  error = 'error'
}

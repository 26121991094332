import * as React from 'react';
import { Divider } from 'antd';
import { AsyncSelectMeta } from './AsyncSelectInput';
import './AsyncSelectInputMeta.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface IAsyncSelectInputMetaProps {
  meta?: AsyncSelectMeta;
  /**
   * Indicare quanti elementi aggiuntivi ci sono non presenti nei
   * metadati.
   */
  extraValues?: number;
  /**
   * Se impostato, mostra i componenti aggiuntivi utili per esempio ad aggiungere filtri custom alla lista
   */
  extraMeta?: React.ReactNode;
}

/**
 * Dati aggiuntivi per la select asincrona
 */
export function AsyncSelectInputMeta(props: IAsyncSelectInputMetaProps) {
  const { meta, extraValues, extraMeta } = props;

  if (!meta) return null;

  return (
    <>
      <Divider style={{ margin: '4px 0' }} />
      <div className="async-select-input-meta">
        <div className="async-select-input-meta--icon">
          <InfoCircleOutlined />
        </div>
        <div className="async-select-input-meta--text">
          Mostrati {meta.shown + (extraValues ?? 0)} di {meta.total}.
          {meta.shown < meta.total && (
            <>
              <br />
              Filtrare la ricerca digitando per migliorare i risultati.
            </>
          )}
        </div>
      </div>
      {/* Componenti opzionali extra */}
      {extraMeta && (
        <>
          <Divider style={{ margin: '4px 0' }} />
          <div className="async-select-input-extra-meta">{extraMeta}</div>
        </>
      )}
    </>
  );
}

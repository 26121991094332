import { BookOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { Section } from 'client/ui/section/Section';
import e from 'express';
import React from 'react';

export interface IPreRegistereContractInfoProps {}

export function PreRegistereContractInfo(
  props: IPreRegistereContractInfoProps
) {
  const {} = props;
  return (
    <Alert
      message="Contratti Preregistrati"
      description={
        <>
          <p>
            Vengono considerati Contratti Preregistrati da inserire manualmente
            tutti i contratti che:
          </p>
          <ul>
            <li>
              non erano presenti nel vecchio applicativo e che quindi non sono
              stati importati attraverso la migrazione dei dati;
            </li>
            <li>
              sono stati registrati nel 2021, con numero registro cronologico
              assegnato, e che non sono stati inseriti nei file excel per
              l’importazione e generazione delle schede di anagrafe tributaria
              per l’anno 2021;
            </li>
            <li>
              sono stati registrati nel 2022, con numero registro cronologico
              assegnato.
            </li>
          </ul>
        </>
      }
    />
  );
}

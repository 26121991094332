import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    /**
     * Richiede che la stringa sia esattamente del tipo fornito
     */
    exact<T extends string>(compare: T): StringSchema<T>;
  }
}

yup.addMethod(yup.string, 'exact', function (this: yup.StringSchema, compare) {
  return this.meta({ literalValue: compare }).test({
    message: 'Il campo ${path} deve essere "' + compare + '".',
    name: 'notEmptyHtml',
    exclusive: true,
    test(value) {
      if (!value || typeof value !== 'string') return false;
      return value === compare;
    }
  });
});

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from "client/ui/table/TableColumnSort";
import { TaxRecordOrderBy } from 'common/schema/tax-record/TaxRecordTypes';

@GenerateDto()
class TaxRecordQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
  @SchemaLabel("Ordinamento")
  orderBy?: TaxRecordOrderBy = TaxRecordOrderBy.id;
  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
}

/**
 * Rappresentazione DTO della classe TaxRecordQuery 
 * Hash: e6aff4b49be1137eb2c16d3e7e3016a8
 */
@ValidationSchema(() => TaxRecordQuerySchema)
export class TaxRecordQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, enum: ['id', 'paymentYear'], description: 'Ordinamento' })
  orderBy?: TaxRecordOrderBy | undefined = TaxRecordOrderBy.id;
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<TaxRecordQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof TaxRecordQueryDto ? values : plainToClass(TaxRecordQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await TaxRecordQuerySchema.validate(classToPlain(this), options);
    return new TaxRecordQueryDto(validated);
  }
}

export const TaxRecordQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    orderBy: yup.string().oneOfEnum(TaxRecordOrderBy).default(TaxRecordOrderBy.id).label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento')
  })
  .noUnknown()
  .meta({ schemaName: "TaxRecordQuerySchema" })
  .required();

import React, { useCallback } from 'react';
import { InputNumber } from 'antd';
import { FormikValues, useField } from 'formik';
import { InputNumberProps } from 'antd/lib/input-number';
import { useFormikFormContext } from '../FormikFormContext';
import Decimal from 'decimal.js-light';

export interface PercentageInputProps<T extends FormikValues>
  extends Omit<InputNumberProps, 'name'> {
  name: keyof T & string;
}

const PARSE_REGEX = /\s?%/g;

/**
 * Campo per input di tipo Percentuale.
 */
export function PercentageInput<T extends FormikValues>(
  props: PercentageInputProps<T>
) {
  const { name } = props;
  const [field, meta, helpers] = useField<Decimal | null | undefined>(name);
  const { disabled } = useFormikFormContext();

  const handleChange = useCallback(
    (value: string | number | undefined | null) => {
      helpers.setValue(
        value === '' || value == null ? null : new Decimal(value)
      );
    },
    [helpers.setValue]
  );

  return (
    <InputNumber
      {...field}
      value={field.value == null ? undefined : field.value.toNumber()}
      onChange={handleChange}
      {...props}
      formatter={value => `${value} %`}
      parser={value => {
        return value ? value.replace(PARSE_REGEX, '') : '';
      }}
      decimalSeparator=","
      disabled={props.disabled || disabled}
    />
  );
}

PercentageInput.renderFieldValue = (value: any) => {
  return value == null
    ? '-'
    : value instanceof Decimal
    ? `${value.toString()}%`
    : value;
};

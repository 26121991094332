import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import React from 'react';

export interface IFormikSaveButtonProps {
  /** Titolo */
  name?: string;
}

export function FormikSaveButton(props: IFormikSaveButtonProps) {
  const { name } = props;
  const formik = useFormikContext();

  return (
    <Button
      key="submit"
      type="primary"
      htmlType="submit"
      disabled={!formik.dirty}
      loading={formik.isSubmitting}
      icon={<SaveOutlined />}
    >
      Salva {name ?? ''}
    </Button>
  );
}

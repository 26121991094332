import { FileOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, message, Radio, RadioChangeEvent, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { AuthLink } from 'client/core/router/links/AuthLink';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { RadioInput } from 'client/ui/form/input/RadioInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { UploadButtonInputItem } from 'client/ui/form/input/UploadButtonInputItem';
import { UploadDraggerInput } from 'client/ui/form/input/UploadDraggerInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import {
  ContractDocumentDto,
  ContractDocumentSchema
} from 'common/dto/generated/ContractDocumentDto';
import { DocumentKind } from 'common/schema/contract/contract-document/ContractDocumentTypes';
import { useField } from 'formik';
import { get } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { ContractApi } from '../ContractApi';
import { ContractDocumentApi } from './ContractDocumentApi';
import { getDocumentKindDescription } from './getDocumentKindDescription';
import { RadioOptionBlock } from './radio/RadioOptionBlock';

type setContractFn = React.Dispatch<
  React.SetStateAction<ContractDocumentDto | null>
>;
export interface IContractDocumentEditModalProps {
  documents: ContractDocumentDto[] | null | undefined;
  editable?: boolean;
  contractId: number;
  current: ContractDocumentDto | null;
  setCurrent: setContractFn;
}

export function ContractDocumentEditModal(
  props: IContractDocumentEditModalProps
) {
  const { documents, contractId, editable, current, setCurrent } = props;
  const [file, setFile] = useState<File | null>(null);

  // const [helpText, setHelpText] = useState<string | null>(
  //   getDocumentKindDescription(current?.kind)
  // );

  // useEffect(() => {
  //   const description = getDocumentKindDescription(current?.kind);
  //   setHelpText(description);
  // }, [current]);

  const [create, createHelpers] = useApiMutation(ContractDocumentApi.create, {
    invalidates: [ContractDocumentApi.list, ContractApi.find]
  });

  const [update, updateHelpers] = useApiMutation(ContractDocumentApi.update, {
    invalidates: [ContractDocumentApi.list, ContractApi.find]
  });

  const handleClose = useCallback(() => {
    setCurrent(null);
    setFile(null);
  }, [setCurrent, setFile]);

  const isNew = current == null || current.id == null;
  const visible = current != null;

  return (
    <>
      <FormikAugmented<ContractDocumentDto>
        enableReinitialize
        validateOnChange={false}
        initialValues={current ?? new ContractDocumentDto()}
        validationSchema={ContractDocumentSchema}
        validationContext={{ documents }}
        onSubmit={async values => {
          if (!props.editable) return;
          try {
            const input = await values.validate({ documents });

            if (isNew && !file) {
              message.error(`Nessun documento selezionato. Caricare un documento e riprovare.`); // prettier-ignore
              return;
            }

            // Imposto il file e i metadati
            const formData = new FormData();
            if (file) formData.append('document', file);
            Object.keys(input).forEach(key => {
              formData.set(key, get(input, key));
            });

            if (!isNew) {
              await update({
                data: {
                  contractId,
                  id: current.id,
                  data: formData
                }
              });

              message.success('Documento modificato');
            } else {
              await create({
                data: {
                  contractId,
                  data: formData
                }
              });
              message.success('Documento aggiunto');
            }

            handleClose();
          } catch (e) {
            message.error('Si è verificato un errore nella gestione del documento'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        {formik => (
          <>
            <FormErrorsNotification />
            <FormikForm nested editable={props.editable} helpInTooltips>
              <ModalDialog
                title={
                  props.editable && isNew
                    ? 'Aggiungi Documento'
                    : props.editable
                    ? 'Modifica Documento'
                    : 'Dettaglio Documento'
                }
                visible={visible}
                onCancel={handleClose}
                footer={
                  <Space>
                    <Button onClick={handleClose}>Annulla</Button>
                    {props.editable && (
                      <FormikSendButton>
                        <SaveOutlined /> Salva Documento
                      </FormikSendButton>
                    )}
                  </Space>
                }
              >
                {/* Upload */}
                <FormFieldsContainer columns={1}>
                  {/* TODO: Non mostrare il campo upload per la modifica (gestire anche lato server) */}
                  {editable && !formik.values.id && (
                    <FormFieldItem
                      component={UploadDraggerInput}
                      name="uploadDocument"
                      label="Documento"
                      beforeUpload={(file: File) => {
                        if (!file) return false;
                        setFile(file);
                        return false;
                      }}
                      maxCount={1}
                      showUploadList={false}
                    />
                  )}

                  <DataItem
                    value={
                      !file && formik.values.id ? (
                        <AuthLink
                          href={ContractDocumentApi.downloadLink({
                            contractId,
                            id: formik.values.id
                          })}
                        >
                          <a>
                            <FileOutlined /> {formik.values.filename}
                          </a>
                        </AuthLink>
                      ) : (
                        <span>
                          {file?.name && <FileOutlined />} {file?.name}
                        </span>
                      )
                    }
                    label="File"
                  />
                </FormFieldsContainer>

                {/* Dettagli */}
                <FormFieldsContainer columns={1}>
                  <FormFieldItem
                    component={TextAreaInput}
                    name="description"
                    label="Descrizione"
                    placeholder="Descrizione..."
                    rows={3}
                  />
                </FormFieldsContainer>

                <FormFieldsContainer>
                  <FormFieldItem
                    component={RadioInput}
                    name="kind"
                    label="Tipo"
                    // onAfterChange={(kind?: DocumentKind) => {
                    //   setHelpText(getDocumentKindDescription(kind));
                    // }}
                    children={
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <RadioOptionBlock
                          value={DocumentKind.Principal}
                          label="Atto Principale"
                        />
                        <RadioOptionBlock
                          value={DocumentKind.Attachment}
                          label="Allegato Atto Principale"
                        />
                        <RadioOptionBlock
                          value={DocumentKind.Act}
                          label="Atto Presupposto"
                        />
                        <RadioOptionBlock
                          value={DocumentKind.Other}
                          label="Altro"
                        />
                      </Space>
                    }
                  />
                  {/* <Text type="secondary">{helpText}</Text> */}
                </FormFieldsContainer>
              </ModalDialog>
            </FormikForm>
          </>
        )}
      </FormikAugmented>
    </>
  );
}

import { PreventNavigation } from 'client/core/network/prevent/PreventNavigation';
import { useFormikContext } from 'formik';
import * as React from 'react';

export interface IFormikPreventNavigationProps {}

/**
 * Previene la navigazione se il Form di Formik è stato modificato, ovvero
 * se ci sono modifiche non salvate.
 *
 * Utilizziamo "dirty && !isSubmitting" come condizione per evitare di essere
 * bloccati durante il salvataggio di un nuovo form prima del redirect.
 */
export function FormikPreventNavigation(props: IFormikPreventNavigationProps) {
  const formik = useFormikContext();

  return <PreventNavigation when={formik.dirty && !formik.isSubmitting} />;
}

import React from 'react';
import { Prompt } from 'react-router';

interface PreventNavigationProps {
  when: boolean;
  message?: string;
}

/**
 * Notifica l'utente che sta lasciando una pagina quando `when` è vera.
 * Utile per evitare di lasciare form non salvati.
 * NB: se utilizzato con Formik, usare FormikPreventNavigation
 *
 * @see FormikPreventNavigation
 */
export function PreventNavigation(props: PreventNavigationProps) {
  return (
    <Prompt
      when={props.when}
      message={
        props.message ??
        'Sei sicuro di voler abbandonare la pagina? Tutte le modifiche non salvate andranno perse'
      }
    />
  );
}

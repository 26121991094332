import React, { useContext } from 'react';
import { Subject } from 'rxjs';
import { NetworkInvalidate } from './NetworkInvalidate';

export interface NetworkContextState {
  /**
   * Gestisce il refetch automatico di altre query in seguito a delle Mutation.
   */
  invalidator: Subject<NetworkInvalidate>;
}

export const NetworkContext = React.createContext<NetworkContextState>({
  invalidator: new Subject()
});

/**
 * Provider dello stato delle richieste.
 */
export const NetworkProvider = NetworkContext.Provider;

/**
 * Permette di utilizzare il NetworkContext dagli hooks.
 */
export function useNetworkContext() {
  return useContext(NetworkContext);
}

import { ApiProperty } from '@nestjs/swagger';

export class PaginatedMetaDto {
  @ApiProperty({ type: Number })
  page!: number;
  @ApiProperty({ type: Number })
  total!: number;
  @ApiProperty({ type: Number })
  size!: number;
}

type ClassToInterface<T> = {
  [K in keyof T as T[K] extends Function ? never : K]: T[K];
};

type IPaginatedMetaDto = ClassToInterface<PaginatedMetaDto>;

import React from 'react';
import clx from 'classnames';
import { Typography, Col, Row, Layout } from 'antd';
const { Content, Header } = Layout;
const { Text, Title } = Typography;
import './PageLayoutHeaderRogante.scss';
import { useThemeConfig } from 'client/components/schema/config/ClientConfigModule';
import { resolvePublicPath } from 'client/core/appConfig';
import { PageFixedWidthWrapper } from '../page/content/PageFixedWidthWrapper';
import { UserSubMenu } from '../header/UserSubMenu';
import { HeaderUserData } from '../header/HeaderUserData';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { RoleName, Roles } from 'common/permissions/Roles';

/**
 * Placeholder per il logo proncipale dell'applicativo:
 * inseriamo un'immagine trasparente con le dimensioni del logo (il logo sara inserito come background).
 * Le dimensioni e il file del logo sono definite nel thema scss.
 */
const LogoPlaceholder = require('./image/logo-space.png');

export interface PageLayoutHeaderBrianzaProps {
  children: React.ReactNode;
  hasMainMenu?: boolean;
}

/**
 * Fascia in alto della pagina che contiene il logo
 * il nome e il ruolo dell'utente ed eventualmente il nome della UID
 */
export function PageLayoutHeaderRogante(props: PageLayoutHeaderBrianzaProps) {
  const theme = useThemeConfig();
  const currentUser = useCurrentUser();

  const isRogatory = currentUser?.isRogatory();

  return (
    <Header className="page-header-rogante">
      <PageFixedWidthWrapper>
        <div className="top-header">
          <Row align="middle" className="top-name-row">
            <div className="logo">
              <img src={resolvePublicPath(LogoPlaceholder)} alt="Logo" />
            </div>
            <Col>
              <Title
                level={2}
                className={clx('app-title', { rogatory: isRogatory })}
              >
                Ufficiale Rogante | {isRogatory ? 'UR' : 'UD'}
              </Title>
            </Col>
            <Col className="col-user">
              <HeaderUserData />
            </Col>
          </Row>
        </div>
        <Row className="page-menu">
          <Col span={24} className="page-menu-column">
            {props.children}
          </Col>
        </Row>
      </PageFixedWidthWrapper>
    </Header>
  );
}

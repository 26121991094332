import { ArrowRightOutlined, DownloadOutlined } from '@ant-design/icons';
import { Tag, Typography } from 'antd';
const { Paragraph } = Typography;
import { ColumnProps } from 'antd/lib/table';
import { resolveApiPath } from 'client/core/appConfig';
import { ISODate } from 'client/ui/display/date/ISODate';
import { TaxExportDto } from 'common/dto/generated/TaxExportDto';
import React from 'react';
import { Link } from 'react-router-dom';

export const TaxExportColumns: ColumnProps<TaxExportDto>[] = [
  {
    title: 'Anno',
    dataIndex: 'year',
    width: 90,
    sorter: true,
    // In questo modo previene di impostare la colonna a undefined.
    // Avendo un default nel dto, si crea un problema quando viene
    // impostato a undefined
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Committente',
    dataIndex: 'consumerId',
    render: (_, record) => record.consumer?.name
  },
  {
    title: 'File esportato',
    dataIndex: 'fileName',
    // TODO link per scaricare il file
    render: (_, record) => (
      <a href={resolveApiPath(`/tax-exports/${record.id}/download`)}>
        {record.fileName} <DownloadOutlined />
      </a>
    )
  },
  {
    title: 'Data',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 120,
    render: (_, record) => <ISODate date={record.createdAt} />
  },
  {
    title: 'Autore',
    dataIndex: 'authorId',
    width: 120,
    render: (_, record) => record.author?.name
  }
];

import { Tag } from 'antd';
import { DocumentKind } from 'common/schema/contract/contract-document/ContractDocumentTypes';
import React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface IContractDocumentKindTagProps {
  kind: DocumentKind;
}

export function ContractDocumentKindTag(props: IContractDocumentKindTagProps) {
  const { kind } = props;
  switch (kind) {
    case DocumentKind.Attachment:
      return <Tag color="orange">Allegato</Tag>;
    case DocumentKind.Principal:
      return <Tag color="blue">Atto Principale</Tag>;
    case DocumentKind.Act:
      return <Tag color="green">Atto Presupposto</Tag>;
    case DocumentKind.Other:
      return <Tag>Altro</Tag>;

    default:
      assertNever(kind);
  }
}

/** Permette di interfacciare l'ordinamento di Ant e TypeOrm */
export enum ColumnSortDirections {
  ascend = 'ASC',
  descend = 'DESC'
}

/**
 * Prende in input l'ordinamento di una colonna delle tabelle di antd,
 * ritorna null in caso la stringa non sia "ascend" | "descend"
 * @param sort
 * @returns "ascend" | "descend" | null
 */
export function antdTableSortToDirection(
  sort: string | undefined | null
): ColumnSortDirections | null {
  switch (sort) {
    case 'ascend':
      return ColumnSortDirections.ascend;
    case 'descend':
      return ColumnSortDirections.descend;
    default:
      return null;
  }
}

import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaNotRequired, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";
import { ContractState } from 'common/schema/contract/ContractTypes';
import { BaseSchema } from 'yup';


/**
 * Il send to verify si comporta in due modi diversi in base allo stato del contratto di provenienza.
 * Se proviene da "toReview" non verrà uploadato il file pdf ma solo la descrizione (caricata e protocollata come txt).
 * Per fare ciò, si usa lo schema condizionale che rimuove i campi non necessari e converte il content 
 * dal file in esame alla stringa da codificare.
 */
@GenerateDto()
class ContractSendToVerify {
    @SchemaLabel('Nota passaggio a Verifica')
    /* Rimuove il campo */
    @SchemaValidate((schema) => schema.when(['$fromState'], {
      is: ContractState.ToReview,
      then: (schema) => schema.nullable().strip()
    }))
    description?: string | null;

    @SchemaLabel('Nome file')
    /* Rimuove il campo */
    @SchemaValidate((schema) => schema.when(['$fromState'], {
      is:  ContractState.ToReview,
      then: (schema) => schema.notRequired().nullable().strip(),
      otherwise: (schema) => schema.required()
    }))
    filename?: string | null;

    @SchemaLabel('File')
    /* Modifica a label */
    @SchemaValidate((schema) => schema.when(['$fromState'], {
      is: ContractState.ToReview,
      then: (schema) => schema.label('Note di revisione'),
      otherwise: (schema) => schema
    }))
    content!: string;

    // Schema per la check di conferma invio con ufficio proponente differente dall'ufficio dell'utente
    @SchemaLabel('Conferma Invio') 
    @SchemaValidate(schema =>
      schema.when(['$contractOfficeId', '$userOfficeId'], {
        is: (
          contractOfficeId: number | null | undefined,
          userOfficeId: number | null | undefined
        ) => contractOfficeId != null && userOfficeId != null && contractOfficeId !== userOfficeId,
        then: schema =>
          schema.required("È necessario confermare l'invio con un ufficio diverso da quello registrato nel contratto.")
      })
    )
    allowsDifferentOffices?:boolean;
}

/**
 * Rappresentazione DTO della classe ContractSendToVerify 
 * Hash: c86fdd59415e944e793819e48b99df5d
 */
@ValidationSchema(() => ContractSendToVerifySchema)
export class ContractSendToVerifyDto {
  @ApiProperty({ required: false, type: String, description: 'Nota passaggio a Verifica' })
  description?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome file' })
  filename?: string | null | undefined;
  @ApiProperty({ type: String, description: 'File' })
  content!: string;
  @ApiProperty({ required: false, type: Boolean, description: 'Conferma Invio' })
  allowsDifferentOffices?: boolean | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractSendToVerifyDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractSendToVerifyDto ? values : plainToClass(ContractSendToVerifyDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ContractSendToVerifySchema.validate(classToPlain(this), options);
    return new ContractSendToVerifyDto(validated);
  }
}

export const ContractSendToVerifySchema = yup
  .object({
    description: yup.string().nullable().when(['$fromState'], {
          is: ContractState.ToReview,
          then: (schema) => schema.nullable().strip()
        }).label('Nota passaggio a Verifica'),
    filename: yup.string().nullable().when(['$fromState'], {
          is:  ContractState.ToReview,
          then: (schema) => schema.notRequired().nullable().strip(),
          otherwise: (schema) => schema.required()
        }).label('Nome file'),
    content: yup.string().required().when(['$fromState'], {
          is: ContractState.ToReview,
          then: (schema) => schema.label('Note di revisione'),
          otherwise: (schema) => schema
        }).label('File'),
    allowsDifferentOffices: yup.boolean().when(['$contractOfficeId', '$userOfficeId'], {
            is: (
              contractOfficeId: number | null | undefined,
              userOfficeId: number | null | undefined
            ) => contractOfficeId != null && userOfficeId != null && contractOfficeId !== userOfficeId,
            then: schema =>
              schema.required("È necessario confermare l'invio con un ufficio diverso da quello registrato nel contratto.")
          }).label('Conferma Invio')
  })
  .noUnknown()
  .meta({ schemaName: "ContractSendToVerifySchema" })
  .required();

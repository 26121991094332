import { CheckOutlined } from '@ant-design/icons';
import { Button, Divider, message, Space, Typography } from 'antd';
const { Text, Paragraph } = Typography;
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import {
  ContractState,
  ContractTypologyType
} from 'common/schema/contract/ContractTypes';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import {
  ContractSendRegisteredDto,
  ContractSendRegisteredSchema
} from 'common/dto/schema/ContractSendRegisteredDto';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { ContractMachine } from 'common/schema/contract/ContractMachine';

export interface ContractSendToRegistrationModalProps {
  contract?: ContractDto;
}

/**
 * Modal per la Registrazione del contratto  da parte di UR (Ufficiale Rogante)
 * permette di procedere alla Registrazione su Prosa del contratto
 *
 */
export function ContractSendToRegistrationModal(
  props: ContractSendToRegistrationModalProps
) {
  const { contract } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [sendInRegistration] = useApiMutation(
    ContractApi.actions.states.inRegistration,
    { invalidates: [ContractApi.find] }
  );

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const currentUser = useCurrentUser();

  const transition = ContractMachine.to(
    contract,
    ContractState.InRegistration,
    currentUser
  );

  if (!transition.allowed && transition.skipped) return null;
  if (!contract) return null;

  const noTaxRecords =
    contract.typologyType === ContractTypologyType.NoTaxRecords;

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Registra Contratto <CheckOutlined />
      </Button>

      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={
          new ContractSendRegisteredDto({
            withCard:
              contract.typologyType !== 'noTaxRecords' &&
              contract.grossAmount.gte(10326)
          })
        }
        validate={schemaToValidator(ContractSendRegisteredSchema)}
        onSubmit={async values => {
          try {
            const data = await values.validate();

            await sendInRegistration({ data: { id: contract.id, data } });
            message.success('Operazione riuscita');
            handleCancel();
          } catch (e) {
            message.error('Si è verificato un errore. Riprovare.'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        <>
          <FormErrorsNotification />
          <FormikForm nested inline editable={true} helpInTooltips>
            <ModalDialog
              title="Registrazione Contratto e creazione Fascicolo"
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={
                <Space>
                  <Button onClick={handleCancel}>Annulla</Button>
                  <FormikSendButton>
                    Registra Contratto <CheckOutlined />
                  </FormikSendButton>
                </Space>
              }
            >
              <Paragraph style={{ margin: 0 }}>
                <Text strong>Comunicazione Anagrafe Tributaria: </Text>
                {noTaxRecords ? (
                  <>
                    Per il seguente contratto non è richesta la compilazione
                    delle schede di Anagrafe Tributaria perché il contratto è
                    della tipologia "Non soggetto a Comunicazione Anagrafe
                    Tributaria"
                  </>
                ) : (
                  <>
                    Spuntare la casella successiva per abilitare la generazione
                    delle schede di Anagrafe Tributaria che dovranno essere
                    compilate annualmente dall'ufficio proponente.
                  </>
                )}
              </Paragraph>
              {!noTaxRecords && (
                <>
                  <Divider />
                  <FormFieldsContainer>
                    <FormFieldItem
                      component={CheckboxInput}
                      label={<span />}
                      name="withCard"
                      style={{ fontSize: '1.5em' }}
                      children={
                        'È richiesto l’inserimento delle schede di Anagrafe Tributaria'
                      }
                      disabled={noTaxRecords}
                    />
                  </FormFieldsContainer>
                </>
              )}
            </ModalDialog>
          </FormikForm>
        </>
      </Formik>
    </>
  );
}

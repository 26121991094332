import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useFormikContext } from 'formik';
import { FormikValues } from 'formik/dist/types';
import { omit } from 'lodash';
import { AsyncSelectInputProps } from '../../input/AsyncSelectInput';

type Props<T extends FormikValues, A, R, O extends Array<any>> = Pick<
  AsyncSelectInputProps<T, A, R, O>,
  'initialValueTransform' | 'loadInitialValue' | 'optionTransform'
> & { search: string | undefined };

/**
 * Hook per il recupero delle option del singolo campo di initialValue.
 * Permette l'inizializzazione corretta dell'initialValue nelle asyncSelect
 */
export function useAsyncSelectInitialValue<
  T extends FormikValues,
  A,
  R,
  O extends Array<any>
>(input: Props<T, A, R, O>) {
  const {
    initialValueTransform,
    loadInitialValue,
    optionTransform,
    search
  } = input;

  const formik = useFormikContext<T>();

  const initialValueTransformFn = initialValueTransform ?? (i => i as Item<O>);
  const initialValueOptions =
    typeof loadInitialValue?.options === 'function'
      ? loadInitialValue.options(formik, search)
      : loadInitialValue?.options;

  const skip = initialValueOptions?.skip;
  const otherInitialValueOptions = omit(initialValueOptions, 'skip');

  const { response, loading, error } = useApiQuery(loadInitialValue?.apiFn!, {
    skip: !loadInitialValue || skip,
    ...otherInitialValueOptions
  });

  const initialValue: Item<O> =
    (response?.data && initialValueTransformFn(response?.data)) ?? null;

  const option = initialValue ? optionTransform(initialValue) : null;

  return { option, loading, error };
}

/** Stato del Contratto */
export enum TaxRecordState {
  /** Da Completare */
  ToComplete = 'ToComplete',
  /** Da Verificare */
  ToVerify = 'ToVerify',
  /** Rifiutata */
  Refused = 'Refused',
  /** Accettata */
  Accepted = 'Accepted',
  /** Non Inviabile */
  NotSendable = 'NotSendable'
}

export enum TaxRecordOrderBy {
  id = 'id',
  paymentYear = 'paymentYear'
}

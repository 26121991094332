import { Affix, PageHeader, PageHeaderProps } from 'antd';
import clx from 'classnames';

import * as React from 'react';
import { useState } from 'react';
import { PageHeading, PageHeadingProps } from '../page/heading/PageHeading';

export interface PageHeadingRoganteProps extends PageHeadingProps {
  children?: React.ReactNode;
  steps?: React.ReactNode;
}

/**
 * Intestazione della singola pagina
 */
export function PageHeadingRogante(props: PageHeadingRoganteProps) {
  const { steps, ...otherProps } = props;

  return (
    <>
      <PageHeading affix {...otherProps} borderBottom={steps ? false : true} />
      {steps && <PageHeading style={{ paddingTop: 0 }}>{steps}</PageHeading>}
    </>
  );
}

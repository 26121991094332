import { FormikErrors } from 'formik';

export function allErrors(errors: FormikErrors<any>, list: string[] = []) {
  if (!errors) return list;

  Object.values(errors).forEach(error => {
    if (isStringArray(error)) list.push(...error);
    else if (Array.isArray(error)) error.map(e => allErrors(e, list));
    else if (typeof error === 'string') list.push(error);
    else if (typeof error == 'object') allErrors(error, list);
  });
  return list;
}

function isStringArray(item: any): item is string[] {
  return Array.isArray(item) && typeof item[0] === 'string';
}

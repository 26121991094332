import { DeliveredProcedureOutlined } from '@ant-design/icons';
import { Button, message, Space } from 'antd';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { TaxRecordDto } from 'common/dto/generated/TaxRecordDto';
import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import {
  TaxRecordSendToVerifyDto,
  TaxRecordSendToVerifySchema
} from 'common/dto/schema/TaxRecordSendToVerifyDto';
import { TaxRecordApi } from 'client/components/schema/tax-record/TaxRecordApi';
import { TaxRecordMachine } from 'server/schema/tax/tax-record/TaxRecordMachine';
import { TaxRecordState } from 'common/schema/tax-record/TaxRecordTypes';
import { useCurrentUser } from 'client/components/auth/AuthModule';

export interface TaxRecordSendToVerifyModalProps {
  taxRecord?: TaxRecordDto;
}

/**
 * Modal per l'invio in verifica della scheda anagrafica all'Ufficiale Rogante
 */
export function TaxRecordSendToVerifyModal(
  props: TaxRecordSendToVerifyModalProps
) {
  const { taxRecord } = props;
  const currentUser = useCurrentUser();
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (!taxRecord) return null;

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [sendToVerify] = useApiMutation(TaxRecordApi.actions.states.toVerify, {
    invalidates: [TaxRecordApi.find]
  });

  const transition = TaxRecordMachine.to(
    taxRecord,
    TaxRecordState.ToVerify,
    currentUser
  );

  if (!transition.allowed && transition.skipped) return null;

  return (
    <>
      <Button type="primary" disabled={!transition.allowed} onClick={showModal}>
        Invia a Ufficiale Rogante <DeliveredProcedureOutlined />
      </Button>

      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={new TaxRecordSendToVerifyDto()}
        validate={schemaToValidator(TaxRecordSendToVerifySchema)}
        onSubmit={async values => {
          try {
            const input = await values.validate();

            await sendToVerify({ data: { data: input, id: taxRecord.id } });
            message.success('Operazione riuscita');
            handleCancel();
          } catch (e) {
            message.error('Si è verificato un errore. Riprovare.'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        <FormikForm nested editable helpInTooltips>
          <ModalDialog
            title="Invia Scheda Anagrafica a Ufficiale Rogante per la verifica"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={
              <Space>
                <Button onClick={handleCancel}>Annulla</Button>
                <FormikSendButton>
                  Invia a Ufficiale Rogante <DeliveredProcedureOutlined />
                </FormikSendButton>
              </Space>
            }
          >
            <FormFieldsContainer>
              <FormFieldItem
                component={TextAreaInput}
                label="Descrizione"
                name="description"
                placeholder="Descrizione"
              />
            </FormFieldsContainer>
          </ModalDialog>
        </FormikForm>
      </Formik>
    </>
  );
}

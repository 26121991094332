import React from 'react';
import clx from 'classnames';
import './BreadcrumbsContainer.scss';
import { Breadcrumbs } from 'client/core/router/breadcrumb/Breadcrumbs';

export interface BreadcrumbsContainerProps {}

/*
 * Contiene il breadcrumb e gli stili per il posizionamento nel Layout
 */
export function BreadcrumbsContainer(props: BreadcrumbsContainerProps) {
  return (
    <div className={clx('breadcrumb-container', {})}>
      <Breadcrumbs />
    </div>
  );
}

/**
 * Encoding e decoding Base64 con supporto unicode.
 * See https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 */
export const base64 = {
  encode(plain: string) {
    return btoa(
      encodeURIComponent(plain).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode(parseInt(p1, 16));
        }
      )
    );
  },
  decode(encoded: string) {
    return decodeURIComponent(
      atob(encoded)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }
};

/**
 * Un'azione semplice con Payload.
 */
export interface Action<T extends string, P = unknown> {
  type: T;
  payload: P;
  error?: boolean;
  meta?: object;
}

export type PayloadCreator = (...args: any[]) => any;
export type ActionCreator<T extends string, A extends any[], R> = (
  ...args: A
) => Action<T, R>;

type PayloadReturnType<T> = T extends PayloadCreator ? ReturnType<T> : void;

/**
 * Crea un creatore di azioni senza payload
 * @param type Il nome dell'azione
 */
export function createAction<T extends string, P extends PayloadCreator>(
  type: T,
  payloadCreator?: P
) {
  return (...args: Parameters<P>) =>
    ({
      type,
      payload: payloadCreator ? payloadCreator(...args) : null
    } as Action<T, PayloadReturnType<P>>);
}

/**
 * Tipi delle azioni presenti in un array o in un oggetto.
 */
export type ActionTypes<T> = {
  [K in keyof T]: T[K] extends ActionCreator<infer TT, any, infer P>
    ? Action<TT, P>
    : never;
}[keyof T];

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import clx from 'classnames';
import './EllipsisTooltip.scss';
import { Tooltip } from 'antd';

export interface EllipsisTooltipProps {
  children: React.ReactNode;
}
/*
 * Render per testo con ellipsis
 * In caso di ellipsis viene attivato il popover
 */
export function EllipsisTooltip(props: EllipsisTooltipProps) {
  const [tooltips, setTooltips] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const width = ref.current?.clientWidth ?? 0;
    const scroll = ref.current?.scrollWidth ?? 0;
    if (scroll > width) {
      setTooltips(true);
    }
  }, [ref.current]);

  const text = (
    <div ref={ref} className={clx('ellipsis-tooltips')}>
      {props.children}
    </div>
  );
  return tooltips ? <Tooltip title={props.children}>{text}</Tooltip> : text;
}

import React, { useCallback } from 'react';
import { InputNumber, UploadProps } from 'antd';
import { FormikValues, useField } from 'formik';
import { useFormikFormContext } from '../FormikFormContext';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { UploadInputWrapper } from './UploadInputWrapper';

export interface UploadDraggerInputProps<T extends FormikValues>
  extends Omit<DraggerProps, 'name'> {
  name: keyof T & string;
  text?: string;
}

/**
 * Campo upload file con Dragger.
 */
export function UploadDraggerInput<T extends FormikValues>(
  props: UploadDraggerInputProps<T>
) {
  const { text, ...draggerProps } = props;

  return (
    <UploadInputWrapper>
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {text ??
            `Fai click o trascina un file in quest'area per caricare il documento.`}
        </p>
      </Dragger>
    </UploadInputWrapper>
  );
}

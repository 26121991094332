import React, { useCallback } from 'react';
import { InputNumber } from 'antd';
import { FormikValues, useField } from 'formik';
import { InputNumberProps } from 'antd/lib/input-number';
import { useFormikFormContext } from '../FormikFormContext';
import Decimal from 'decimal.js-light';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';

export interface CurrencyInputProps<T extends FormikValues>
  extends Omit<InputNumberProps, 'name'> {
  name: keyof T & string;
}

const PARSE_REGEX = /€\s?/g;

/**
 * Campo per input di tipo Valuta.
 */
export function CurrencyInput<T extends FormikValues>(
  props: CurrencyInputProps<T>
) {
  const { name } = props;
  const [field, meta, helpers] = useField<Decimal | null | undefined>(name);
  const { disabled } = useFormikFormContext();

  const handleChange = useCallback(
    (value: string | number | undefined | null) => {
      helpers.setValue(
        value === '' || value == null ? null : new Decimal(value)
      );
    },
    [helpers.setValue]
  );

  return (
    <InputNumber
      {...field}
      value={field.value == null ? undefined : field.value.toNumber()}
      max={4_000_000_000}
      onChange={handleChange}
      {...props}
      formatter={value =>
        value != null ? `€ ${value.toString().replace(/\./g, ',')}` : ''
      }
      parser={value => {
        return value
          ? value.replace(PARSE_REGEX, '').replace(/\./g, '').replace(/,/g, '.')
          : '';
      }}
      decimalSeparator=","
      disabled={props.disabled || disabled}
    />
  );
}

CurrencyInput.renderFieldValue = (value: any) => {
  return <CurrencyRender value={value} />;
};

import React, { useCallback } from 'react';
import { RouteProps, Route, Redirect, RouteComponentProps } from 'react-router';
import { useAuth, useCurrentUser, usePermissions } from '../AuthModule';
import { PermissionsType } from 'common/permissions/Permissions';
import { ForbiddenPage } from 'client/components/errors/forbidden/ForbiddenPage';

interface Props<T extends RouteComponentProps<any>> extends RouteProps {
  permission?: PermissionsType | PermissionsType[];
  component?: React.ComponentType<T> | React.ComponentType<any>;
  /** Proprietà aggiuntive del componente */
  componentProps?: Partial<T>;
}

/**
 * Rende privata una `Route` a meno che non sia stato effettuato il login,
 * ovvero se è disponibile un Token.
 */
export function PrivateRoute<T extends RouteComponentProps<any>>(
  props: Props<T>
) {
  const { component, componentProps, permission, ...rest } = props;
  const { logged } = useAuth();
  const permissions = usePermissions();
  const Component = component as React.ComponentType<any>;

  const granted = logged && (!permission || permissions.has(permission));

  const redirectUrl = (props.location?.pathname ?? '').includes('/admin')
    ? '/admin/login'
    : '/';

  return (
    <Route
      {...rest}
      render={props =>
        granted ? (
          <Component {...componentProps} {...props} />
        ) : logged ? (
          <ForbiddenPage />
        ) : (
          <Redirect
            to={{ pathname: redirectUrl, state: { from: props.location } }}
          />
        )
      }
    />
  );
}

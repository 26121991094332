type LogArguments = Parameters<typeof console['log']>;

export const logger = {
  log(...args: LogArguments) {
    console.log(...args);
  },
  warn(...args: LogArguments) {
    console.warn(...args);
  },
  error(...args: LogArguments) {
    console.error(...args);
  }
};

import { IDocumentalOffice } from "server/schema/documental/dto/DocumentalOfficeDto";
import { IFoliumApiOutcome } from "./FoliumApiOutcomeDto";


export enum OfficeAssignVisibility {
    HIDDEN = "0",
    VISIBLE = "1",
    NO_ASSIGNMENT = "2",
    ONLY_USERS ="3"
}

export interface IFoliumOffice {
    esito: IFoliumApiOutcome;
    results: null;
    result: {
        idUff: number;
        codiceUff: string;
        descUff: string;
        idAoo: number;
        userIdResp: string;
        visibilePerAssegnazione :OfficeAssignVisibility; 
        visibileDesc:string
    }
}

  
  export interface IDocumentalOfficeListResult {
    // Null in caso di Root (nodo radice livello 0)
    office: Maybe<IDocumentalOffice>;
    children: IDocumentalOfficeListResult[];
  }
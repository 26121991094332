import { Button, CheckboxOptionType, message, Space } from 'antd';
import { RelationEditor } from 'client/components/form/relations/useRelationEditor';
import { genderOptions } from 'client/routes/supplier/edit/genderOptions';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { CurrencyInput } from 'client/ui/form/input/CurrencyInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { RadioInput } from 'client/ui/form/input/RadioInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ContractDto } from 'common/dto/generated/ContractDto';
import {
  ContractSupplierDto,
  ContractSupplierSchema
} from 'common/dto/generated/ContractSupplierDto';
import { CountryCodeOptions } from 'common/schema/country-codes/CountryCodeOptions';
import { GenderTypes } from 'common/schema/supplier/GenderTypes';
import * as React from 'react';
import { SupplierFieldItem } from '../../supplier/input/SupplierFieldItem';

export interface IContractSupplierEditModalProps {
  contractId?: number;
  editor: RelationEditor<ContractSupplierDto>;
  editable?: boolean;
}

export function ContractSupplierEditModal(
  props: IContractSupplierEditModalProps
) {
  const { contractId, editor } = props;

  const labelWidth: ILabelWidth = 'width-140';

  return (
    <>
      <FormikAugmented
        enableReinitialize
        initialValues={
          editor.handle.current ?? new ContractSupplierDto({ contractId })
        }
        validationSchema={ContractSupplierSchema}
        validationContext={{}}
        validateOnChange={true}
        onSubmit={async values => {
          if (!props.editable) return;
          try {
            const input = await values.validate();
            // Solo per il rendering, registriamo il valore nel Dto. In ogni caso
            // NON viene inviato al server.
            input.supplier = values.supplier;

            // Evito che si possano inserire
            const isAlreadyInserted = editor.items.some(
              i =>
                i.supplierId === input.supplierId && i !== editor.handle.current
            );
            if (isAlreadyInserted) {
              message.error(
                `Fornitore già presente nel contratto, impossibile aggiungerlo nuovamente. Modificare quello esistente.`
              );
              return;
            }

            const type = editor.handle.apply(input);
            if (type === 'updated') {
              message.success('Fornitore modificato');
            } else {
              message.success('Fornitore aggiunto');
            }
          } catch (e) {
            message.error(
              'Si è verificato un errore nella gestione del fornitore, si prega di riprovare.'
            );
            console.error(e);
          }
        }}
      >
        <FormikForm nested editable={props.editable} helpInTooltips>
          <ModalDialog
            title={
              props.editable && editor.handle.isNew
                ? 'Aggiungi Contraente'
                : props.editable
                ? 'Modifica Contraente'
                : 'Dettaglio Contraente'
            }
            visible={editor.handle.isVisible}
            onCancel={editor.handle.cancel}
            footer={
              <Space>
                <Button onClick={editor.handle.cancel}>Annulla</Button>
                {props.editable && <FormikSendButton>Salva</FormikSendButton>}
              </Space>
            }
            mask
            maskClosable={false}
          >
            <FormErrorsNotification />

            <SupplierFieldItem name="supplierId" objectName="supplier" />
            <FormFieldsContainer removeTopTitleSpacing columns={1}>
              <FormFieldItem
                component={CurrencyInput}
                label="Importo"
                name="amount"
                placeholder="Importo del Contratto relativo al Contraente"
              />
              <FormFieldsContainer
                columns={8}
                title="Dati Rappresentante Legale"
              >
                <FormFieldItem
                  colSpan={3}
                  component={ConfigEnumInput}
                  feature="supplier.values"
                  enumKey="legalRole"
                  label="Qualifica"
                  name="legalRole"
                  placeholder="Qualifica"
                />
                <FormFieldItem
                  inline
                  colSpan={5}
                  component={TextInput}
                  label="Descrizione"
                  name="legalOther"
                  placeholder="Descrizione Qualifica"
                />

                <FormFieldItem
                  colSpan={3}
                  label="Nome"
                  placeholder="Nome"
                  component={TextInput}
                  name="legalName"
                />
                <FormFieldItem
                  inline
                  colSpan={3}
                  label="Cognome"
                  placeholder="Cognome"
                  component={TextInput}
                  name="legalSurname"
                />
                <FormFieldItem
                  inline
                  colSpan={2}
                  label="Sesso"
                  component={RadioInput}
                  name="legalGender"
                  options={genderOptions}
                />
              </FormFieldsContainer>

              <FormFieldsContainer columns={8}>
                <FormFieldItem
                  colSpan={3}
                  component={TextInput}
                  label="Codice Fiscale"
                  placeholder="Codice Fiscale"
                  name="legalFiscalNumber"
                />
                <FormFieldItem
                  inline
                  colSpan={2}
                  labelWidth={labelWidth}
                  component={SelectInput}
                  dropdownMatchSelectWidth={true}
                  options={CountryCodeOptions}
                  showSearch
                  optionFilterProp="value"
                  label="Sigla Nazione IVA"
                  placeholder="Sigla Nazione IVA"
                  name="legalFiscalIdCountry"
                />
                <FormFieldItem
                  inline
                  colSpan={3}
                  component={TextInput}
                  label="Codice IVA"
                  placeholder="Codice IVA"
                  name="legalFiscalIdCode"
                />
              </FormFieldsContainer>

              <FormFieldsContainer columns={8}>
                <FormFieldItem
                  colSpan={3}
                  label="Comune Nascita"
                  placeholder="Comune Nascita"
                  component={TextInput}
                  name="legalBornCity"
                />
                <FormFieldItem
                  inline
                  colSpan={2}
                  label="Provincia Nascita"
                  placeholder="Provincia Nascita"
                  component={TextInput}
                  name="legalBornProvince"
                />
                <FormFieldItem
                  inline
                  colSpan={3}
                  label="Data Nascita"
                  placeholder="Data Nascita"
                  type="date-only"
                  component={DatePickerInput}
                  name="legalBornDate"
                />
              </FormFieldsContainer>

              <FormFieldsContainer columns={3}>
                <FormFieldItem
                  label="Telefono"
                  placeholder="Telefono"
                  component={TextInput}
                  name="legalPhoneNumber"
                />
                <FormFieldItem
                  inline
                  label="Fax"
                  placeholder="Fax"
                  component={TextInput}
                  name="legalFaxNumber"
                />
                <FormFieldItem
                  inline
                  label="Email"
                  placeholder="Email"
                  component={TextInput}
                  name="legalEmail"
                />
              </FormFieldsContainer>
            </FormFieldsContainer>
          </ModalDialog>
        </FormikForm>
      </FormikAugmented>
    </>
  );
}

import { ProfileOutlined } from '@ant-design/icons';
import { Divider, Typography } from 'antd';
const { Text, Paragraph } = Typography;
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { Section } from 'client/ui/section/Section';
import { ContractTypologyType } from 'common/schema/contract/ContractTypes';
import { useField } from 'formik';
import React from 'react';

export interface IPreregisteredContractTaxRecordsSectionProps {}

export function PreregisteredContractTaxRecordsSection(
  props: IPreregisteredContractTaxRecordsSectionProps
) {
  const {} = props;
  const [typologyTypeField] = useField('typologyType');

  const noTaxRecords =
    typologyTypeField.value === ContractTypologyType.NoTaxRecords;

  if (!typologyTypeField.value) return null;

  return (
    <Section
      icon={<ProfileOutlined />}
      title="Comunicazione Anagrafe Tributaria"
      hasPadding
    >
      <Paragraph style={{ margin: 0 }}>
        <Text strong>Comunicazione Anagrafe Tributaria: </Text>
        {noTaxRecords ? (
          <>
            Per il seguente contratto non è richesta la compilazione delle
            schede di Anagrafe Tributaria perché il contratto è della tipologia
            "Non soggetto a Comunicazione Anagrafe Tributaria"
          </>
        ) : (
          <>
            Spuntare la casella successiva per abilitare la generazione delle
            schede di Anagrafe Tributaria che dovranno essere compilate
            annualmente dall'ufficio proponente.
          </>
        )}
      </Paragraph>
      {!noTaxRecords && (
        <>
          <Divider />
          <FormFieldsContainer>
            <FormFieldItem
              component={CheckboxInput}
              label={<span />}
              name="hasTaxRecords"
              style={{ fontSize: '1.5em' }}
              children={
                'È richiesto l’inserimento delle schede di Anagrafe Tributaria'
              }
              disabled={noTaxRecords}
            />
          </FormFieldsContainer>
        </>
      )}
    </Section>
  );
}

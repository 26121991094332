import { Tag } from 'antd';
import moment from 'moment';
import React from 'react';

export interface IUserStateTagProps {
  disabled?: boolean | null;
}

/**
 * Tag per lo stato dell'utente
 */
export function UserStateTag(props: IUserStateTagProps) {
  const { disabled } = props;

  return disabled ? (
    <Tag color={'red'}>Disabilitato</Tag>
  ) : (
    <Tag color={'green'}>Abilitato</Tag>
  );
}
